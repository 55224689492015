import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import { Button, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import telegram from "../../assets/images/ref_telegram.svg";
import group from "../../assets/images/ref_group.svg";
import twitter from "../../assets/images/ref_twitter.svg";
import wallet from "../../assets/images/ref_wallet.svg";
import { _get, _post } from "../../api/ApiClient";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../components/commonFunction/commonFunction";
import BarChart from "../../components/Chart/BarChart"
import DonutChart from "../../components/Chart/DonutChart"
import { userInfo } from "../../redux/user/UserSlice";
import { useNavigate } from "react-router-dom";

const ReferralProgram = () => {
  const userData = useSelector((state) => state.ROOT);
  const [referralInfo, setReferralInfo] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    getReferralInfo()

  }, [])

  const getReferralInfo = async () => {
    try {

      const response = await _get("/v1/user/get-refferal-info", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const res = response.data
      if (res.status) {
        setReferralInfo(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Layout>
      <Row>
        <div className="center-class" style={{ justifyContent: "space-between" }}>
          <div>
            <span className="font-color" style={{ fontSize: "33px", fontWeight: "500" }}>Referral</span>
            <span style={{ fontSize: "33px", fontWeight: "500", color: "white", marginLeft: "10px" }}>Program</span>
          </div>
          <button type="button" className="btn secure-sign-in-btn" onClick={() => navigate("/redeem-point-history")}>
            <span style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
              Redeem History
            </span>
          </button >
        </div>
      </Row>
      <Row className="pt-2">
        <h4 style={{ color: "white" }}>
          Total Points
        </h4>
      </Row>
      <Row>
        <div className="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-12">
          <div className="border-style-background">
            <Table responsive striped className="text-start referral-page-table">
              <thead>
                <tr>
                  <th className="referral-page-table-right-border">Your Total Points</th>
                  <th>{referralInfo?.points?.total_points}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="referral-page-table-bottom-border">
                  <td className="referral-page-table-right-border">Rewards paid for</td>
                  <td>{referralInfo?.points?.reward_paid_for}</td>
                </tr>
                <tr>
                  <td className="referral-page-table-right-border">Point reward pending</td>
                  <td>{referralInfo?.points?.point_reward_pending}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12">
          <div className="center-class" style={{ color: "white", justifyContent: "center" }}>
            <span className="font-color" style={{ fontWeight: "500", fontSize: "50px" }}>{referralInfo?.points?.point_reward_pending}</span>
          </div>
          {userData?.userInfo?.role === "Admin" && <div className="center-class" style={{ justifyContent: "center" }}>
            <button type="button" className="btn redeem-now-btn">
              <span style={{ color: "white", fontSize: "25px", fontWeight: "500" }} onClick={() => navigate("/redeem-point")}>Redeem Now</span>
            </button>
          </div>}
        </div>
      </Row>
      <Row className="pt-5">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <h3 style={{ color: "white" }}>
            Activity Point
          </h3>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <h3 style={{ color: "white" }}>
            Direct Referral Points
          </h3>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <h3 style={{ color: "white" }}>
            In Direct Referral Points
          </h3>
        </div>
      </Row>
      <Row>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="border-style" style={{ padding: "20px" }}>
            <DonutChart data={referralInfo?.chart?.activity_points} name="Activity Points" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="border-style" style={{ padding: "20px" }}>
            <BarChart data={referralInfo?.chart?.indirect_points} title={""} />
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="border-style" style={{ padding: "20px" }}>
            <BarChart data={referralInfo?.chart?.direct_points} title={""} />
          </div>
        </div>
      </Row>
      <Row className="pt-4">
        {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="referral-page-div-bg">
            <img src={telegram} alt="telegram" height={50} width={50} />
            <div style={{ paddingLeft: "20px", color: "white" }}>
              <span className="referral-div-text">Subscribe to KolsAi Connect</span><br />
              <span className="referral-div-text">telegram with KolsaiSignalBot</span>
            </div>
          </div>
          <div className="referral-page-div-bg mt-2">
            <img src={wallet} alt="telegram" height={50} width={50} />
            <div style={{ paddingLeft: "20px", color: "white" }}>
              <span className="referral-div-text">Connect Wallet</span>
            </div>
          </div>
          <div className="referral-page-div-bg mt-2">
            <img src={group} alt="telegram" height={50} width={50} />
            <div style={{ paddingLeft: "20px", color: "white" }}>
              <span className="referral-div-text">Join Group of KolsAI</span>
            </div>
          </div>
          <div className="referral-page-div-bg mt-2">
            <img src={twitter} alt="telegram" height={50} width={50} />
            <div style={{ paddingLeft: "20px", color: "white" }}>
              <span className="referral-div-text">Follow KolsAI Twitter</span>
            </div>
          </div>
        </div> */}
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="border-style-background">
            <Table responsive striped className="text-center referral-page-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Date</th>
                  <th>Point</th>
                </tr>
              </thead>
              <tbody>
                {referralInfo?.table?.direct.map((item, index) => (
                  <tr className="referral-page-table-bottom-border">
                    <td className="referral-page-table-right-border">{item.user}</td>
                    <td className="referral-page-table-right-border">{formatDate(item.date)}</td>
                    <td>{item.points}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="border-style-background">
            <Table responsive striped className="text-center referral-page-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Date</th>
                  <th>Point</th>
                </tr>
              </thead>
              <tbody>
                {referralInfo?.table?.indirect.map((item, index) => (
                  <tr className="referral-page-table-bottom-border">
                    <td className="referral-page-table-right-border">{item.user}</td>
                    <td className="referral-page-table-right-border">{formatDate(item.date)}</td>
                    <td>{item.points}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Row>

    </Layout>
  );
};

export default ReferralProgram;
