import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { _get, _post } from "../../api/ApiClient";
import { useSelector } from "react-redux";


const ChainDataAnalysis = () => {
  const userData = useSelector((state) => state.ROOT);
  const [chainDataChart, setChainDataChart] = useState({})
  const getChianDataAnalysisChart = async () => {
    try {
      const res = await _get("/v1/user/get-token-chart-data", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (res.data) {
        if (res.data.data) {
          setChainDataChart(res.data.data)
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  }


  const lineChartTokenCreaction = {
    chart: {
      type: 'line',
      backgroundColor: "transparent"
    },
    title: {
      text: 'Token scanned by KolsAI',
      style: {
        color: '#FFFFFF'
      }
    },
    xAxis: {
      categories: chainDataChart?.daily_token_creation?.categories,
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Values',
      },
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }

    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        let s = `<b>${this.x}</b>`;
        this.points.forEach(point => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: ${point.y}`;
        });
        return s;
      }
    },
    legend: {
      itemStyle: {
        color: '#FFFFFF'
      },
      itemHoverStyle: {
        color: '#d5d5d5' // The hover color you want
      }
    },
    /* series: [
      {
        name: 'Example Series',
        data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
      },
    ], */
    series: chainDataChart?.daily_token_creation?.series.filter(item => item !== null),
    credits: {
      enabled: false
    }
  };

  const lineChartTokenShared = {
    chart: {
      type: 'line',
      backgroundColor: "transparent"
    },
    title: {
      text: 'New Contract shared by Kols',
      style: {
        color: '#FFFFFF'
      }
    },
    xAxis: {
      categories: chainDataChart?.daily_by_influencer?.categories,
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Values',
      },
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        let s = `<b>${this.x}</b>`;
        this.points.forEach(point => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: ${point.y}`;
        });
        return s;
      }
    },
    legend: {
      itemStyle: {
        color: '#FFFFFF'
      },
      itemHoverStyle: {
        color: '#d5d5d5' // The hover color you want
      }
    },
    series: chainDataChart?.daily_by_influencer?.series.filter(item => item !== null),
    credits: {
      enabled: false
    }
  };

  const transactionOnChain10Days = {
    chart: {
      type: 'column',
      backgroundColor: "transparent"
    },
    title: {
      text: 'KOL Effect 1hr Volume',
      style: {
        color: '#FFFFFF'
      }
    },
    xAxis: {
      categories: chainDataChart?.transaction_last_ten_days?.categories,
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Values',
      },
      labels: {
        style: {
          color: '#FFFFFF'
        },
        formatter: function () {
          // Divide value by 1 billion and add 'B'
          return `${(this.value / 1e9).toFixed(1)}B`;
        },
      }
    },
    legend: {
      itemStyle: {
        color: '#FFFFFF'
      },
      itemHoverStyle: {
        color: '#d5d5d5' // The hover color you want
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0,  // Remove the border from columns
        dataLabels: {
          enabled: false,
          style: {
            color: '#FFFFFF'
          }
        }
      }
    },
    /* series: [
      {
        name: 'Example Series',
        data: [{ y: 29.9, extraInfo: 'Additional info for Jan </br> gtbb' },
        { y: 71.5, extraInfo: 'Additional info for Feb' },
        { y: 106.4, extraInfo: 'Additional info for Mar' },
        { y: 129.2, extraInfo: 'Additional info for Apr' },
        { y: 144.0, extraInfo: 'Additional info for May' },
        { y: 176.0, extraInfo: 'Additional info for Jun' },
        { y: 135.6, extraInfo: 'Additional info for Jul' },
        { y: 148.5, extraInfo: 'Additional info for Aug' },
        { y: 216.4, extraInfo: 'Additional info for Sep' },
        { y: 194.1, extraInfo: 'Additional info for Oct' },
        { y: 95.6, extraInfo: 'Additional info for Nov' },
        { y: 54.4, extraInfo: 'Additional info for Dec' }],
      },
    ], */
    series: {
      showInLegend: false,
      data: chainDataChart?.transaction_last_ten_days?.series.filter(item => item !== null)
    },

    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b> </br>Total volume: ${this.y}<br/> ${this.point.extraInfo}`;
      },
      style: {
        color: '#000'
      }
    },
    credits: {
      enabled: false
    }
  };

  const transactionOnChain24Hours = {
    chart: {
      type: 'column',
      backgroundColor: "transparent"
    },
    title: {
      text: 'KOL Effect 24hr Volume',
      style: {
        color: '#FFFFFF'
      }
    },
    xAxis: {
      categories: chainDataChart?.transaction_last_24_hours?.categories,
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Values',
      },
      labels: {
        style: {
          color: '#FFFFFF'
        },
        formatter: function () {
          // Divide value by 1 billion and add 'B'
          return `${(this.value / 1e9).toFixed(1)}B`;
        },
      }
    },
    legend: {
      itemStyle: {
        color: '#FFFFFF'
      },
      itemHoverStyle: {
        color: '#d5d5d5' // The hover color you want
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0,  // Remove the border from columns
        dataLabels: {
          enabled: false,
          style: {
            color: '#FFFFFF'
          }
        }
      }
    },
    series: {
      showInLegend: false,
      data: chainDataChart?.transaction_last_24_hours?.series.filter(item => item !== null)
    },

    tooltip: {
      formatter: function () {
        return `${this.x}: ${this.y}<br/> ${this.point.extraInfo}`;
      },
      style: {
        color: '#000'
      }
    },
    credits: {
      enabled: false
    }
  };

  useEffect(() => {
    getChianDataAnalysisChart()
  }, [])


  return (
    <Layout>
      <h4 style={{ color: "white" }}>Chain Data Analysis</h4>
      <div className="row" style={{ color: "#fff" }}>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <HighchartsReact highcharts={Highcharts} options={lineChartTokenCreaction} />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 chart-margin-top">
          <HighchartsReact highcharts={Highcharts} options={lineChartTokenShared} />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <HighchartsReact highcharts={Highcharts} options={transactionOnChain10Days} />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 chart-margin-top">
          <HighchartsReact highcharts={Highcharts} options={transactionOnChain24Hours} />
        </div>
      </div>
    </Layout>
  );
};

export default ChainDataAnalysis;
