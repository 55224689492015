import React from "react";
import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useSelector } from "react-redux";
import { Button, Row } from "react-bootstrap";
import fireDrop from "../../assets/images/fire.svg";
import { getSocket } from "../../socket";
import Table from "react-bootstrap/Table";
import defaultProfile from "../../assets/images/default-profile.jpg"
import { formatNumber, platformLogo, twoDigitNumber, chainOptionList } from "../../components/commonFunction/commonFunction";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
const KolsAIChatbot = () => {
  const userData = useSelector((state) => state.ROOT);
  const [topPerfomingInfluencers, setTopPerfomingInfluencers] = useState(null)
  const [activeButton, setActiveButton] = useState("1H");
  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent", // Set the background color to gray
      width: "100%", // Set the width to 140px
      boxShadow: null,
      color: "#fff",
      borderColor: state.isFocused ? "grey" : "#7fbbbb",
      borderRadius: "8px"
    }),

    valueContainer: (provided, state) => ({
      ...provided,
    }),
    menu: (provided) => ({
      ...provided,
      minwidth: "300px", // Adjust the width as needed
      background: "#272727",
      color: "#fff",
      fontFamily: "Plus Jakarta Sans",
    }),
    option: (provided, state) => ({
      ...provided,
      gap: "20px",
      display: "flex",
      cursor: "pointer",
      color: state.isSelected ? "#fff" : "gray",
      backgroundColor: state.isSelected
        ? "black"
        : state.isFocused
          ? "lightgray"
          : null,

      // backgroundColor: state.isFocused ? "#00b8d8" : provided.backgroundColor, // Apply background color on hover or focus
      ":hover": {
        backgroundColor: "#8CDFED",
        color: "#fff", // This will apply when the option is hovered over with the mouse
      },
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 48, // Ensure the indicators container matches the control height
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Set the font color to your desired color for the selected option
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // Set the font color to white for the text you type
    }),
  };
  const optionListPlatform = [
    { value: "all", label: "All" },
    { value: "telegram", label: "Telegram" },
    { value: "twitter", label: "Twitter" }
  ];

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };
  useEffect(() => {
    const socket = getSocket();
    if (socket && userData?.jwtToken) {
      socket?.emit("get_kolboard", {
        data: {},
      });
      socket.on("kolboard", (data) => {
        setTopPerfomingInfluencers(data)
      });
    }
  }, [])
  return (
    <Layout>
      <Row>
        <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 col-12">
          <div>
            <Button
              variant="primary" className={`kolsboard-btn ${activeButton === "5Min" ? "active" : ""}`}
              onClick={() => handleButtonClick("5Min")}
            >
              5Min
            </Button>
            <Button
              variant="primary" className={`kolsboard-btn ${activeButton === "1H" ? "active" : ""}`}
              onClick={() => handleButtonClick("1H")} style={{ marginLeft: '10px' }}
            >
              1H
            </Button>
            <Button
              variant="primary" className={`kolsboard-btn ${activeButton === "12H" ? "active" : ""}`}
              onClick={() => handleButtonClick("12H")} style={{ marginLeft: '10px' }}
            >
              12H
            </Button>
            <Button
              variant="primary" className={`kolsboard-btn ${activeButton === "Call Growth" ? "active" : ""}`}
              onClick={() => handleButtonClick("Call Growth")} style={{ marginLeft: '10px' }}
            >
              Call Growth
            </Button>
            <Button
              variant="primary" className={`kolsboard-btn ${activeButton === "7D" ? "active" : ""}`}
              onClick={() => handleButtonClick("7D")} style={{ marginLeft: '10px' }}
            >
              7D
            </Button>
            <Button
              variant="primary" className={`kolsboard-btn ${activeButton === "30D" ? "active" : ""}`}
              onClick={() => handleButtonClick("30D")} style={{ marginLeft: '10px' }}
            >
              30D
            </Button>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
          <div style={{display: "flex"}}>
            <div className="dropdown-container">
              <div className="select" style={{ width: "140px" }}>
                <Select
                  styles={customStyles2}
                  options={optionListPlatform}
                  defaultValue={optionListPlatform[0]}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="dropdown-container" style={{marginLeft: "5px"}}>
              <div className="select" style={{ width: "140px" }}>
                <Select
                  styles={customStyles2}
                  options={chainOptionList}
                  defaultValue={chainOptionList[0]}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
        </div>



      </Row>
      <Row className="mt-3 text-start" style={{ color: 'white' }}>
        <div className="p-0" style={{ display: 'flex', alignItems: 'center' }}>
          <img src={fireDrop} alt="" height={40} width={40} />
          <h5>Kols Board</h5>
        </div>
      </Row>

      <Row className="mt-3">
        <div>
          <div className="border-style-background">
            {topPerfomingInfluencers?.length > 0 ? <Table responsive striped className="text-center main-dashboard-table dashboard-table-common">
              <thead>
                <tr>
                  <th></th>
                  <th>Total</th>
                  <th>Contract</th>
                  <th>Presale</th>
                  <th>$token</th>
                  <th>Air Drop</th>
                  <th>Average ROI</th>
                  <th>Success ratio</th>
                  <th>Followers</th>
                  <th>5Min Volume</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                <td>1# <img src={token} alt="" height={30} width={30} /> <span style={{ marginLeft: '5px', marginRight: '5px' }}>MadApes</span>
                  <img src={telegram} alt="" height={20} width={20} /></td>
                <td>650</td>
                <td>50</td>
                <td>150</td>
                <td>25</td>
                <td><span className="font-color">150</span></td>
                <td><span className="font-color">2X</span></td>
                <td><span className="font-color">50%</span></td>
                <td><span className="font-color">90K</span></td>
                <td><span className="font-color">$100K</span></td>
              </tr> */}
                {topPerfomingInfluencers?.map((item, index) => (
                  <tr>
                    <td className="text-start">{index + 1}#&nbsp;
                      <img src={item.platform == "telegram" ? defaultProfile : item.profile_image} alt="" height={30} width={30} /> <span style={{ marginLeft: '5px', marginRight: '5px' }}>{item.influncerName}</span>
                      <a target="_balnk" href={item.profile_link} >
                        <img
                          src={platformLogo[item.platform]}
                          alt=""
                          height={20}
                          width={20}
                        />
                      </a>
                    </td>
                    <td>{item.total_messages}</td>
                    <td>50</td>
                    <td>{item.total_prelaunch_tags}</td>
                    <td>{item.total_dollar_tokens}</td>
                    <td><span className="font-color">{item.total_airdrop}</span></td>
                    <td><span className="font-color">{twoDigitNumber(item.performance)}X</span></td>
                    <td><span className="font-color">{item.success_ratio}%</span></td>
                    <td><span className="font-color">{formatNumber(item.followers)}</span></td>
                    <td><span className="font-color">${formatNumber(item?.m5transactions?.volume)}</span></td>
                  </tr>
                ))}
              </tbody>
            </Table> :
              <div style={{ background: "#13273c", borderRadius: "15px", padding: "15px" }} className="text-center">
                <Spinner animation="border" style={{ height: "50px", width: "50px", color: "white" }} />
              </div>}
          </div>
        </div>
      </Row>
    </Layout>
  );
};

export default KolsAIChatbot;
