import React, { useEffect, useState } from "react";
import logoimg from "../../assets/images/KolsAILogo.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { _post } from "../../api/ApiClient";
import { Button, Row } from "react-bootstrap";
import loginX from "../../assets/images/login-x.svg";
import loginTelegram from "../../assets/images/login-telegram.svg";
import loginMail from "../../assets/images/login-mail.svg";
import loginYoutube from "../../assets/images/youtubeLogin.svg";
import loginMedium from "../../assets/images/mediumLogin.svg";
import errowImg from "../../assets/images/errow.svg";
import { CONFIG } from "../../config/index.js";
import LoginWithWallete from "./LoginWithWallet.jsx"
import axios from "axios";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
const SignUp = () => {
  const userData = useSelector((state) => state.ROOT);
  const navigate = useNavigate();
  const isLoggedIn = userData?.jwtToken
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard")
    } else {
      setIsCheckingAuth(false);
    }
  }, [isLoggedIn, navigate])

  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });


  if (isCheckingAuth) {
    return null;
  }
  const goingToLoginPage = () => {
    navigate("/login");
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { first_name, email, password, confirm_password } = formData;

    if (!first_name || !email || !password || !confirm_password) {
      return "All fields are required.";
    }
    if (password !== confirm_password) {
      return "Passwords do not match.";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      setError("");
      setSuccess("");
      if (localStorage.getItem("referral_code")) {
        formData.refer_by = localStorage.getItem("referral_code");
      }

      const response = await axios.post(CONFIG.BASE_URL + "/v1/signup", formData);

      if (response.data.status) {
        if (localStorage.getItem("referral_code")) {
          localStorage.removeItem("referral_code");
        }
        toast.success(response.data.message)
        //setSuccess("Sign-up successful!");
        setFormData({
          first_name: "",
          email: "",
          password: "",
          confirm_password: "",
        });
        navigate("/login");
      } else {
        setError(response.data.message || "An error occurred.");
      }
    } catch (error) {
      setError(error.response?.data?.message || "Failed to sign up. Please try again.");
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          background: '#171721',
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div
          style={{
            background: "url('/curve.svg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div className="container-fluid">
            <Row>
              <div className="row login-page-headre-logo">
                <div className="col-xl-10 col-lg-10 col-md-8 col-sm-6 col-12" style={{ paddingLeft: "50px" }}>
                  <img src={logoimg} alt="headerLogo" height={75} width={220} style={{ marginTop: "10px", cursor: "pointer" }} onClick={() => navigate("/")} />
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <a href="https://x.com/kolsai_io" target="_blank">
                    <img src={loginX} width={40} height={40} />
                  </a>
                  <a href="https://t.me/Kolsai_io" target="_blank">
                    <img src={loginTelegram} width={40} height={40} style={{ marginLeft: "10px" }} />
                  </a>
                  <a href="https://www.youtube.com/@KolsAI-io" target="_blank">
                    <img src={loginYoutube} width={40} height={40} style={{ marginLeft: "10px" }} />
                  </a>
                  <a href="https://medium.com/@support_89738" target="_blank">
                    <img src={loginMedium} width={40} height={40} style={{ marginLeft: "10px" }} />
                  </a>
                </div>
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="text-start">
                  <div>
                    <span className="login-main-text">
                      Unlock the Power of <span className="font-color">Kols</span>AI
                    </span><br />
                    <span style={{ color: "white", fontSize: "18px", fontWeight: "500" }}>
                      Your gateway to smarter decisions and powerful insights awaits.
                    </span>
                  </div>

                  <div style={{ color: "white", }} className="pt-4">
                    <div style={{ textAlign: "start", display: "inline-block" }}>
                      <div>
                        <span style={{ fontSize: "30px", fontWeight: "500" }} className="font-color">Why Signup?</span><br />
                      </div>
                      <div className="center-class mt-3">
                        <div>
                          <span className="login-text-bullet main-answer">Access Premium Tools: Stay ahead with cutting-edge analytics.</span>
                        </div>
                      </div>
                      <div className="center-class mt-2">
                        <div>
                          <span className="login-text-bullet main-answer">Never Miss a Signal: Get updates on trending contracts, presales, and more.</span>
                        </div>
                      </div>
                      <div className="center-class mt-2">
                        <div>
                          <span className="login-text-bullet main-answer">Exclusive Features: Unlock benefits designed for crypto enthusiasts.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-4" style={{ fontWeight: "400", fontSize: "18px", color: "white" }}>
                    <span>Don’t Wait,{" "}</span>
                    <span className="font-color">Sign Up Now!</span>
                  </div>
                  <div className="pt-5" style={{ fontWeight: "400", fontSize: "18px", color: "white" }}>
                    <span>Already have an Account?</span><br />
                    <div>
                      <Button className="sign-in-here" onClick={() => goingToLoginPage()}>Sign in here</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 co-sm-12 col-12">
                <div style={{ padding: "0 20px" }}>
                  <div style={{ background: "#272F3C7A", borderRadius: "50px" }}>
                    <div className="text-center login-div-bg">
                      <div style={{ padding: "10px 30px" }}>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <span style={{ fontSize: "40px", fontWeight: "700", color: "#3DD1E5" }}>
                              Sign Up
                            </span>
                          </div>
                          {error && <div style={{ color: "red", margin: "10px 0" }}>{error}</div>}
                          {/* {success && <div style={{ color: "green", margin: "10px 0" }}>{success}</div>} */}
                          <div className="text-start">
                            <div>
                              <span className="login-username">
                                First Name
                              </span><span className="red-star">*</span>
                            </div>
                            <div className="pw-input-border">
                              <input className="login-username-input" type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange} />
                            </div>
                          </div>
                          <div className="text-start pt-4">
                            <div>
                              <span className="login-username">
                                E-mail
                              </span><span className="red-star">*</span>
                            </div>
                            <div className="pw-input-border">
                              <input className="login-username-input" type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange} />
                            </div>
                          </div>
                          <div className="text-start pt-4">
                            <div>
                              <span className="login-username">
                                Password
                              </span><span className="red-star">*</span>
                            </div>
                            <div className="pw-input-border">
                              <input className="login-username-input" type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange} />
                            </div>
                          </div>
                          <div className="text-start pt-4">
                            <div>
                              <span className="login-username">
                                Confirm Password
                              </span><span className="red-star">*</span>
                            </div>
                            <div className="pw-input-border center-class">
                              <input className="login-username-input"
                                type={showPassword ? 'text' : 'password'}
                                name="confirm_password"
                                onChange={handleChange} />
                              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility}
                                className="c-point"
                                style={{ color: "white" }} />
                            </div>
                          </div>
                          <div className="text-start pt-4">
                            <div>
                              <span className="login-username">
                                Referral Code
                              </span>
                            </div>
                            <div className="pw-input-border center-class">
                              <input className="login-username-input"
                                type="text"
                                name="refer_by"
                                disabled={localStorage.getItem("referral_code") ? true : false}
                                defaultValue={localStorage.getItem("referral_code") || ""}
                                onChange={handleChange} />
                            </div>
                          </div>
                          <div className="text-center pt-4">
                            <button type="submit" className="login-button">
                              <div className="sign-in-btn">
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                  <span style={{ fontSize: "30px", fontWeight: "600", color: "white" }}>Sign Up</span>
                                  <div className="center-class">
                                    <div className="errow-bg">
                                      <img src={errowImg} width={25} height={25} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </button>
                          </div>
                        </form>
                        <div className="pt-4">
                          <div>
                            <span style={{ color: "grey" }}>-----------------------{" "}</span>
                            <span style={{ color: "white", fontSize: "18px", fontWeight: "400" }}>OR</span>
                            <span style={{ color: "grey" }}>{" "}-----------------------</span>
                          </div>
                        </div>
                        <div className="pt-3">
                          {/* <img src={loginX} width={50} height={50} style={{ marginLeft: "10px" }} />
                          <img src={loginTelegram} width={50} height={50} style={{ marginLeft: "10px" }} />
                          <img src={loginMail} width={50} height={50} style={{ marginLeft: "10px" }} /> */}
                          <LoginWithWallete origin="login" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="login-bg">
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
