import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import { BorderField, StyledFilterWrapper } from "./style";
import close from "../../assets/images/close.png";
import "./Style.css";
import Switch from "../../components/Switch/Switch"
import { ChainDrawer } from "../../components/Cards/TokenCards/components/ChainDrawer";
import { ChainDrawerModal } from "./ChainDrawerModal"
import { useSelector } from "react-redux";
const customStyles2 = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent", // Set the background color to gray
    width: "100%", // Set the width to 140px
    boxShadow: null,
    color: "#fff",
    borderColor: state.isFocused ? "grey" : "#7fbbbb",
    borderRadius: "8px"
  }),

  valueContainer: (provided, state) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    minwidth: "300px", // Adjust the width as needed
    background: "#272727",
    color: "#fff",
    fontFamily: "Plus Jakarta Sans",
  }),
  option: (provided, state) => ({
    ...provided,
    gap: "20px",
    display: "flex",
    cursor: "pointer",
    color: state.isSelected ? "#fff" : "gray",
    backgroundColor: state.isSelected
      ? "black"
      : state.isFocused
        ? "lightgray"
        : null,

    // backgroundColor: state.isFocused ? "#00b8d8" : provided.backgroundColor, // Apply background color on hover or focus
    ":hover": {
      backgroundColor: "#8CDFED",
      color: "#fff", // This will apply when the option is hovered over with the mouse
    },
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 48, // Ensure the indicators container matches the control height
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff", // Set the font color to your desired color for the selected option
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Set the font color to white for the text you type
  }),
};

const buttonStyle = {
  height: "48px",
  cursor: 'pointer',
  // background: "linear-gradient(70.25deg, #12E2CC 5.93%, #23D1DB 21.64%, #009DCD 58.29%)",
  background: "linear-gradient(70.25deg, rgb(22 22 22) 5.93%, rgb(21 21 21) 21.64%, rgb(20 41 42) 58.29%)",
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent", // Set the background color to gray
    width: "100%", // Set the width to 140px
    boxShadow: null,
    color: "#fff",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    // border: "1px solid red",
    minHeight: "150px",
    maxHeight: "150px",
    overflowY: "auto",
    // padding: "2px 6px",
  }),
  menu: (provided) => ({
    ...provided,
    minwidth: "300px", // Adjust the width as needed
    background: "#272727",
    color: "#fff",
    fontFamily: "Plus Jakarta Sans",
  }),
  option: (provided, state) => ({
    ...provided,
    gap: "20px",
    display: "flex",
    cursor: "pointer",
    backgroundColor: state.isFocused ? "#00b8d8" : provided.backgroundColor, // Apply background color on hover or focus
    ":hover": {
      backgroundColor: "#8CDFED",
      color: "#fff", // This will apply when the option is hovered over with the mouse
    },
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 48, // Ensure the indicators container matches the control height
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff", // Set the font color to your desired color for the selected option
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Set the font color to white for the text you type
  }),
};

const optionList = [
  {
    value: "Telegram",
    label: "Telegram",
  },
  { value: "Twitter", label: "Twitter" },
];

const Filter = ({
  activate,
  allChannelData,
  setSearchValue,
  searchValue,
  setActiveClick,
  activeClick,
  setSelectList,
  activeLoader,
  setSlectedContractAddress,
  selectedTime,
  setSelectedTime,
  setSelectedInfluencerListOption,
  isTokenUpdateLive,
  setIsTokenUpdateLive,
  isSelectChain,
  setIsSelectChain
}) => {
  const [show, setShow] = useState(false);
  const [showModalForUpgrade, setShowModalForUpgrade] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("24");
  const [switchesKols, setSwitchesKols] = useState(false)
  const [checkedItems, setCheckedItems] = useState([]);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.ROOT);

  useEffect(() => {
    if (allChannelData?.length) {

      const option = allChannelData?.map((option) => ({
        value: option?._id, // Use _id as the value
        label: option?.channels[0]?.name, // Use channelUsername as the label
      }));
      setSelectedOptions(option);
      const selectedOptions = option;
      setSelectList({ selectedOptions, inputValue });

      setOptions(option);
    }

    // const option = [];
    // setSelectedOptions(option);
  }, [allChannelData, inputValue, setSelectList]);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const handleINPUTChange = (event) => {
    // Update the state with the new input value
    setInputValue(event.target.value);
  };

  const handleCloseForUpgrade = () => {
    setShowModalForUpgrade(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
  ];

  const optionListforInfluencer = [
    {
      value: "all_influencer",
      label: "All Influencer",
    },
    {
      value: "my_influencer",
      label: "My Influencer"
    },
  ];

  const changeTime = (hours) => {
    setSelectedTime(hours)
  }

  const handleToggle = () => {
    if (userData?.userInfo?.subscriptionDetails?.plan_name?.toLowerCase() === "test plan") {
      setShowModalForUpgrade(true)
    } else {
      setSwitchesKols(!switchesKols)
    }
  };

  useEffect(() => {
    if (switchesKols) {
      setSelectedInfluencerListOption("all_influencer")
    } else {
      setSelectedInfluencerListOption("my_influencer")
    }
  }, [switchesKols])

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add value to the array if checked
      setCheckedItems((prev) => [...prev, value]);
    } else {
      // Remove value from the array if unchecked
      setCheckedItems((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleToggleForLive = () => {
    if (userData?.userInfo?.subscriptionDetails?.plan_name?.toLowerCase() === "test plan") {
      setShowModalForUpgrade(true)
    } else {
      setIsTokenUpdateLive(!isTokenUpdateLive)
    }
  };

  const handleShowOpenModal = () => setShowModal(true);

  const upgradePlanRoute = () => {
    navigate("/subscription-plan");
  }

  return (
    <>
      <StyledFilterWrapper>
        <div className="row pt-2">

          <div className="filter-header col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 center-class">
            {/* <Dropdown optionList={optionList} /> */}
            {/* <div className="dropdown-container">
    <div className="select">
      <Select
        styles={customStyles2}
        options={optionList}
        defaultValue={optionList[0]}
        isSearchable={false}
      />
    </div>
    <div className="channels">
      <BorderField onClick={handleShow} style={{ border: '1px solid #7fbbbb' }}>
        {`${selectedOptions.length} Channels/Groups`}
      </BorderField>
    </div>
  </div> */}
            <div className="center-class">
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  style={{ height: "20px", width: "20px" }}
                  value="telegram"
                  checked={true}
                  /* checked={checkedItems.includes("telegram")} */
                  onChange={handleCheckboxChange}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>Telegram</span>
              </div>
              <div style={{ display: "flex", alignItems: "center", paddingLeft: "5px" }}>
                <input
                  type="checkbox"
                  value="twitter"
                  checked={true}
                  /* checked={checkedItems.includes("twitter")} */
                  onChange={handleCheckboxChange}
                  style={{ height: "20px", width: "20px" }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>Twitter</span>
              </div>
            </div>
            <div style={{ paddingLeft: "5px" }}>
              <Button
                variant="primary"
                style={buttonStyle}
                className={'time-btn ' + (selectedTime == 1 ? 'active-time' : '')}
                onClick={() => { changeTime(1) }}
              >
                1H
              </Button>
              <Button
                variant="primary"
                style={{ ...buttonStyle, marginLeft: '3px' }}
                className={'time-btn ' + (selectedTime == 6 ? 'active-time' : '')}
                onClick={() => { changeTime(6) }}
              >
                6H
              </Button>
              <Button
                variant="primary"
                style={{ ...buttonStyle, marginLeft: '3px' }}
                className={'time-btn ' + (selectedTime == 24 ? 'active-time' : '')}
                onClick={() => { changeTime(24) }}
              >
                1D
              </Button>
              <Button
                variant="primary"
                style={{ ...buttonStyle, marginLeft: '3px' }}
                className={'time-btn ' + (selectedTime == 168 ? 'active-time' : '')}
                onClick={() => { changeTime(168) }}
              >
                1W
              </Button>
              <Button
                variant="primary"
                style={{ ...buttonStyle, marginLeft: '3px' }}
                className={'time-btn ' + (selectedTime == 720 ? 'active-time' : '')}
                onClick={() => { changeTime(720) }}
              >
                1M
              </Button>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12 center-class">
            <div style={{ display: "flex" }}>
              <span style={{ color: 'white', fontSize: '20px' }}>
                All Kols
              </span>
              <div style={{ marginLeft: "10px" }}>
                <Switch
                  isOn={switchesKols}
                  handleToggle={handleToggle}

                />
              </div>
            </div>
            <div style={{ display: "flex", paddingLeft: "5px" }}>
              <span style={{ color: 'white', fontSize: '20px' }}>
                Live
              </span>
              <div style={{ marginLeft: "10px" }}>
                <Switch
                  isOn={isTokenUpdateLive}
                  handleToggle={handleToggleForLive}
                />
              </div>
            </div>
            <div className="chain-modal-div" style={{ color: "white", marginLeft: "5px" }} onClick={handleShowOpenModal}>
              {isSelectChain.current}
            </div>
          </div>
          {/* {location.pathname === "/trending-contract" && <><div className="main-filter">
  <BorderField
    onClick={() => {
      setIsSelectChain((prevState) => ({
        ...prevState,
        isOpen: !prevState.isOpen,
      }));
    }}
    style={{
      height: "30px",
      minHeight: "30px",
      display: "flex",
      alignItems: "center",
    }}
  >
    {isSelectChain.current}
  </BorderField>
  <ChainDrawer
    className="centered-drawer"
    isSelectChain={isSelectChain}
    setIsSelectChain={setIsSelectChain}
  />
</div></>} */}
          {/* <div className="search-container">
  <SearchField
    activeClick={activeClick}
    searchValue={searchValue}
    setSearchValue={setSearchValue}
    setSlectedContractAddress={setSlectedContractAddress}
  />
</div> */}
          <Offcanvas
            show={show}
            onHide={handleClose}
            style={{ background: "#272727", color: "#ffff" }}
          >
            <Offcanvas.Header
              color="#fff"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Offcanvas.Title>Select Channels/Groups</Offcanvas.Title>
              <div style={{ color: "#fff" }}>
                <img
                  onClick={handleClose}
                  src={close}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                />
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Select
                isMulti
                isSearchable={true}
                options={options}
                value={selectedOptions}
                onChange={handleSelect}
                styles={customStyles}
                placeholder="Select channel"
              />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <ChainDrawerModal
          showModal={showModal}
          setShowModal={setShowModal}
          isSelectChain={isSelectChain}
          setIsSelectChain={setIsSelectChain}
        />
      </StyledFilterWrapper>
      <Modal
        show={showModalForUpgrade}
        onHide={handleCloseForUpgrade}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          style={{ background: "#091420", borderBottom: "none" }}
        >
          <Modal.Title style={{ color: "#ffffff" }}>
            Upgrade Your Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#091420", color: "white" }}>
          Please Upgrade Your Plan to see all kolsa and live
        </Modal.Body>
        <Modal.Footer style={{ background: "#091420", borderTop: "none" }}>
          <Button variant="primary" onClick={upgradePlanRoute}>
            Upgrade
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Filter;
