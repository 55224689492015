import styled from "styled-components";
import bgimg from "../../assets/images/mainBg.png";

export const ContainerStyled = styled.div`
  //background-color: #151515;
  //background-image: url(${bgimg});
  //background-size: cover;
  width: 100%;
  height: 100vh;
  //padding-left: 50px;
  display: flex;
  justify-content: center;

  .heading {
    font-size: 30px;
    // color: #49dcdc;
    font-weight: 700;
    background: radial-gradient(
      72.37% 3497.01% at 52.57% 100%,
      #12f3c9 0%,
      #44d9f0 47.09%,
      #009dcd 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .subHeading {
    color: #b4b4b4;
    font-size: 18px;
  }
  .connect_box {
    display: flex;
    align-items: center;
    margin-top: 60px;
    width: fit-content;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    text-align: center;
    gap: 20px;
    img {
      width: 50px;
      height: fit-content;
      padding-left: 10px;
    }
    padding: 5px;
  }

  .connect_box_heading {
    width: 215px;
    background-color: #151515;
    color: #49dcdc;
    font-size: 12px;
    font-weight: 500;
  }
  .btn-connect {
    width: 300px;
    height: 50px;
    background: radial-gradient(
      148.36% 5657.83% at -12.22% 50.82%,
      #16f0cc 3.92%,
      #32e2e2 44.04%,
      #009dcd 84.5%
    );

    //margin-top: 20px;
    border: none;
    border-radius: 5px;
  }
  .footer-container {
    margin-top: 20px;
    width: fit-content;
    .footer-container-adress {
      display: flex;
      flex-direction: column;
      span {
        color: #b4b4b4;
        font-size: 16px;
      }
    }
    .social-icon {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding-left: 0;
    .left-section {
      display: none;
    }
    .right-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-section {
        text-align: center;
      }
    }
  }
`;
