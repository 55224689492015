import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { _get, _post } from "../../api/ApiClient";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import {userInfo} from "../../redux/user/UserSlice";
const VerifyEmail = () => {
  const userData = useSelector((state) => state.ROOT);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const url = window.location.href; // Get the current URL
    const token = url.split("/").pop(); // Extract the last part of the URL
    verifyEmail(token);
  }, []);

  const verifyEmail = async (token) => {
    try {
      const res = await _post("/v1/user/verify-email", { token }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.status) {
        let finalUserInfo = { ...userData.userInfo || {}};
        finalUserInfo.is_email_verified = true
        dispatch(userInfo(finalUserInfo));
        navigate("/dashboard")
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Spinner animation="border" style={{ height: "100px", width: "100px", color: "white" }} />
    </div>
  );
};

export default VerifyEmail;
