import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AreaChart = ({ data, title }) => {
  let series = data?.series.filter(item => item !== null);
  const options = {
    chart: {
      type: 'area',
      backgroundColor: 'transparent'
    },
    title: {
      text: title || 'Token Sharing by Chain',
      style: {
        color: '#fff', // Change the color of the y-axis title text
      },
    },
    xAxis: {
      categories: data?.categories || {},
      lineColor: '#fff',
      labels: {
        style: {
          color: '#fff', // Change label color
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const sortedPoints = this.points.slice().sort((a, b) => b.y - a.y);
        let s = `<b>${this.x}</b>`;
        sortedPoints.forEach(point => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: ${point.y}`;
        });
        return s;
      }
    },
    yAxis: {
      title: {
        text: 'Value',
        style: {
          color: '#fff', // Change the color of the y-axis title text
        },
      },
      lineColor: '#fff',
      labels: {
        style: {
          color: '#fff', // Change label color
        },
      },
    },
    series: series || [],
    plotOptions: {
      area: {
        stacking: 'normal', // or 'percent' for percentage-based stacking
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      itemStyle: {
        color: '#fff', // Change legend text color
      },
    },
    credits: {
      enabled: false, // Remove the Highcharts credits
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default AreaChart;
