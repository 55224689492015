import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faClose } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from "react-redux";
import { _get, _post } from "../api/ApiClient";
import { toast } from 'react-toastify';
import { userInfo, walleteSignature, walletAddress } from "../redux/user/UserSlice";
import copy from "../assets/images/copy.svg";
import nextArrow from "../assets/images/next_arrow.svg";
import walleteIcon from "../assets/images/wallet_ic.svg";
import { handleCopy } from "../components/commonFunction/commonFunction";
import LoginWithWallete from "../../src/components/LoginPage/LoginWithWallet"
import ProgressBar from 'react-bootstrap/ProgressBar';


export const ProfileCompletionModal = ({
  profileCompleteShowModal,
  setProfileCompleteShowModal,
}) => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.ROOT);
  const dispatch = useDispatch();
  const handleClose = () => {
    setProfileCompleteShowModal(false);
  };
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [telegramKey, setTelegramKey] = useState("");
  const [step, setStep] = useState(1);
  const [progressBarValue, setProgressBarValue] = useState(0)

  /* const navigateToSetting = () => {
    setProfileCompleteShowModal(false);
    navigate("/setting");

  }; */

  useEffect(() => {
    if (userData?.userInfo?.is_email_verified) {
      setStep(2)
      setProgressBarValue(25)
      if (userData?.userInfo?.telegram_id) {
        setStep(3)
        setProgressBarValue(50)
        if (userData?.userInfo?.wallet_address) {
          setStep(4)
          setProgressBarValue(75)
        }
      }
    }
  }, [])

  const verifyEmailAddress = async () => {
    try {
      const res = await _post("/v1/user/send-verify-email", {}, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.status) {
        toast.success(resp?.message);
      } else {
        if (resp?.is_email_verified) {

          let finalUserInfo = { ...userData.userInfo || {} };
          finalUserInfo.is_email_verified = true
          dispatch(userInfo(finalUserInfo));
        }
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }


  const generateTelegramKey = async () => {
    setTelegramKey("")
    setShowTelegramModal(true)
    try {
      const res = await _post("/v1/user/generate-telegram-key", { signature: userData?.walleteSignature }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.telegram_username) {
        setShowTelegramModal(false);
        toast.success(resp?.message);
        let finalUserInfo = { ...userData.userInfo };
        finalUserInfo.telegram_username = resp?.telegram_username
        finalUserInfo.telegram_id = resp?.telegram_id
        dispatch(userInfo(finalUserInfo));
      } else if (resp?.status) {
        setTelegramKey(resp?.data)
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    if (userData?.walleteSignature) {
      if (!userData.walletAddress) {
        createWalleteAddress()
      }
    }
  }, [userData?.walleteSignature])

  const createWalleteAddress = async () => {

    try {
      const res = await _post("/v1/user/connect-wallet", { signature: userData?.walleteSignature }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.user) {
        dispatch(walleteSignature(null));
        dispatch(userInfo(resp?.user))
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const handleCloseTelegramModal = () => {
    setShowTelegramModal(false)
  }

  return (
    <>
      <Modal
        show={profileCompleteShowModal}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal profile-modal"
      >
        <div className='profile-modal-bg' style={{ borderRadius: "40px" }}>
          <Modal.Header
            /* closeButton={userData?.userInfo?.email ? (userData?.userInfo?.is_email_verified ? true : false) : true} */
            closeButton={step === 4 ? true : false}
            closeVariant="white"
            style={{ borderBottom: "1px solid grey" }}
          >
            <Modal.Title style={{ color: "#ffffff" }}>
              Profile Completion
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: "white" }}>
            <div style={{ color: "white", paddingBottom: "20px", borderBottom: "1px solid grey" }}>
              <div className='profile-model-div'>
                <div>
                  <span style={{ color: "#d5d5d5", fontSize: "13px" }}>Your Progress</span><br />
                  <span style={{ fontSize: "25px", fontWeight: "500" }}>{progressBarValue}% complete</span>
                </div>
                {userData?.userInfo?.balance > -1 && <div>
                  <img src={walleteIcon} width={30} height={30} /> <span style={{ fontSize: "20px", fontWeight: "500", verticalAlign: "middle" }}>$ {userData?.userInfo?.balance}</span>
                </div>}
              </div>

              <div style={{ paddingTop: "5px" }} className='profile-competion-progress-bar'>
                <ProgressBar now={progressBarValue} style={{ width: "100%", borderRadius: "20px" }} />
              </div>
            </div>
            <div style={{ paddingTop: "10px" }}>
              {step === 1 && <div className="center-profile-completion-modal">

                {userData?.userInfo?.email ? (!userData?.userInfo?.is_email_verified && <div>
                  <span className='profile-step-text'>1. Email verify {userData?.userInfo?.is_email_verified ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#62ff62" }} /> :
                    <FontAwesomeIcon icon={faClose} style={{ color: "red" }} />} </span><br />
                  <span style={{ color: "#d5d5d5" }}>You need to verify email before procced and get 50 points</span>
                </div>) : (<div>
                  <span className='profile-step-text'>1. Add Email and Password <FontAwesomeIcon icon={faClose} style={{ color: "red" }} /></span><br />
                  <span style={{ color: "#d5d5d5" }}>You need add and verify email before procced and get 50 points</span>
                </div>)
                }

                {userData?.userInfo?.email ?
                  (!userData?.userInfo?.is_email_verified && <><button type="button" className="btn btn-primary setting-connet-btn" onClick={verifyEmailAddress}>Verify</button>
                  <span style={{color: "#d5d5d5", textDecoration: "underline", fontSize: "18px", cursor: "pointer"}} onClick={() => navigate("/setting")}>Go to Setting</span>
                  </>)
                  :
                  <button type="button" className="btn btn-primary setting-connet-btn" onClick={() => navigate("/setting")}>Add</button>}
              </div>}

              {step === 2 && <div className="center-profile-completion-modal">
                <div>
                  <span className='profile-step-text'>2. Link Telegram {userData?.userInfo?.telegram_id ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#62ff62" }} /> :
                    <FontAwesomeIcon icon={faClose} style={{ color: "red" }} />}</span><br />
                  <span style={{ color: "#d5d5d5" }}>You need to connect ko KolsAIbot to be able to get exra 50points</span>
                </div>
                {!userData?.userInfo?.telegram_id && <button type="button" className="btn btn-primary setting-connet-btn" onClick={generateTelegramKey}>Link</button>}
              </div>}

              {step === 3 && <div className="center-profile-completion-modal">
                <div>
                  <span className='profile-step-text'>3. Link Wallet {userData?.userInfo?.wallet_address ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#62ff62" }} /> :
                    <FontAwesomeIcon icon={faClose} style={{ color: "red" }} />}</span><br />
                  <span style={{ color: "#d5d5d5" }}>Connect wallet now and get 50 point extra</span>
                </div>
                {!userData?.userInfo?.wallet_address && <LoginWithWallete origin="profile_completion_modal" setProfileCompleteShowModal={setProfileCompleteShowModal}/>}
              </div>}

              {step === 4 && <div className="center-profile-completion-modal">
                <div>
                  <span className='profile-step-text'>4. Select Subscription Plan <FontAwesomeIcon icon={faClose} style={{ color: "red" }} /></span><br />
                  <span style={{ color: "#d5d5d5" }}>Choose tour plan and upgrade it to prmeimum to get 100 points</span>
                </div>
                <button type="button" className="btn btn-primary setting-connet-btn" onClick={() => navigate("/subscription-plan")}>Upgrade</button>
              </div>}

            </div>
            <div className='text-end' style={{ paddingTop: "30px" }}>
              {step === 4 ? <>
                <span className='skip-profile' onClick={handleClose}>Skip</span>
              </> :
                userData?.userInfo?.email ? (userData?.userInfo?.is_email_verified ? <span className='next-profile' onClick={() => setStep((prev) => prev + 1)}>Next <img src={nextArrow} height={15} width={15} /></span> : null)
                  : <span className='next-profile' onClick={() => setStep((prev) => prev + 1)}>Next <img src={nextArrow} height={15} width={15} /></span>
              }
              {/* {userData?.userInfo?.email ? (userData?.userInfo?.is_email_verified ? <span className='next-profile' onClick={() => setStep((prev) => prev + 1)}>Next <img src={nextArrow} height={15} width={15} /></span> : null)
                : <span className='next-profile' onClick={() => setStep((prev) => prev + 1)}>Next <img src={nextArrow} height={15} width={15} /></span>} */}
              {/* <span className='next-profile' onClick={() => setStep((prev) => prev + 1)}>Next <img src={nextArrow} height={15} width={15} /></span> */}
            </div>
          </Modal.Body>
          {/* <Modal.Footer style={{ borderTop: "none" }}>

            {userData?.userInfo?.email ? (userData?.userInfo?.is_email_verified ? <Button style={{ background: "#5c5151", border: "none" }} onClick={handleClose}>
              Skip
            </Button> : null) : <Button style={{ background: "#5c5151", border: "none" }} onClick={handleClose}>
              Skip
            </Button>}

            {step === 4 && <Button style={{ background: "#5c5151", border: "none" }} onClick={handleClose}>
              Skip
            </Button>}

          </Modal.Footer> */}
        </div>
      </Modal>
      <Modal
        show={showTelegramModal}
        onHide={handleCloseTelegramModal}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          style={{ background: "rgb(9, 20, 32)", borderBottom: "none" }}
        >
          <Modal.Title style={{ color: "#ffffff" }}>
            Telegram Account Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "rgb(9, 20, 32)", color: "white" }}>
          <span className='font-color'>your Account Key - </span>
          <div className='pt-2'>
            <span style={{ wordWrap: "break-word" }}>{telegramKey}</span>
            {telegramKey && <img src={copy} height={25} width={25} style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => handleCopy(telegramKey)} />}
          </div>
          <div className='pt-4'>
            <span className='font-color'>use this key to link account with <a className='font-color text-underline' href="https://t.me/kolsAisignalbot" target='blank' style={{ textDecoration: "underline" }}>"https://t.me/kolsAisignalbot"</a> in telegram</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "rgb(9, 20, 32)", borderTop: "none" }}>
          <Button variant="secondary" onClick={handleCloseTelegramModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
