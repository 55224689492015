import React from "react";
import styled from "styled-components";
import slectDown from "../../assets/images/selectdown.png";
import { Form } from "react-bootstrap";
// Define the styled component for the wrapper
const SelectWrapper = styled.div`
  position: relative;
  width: 25%;
  height: 48px;
`;

// Define the styled component for the select box
const StyledSelect = styled(Form.Select)`
  width: 100%;
  height: 100%;
  padding: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 8px;
  background-color: transparent;
  color: #ffffff;
  padding-right: 30px; /* Make room for the icon */
  option:hover {
    background-color: #fff; // Example hover color
    color: #fff;
  }

  /* Style for option items when active */
  option {
    background-color: #272727;
    color: #fff;
  }

  option:checked {
    background-color: blue;
    color: #fff;
  }
`;

// Define the styled component for the icon with red color
const Icon = styled.img`
  position: absolute;
  right: 10px;
  top: 55%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensures clicks go through to the select */
  width: 15px; /* Adjust the width as needed */
  height: 10px; /* Adjust the height as needed */
`;

// Example usage of the StyledSelect component with a red down arrow icon
const DropdownBtn = ({ optionList }) => {
  return (
    <SelectWrapper>
      <StyledSelect>
        <option value="Telegram">Telegram</option>
        <option value="Twitter">Twitter</option>

        {/* <option value="" style={{background:"black"}}>Twitter</option> */}
      </StyledSelect>
      <Icon src={slectDown} alt="Dropdown Icon" />
    </SelectWrapper>
  );
};

export default DropdownBtn;
