import React from "react";
import Nav from 'react-bootstrap/Nav';
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import wallet from "../../assets/images/wallet.svg";

export const SubscriptionHeading = () => {
  const userData = useSelector((state) => state.ROOT);

  return (
    <div>
      <div
        style={{
          fontSize: "28px",
          fontWeight: 600,
          lineHeight: "32.38px",
          color: " rgba(255, 255, 255, 1)",
        }}
      >
        Subscription <span style={{ color: "#44D9F0" }}>Plan</span>
      </div>
      <div className="mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
        <Nav variant="underline" defaultActiveKey="/new-pair" className='subscirption-heading'>
          <Nav.Item >
            <Nav.Link className="active">Hold Token</Nav.Link>
          </Nav.Item>
          <Nav.Item style={{pointerEvents: "none"}}>
            <Nav.Link >Yearly Cost </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{pointerEvents: "none"}}>
            <Nav.Link>Lifetime Cost </Nav.Link>
          </Nav.Item>
        </Nav>
        <div style={{ marginLeft: '20px' }}>
          <img src={wallet} width={40} height={40} alt="user wallet" />
          <span style={{ color: 'white', marginLeft: '15px' }}>{userData?.balance} $KolsAI</span>
        </div>
      </div>
      <div className="mt-3" style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
        <span>Token Hold:</span>
        <div style={{marginLeft: "10px"}}>
          <Form.Check
            inline
            label="Pay Money"
            name="group1"
            type="radio"
            id="Channel"
            value="money"
            disabled
          />
          <Form.Check
            inline
            label="Just Hold Token"
            name="group1"
            type="radio"
            id="Channel"
            value="token"
            checked
          />
        </div>
      </div>
    </div>
  );
};
