import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  walletAddress: null,
  profileUrl: null,
  role: null,
  jwtToken: null,
  isSideBarVisible: false,
  isMobile: false,
  firstName: null,
  lastName: null,
  twitterAc: null,
  telegramAc: null,
  subscriptionDetails: null,
  balance: null,
  userInfo: null,
  walleteSignature: null
};

export const userSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    userId: (state, action) => {
      state.userId = action.payload;
    },
    walletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    profileUrl: (state, action) => {
      state.profileUrl = action.payload;
    },
    role: (state, action) => {
      state.role = action.payload;
    },
    jwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    isSideBarVisible: (state, action) => {
      state.isSideBarVisible = action.payload;
    },
    isMobile: (state, action) => {
      state.isSideBarVisible = action.payload;
    },
    firstName: (state, action) => {
      state.firstName = action.payload;
    },
    lastName: (state, action) => {
      state.lastName = action.payload;
    },
    twitterAc: (state, action) => {
      state.twitterAc = action.payload;
    },
    telegramAc: (state, action) => {
      state.telegramAc = action.payload;
    },
    subscriptionDetails: (state, action) => {
      state.subscriptionDetails = action.payload;
    },
    balance: (state, action) => {
      state.balance = action.payload;
    },
    userInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    walleteSignature: (state, action) => {
      state.walleteSignature = action.payload;
    },

    resetAll: (state) => {
      // Reset all state properties to their initial values
      state.userId = initialState.userId;
      state.walletAddress = initialState.walletAddress;
      state.profileUrl = initialState.profileUrl;
      state.role = initialState.role;
      state.jwtToken = initialState.jwtToken;
      state.isSideBarVisible = initialState.isSideBarVisible;
      state.isMobile = initialState.isMobile;
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
      state.subscriptionDetails = initialState.subscriptionPlan;
      state.twitterAc = initialState.twitterAc;
      state.telegramAc = initialState.telegramAc;
      state.balance = initialState.balance;
      state.userInfo = initialState.userInfo;
      state.walleteSignature = initialState.walleteSignature;
    },
  },
});

export const {
  jwtToken,
  resetAll,
  userId,
  walletAddress,
  profileUrl,
  role,
  isSideBarVisible,
  isMobile,
  firstName,
  lastName,
  subscriptionDetails,
  twitterAc,
  telegramAc,
  balance,
  userInfo,
  walleteSignature
} = userSlice.actions;

export default userSlice.reducer;
