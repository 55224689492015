import React from 'react'
import Table from "react-bootstrap/Table";
import ExportLogo from "../../assets/images/export-arrow.png";

export const TransactionsTable = () => {
  return (
    <div className="mt-3">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>TYPE</th>
            <th>USD</th>
            <th>$BOON</th>
            <th>WETH</th>
            <th>PRICE </th>
            <th>MARKERS</th>
            <th>TXN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>4 Min ago</td>
            <td>Sell</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
            <td>4 Min ago</td>
            <td>Buy</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
          <td>4 Min ago</td>
            <td>Sell</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td> 
          </tr>
          <tr>
          <td>4 Min ago</td>
            <td>Buy</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
            <td>4 Min ago</td>
            <td>Sell</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
            <td>4 Min ago</td>
            <td>Sell</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>  
          </tr>
          <tr>
            <td>4 Min ago</td>
            <td>Buy</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td> 
          </tr><tr>
            <td>4 Min ago </td>
            <td>Sell</td>
            <td>320</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
