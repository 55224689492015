import React, { useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../assets/images/search.png";
export const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 0 30px 0 10px; // Adjust padding to make space for the icon
  background: transparent;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 11px;
  outline: none;
  &::placeholder {
    color: rgba(180, 180, 180, 1);
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 20px;
`;

export const SearchContainer = styled.div`
  position: relative;
  /* width: 30%; */
  height: 40px;
`;

const SearchInputs = ({ searchValue, setSearchValue, activeClick, setSlectedContractAddress }) => {
  useEffect(() => {
    setSearchValue("");
  }, [activeClick]);
  return (
    <SearchContainer>
      <SearchInput
        placeholder="Search influencer..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <SearchIcon>
        <img
          src={searchIcon}
          alt=""
          style={{ width: "20px", height: "20px", cursor: 'pointer' }}
          onClick={() => setSlectedContractAddress(searchValue)}
        />
      </SearchIcon>
    </SearchContainer>
  );
};

export default SearchInputs;
