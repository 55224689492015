import { Button, Form, Modal } from "react-bootstrap";
import Spinner from "../../spinner/Spinner";
import { useState, useEffect } from "react";

export const AddInfluencerModal = ({
  show,
  setShow,
  influencerLoader,
  handleSubmit,
  setInitialData,
  setAddInfluencerData,
  initialData, // New prop for initial data
}) => {
  const [formData, setFormData] = useState({
    profileImage: "",
    influencerName: "",
    contact: "",
    twitterId: "",
    telegramChannel: "",
  });

  const [errors, setErrors] = useState({});

  // Populate form data with initialData when provided
  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // If files are present, handle them separately
    if (files && files.length > 0) {
      const file = files[0]; // Assuming single file input, adjust if multiple
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: file,
      }));

      // Update initialData or addInfluencerData with file
      if (initialData) {
        setInitialData((prevInitialData) => ({
          ...prevInitialData,
          [name]: file,
        }));
      } else {
        setAddInfluencerData((prevAddInfluencerData) => ({
          ...prevAddInfluencerData,
          [name]: file,
        }));
      }
    } else {
      // Clear error message if user starts typing in the field
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);

      // Update formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      // Update initialData or addInfluencerData based on initialData existence
      if (initialData) {
        setInitialData((prevInitialData) => ({
          ...prevInitialData,
          [name]: value,
        }));
      } else {
        setAddInfluencerData((prevAddInfluencerData) => ({
          ...prevAddInfluencerData,
          [name]: value,
        }));
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    // Reset form fields and errors when modal is closed
    setFormData({
      profileImage: "",
      influencerName: "",
      twitterId: "",
      telegramChannel: "",
      contact: "",
    });
    setErrors({});
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
    // style={{ background: "rgb(118, 118, 118)" }}
    >
      {influencerLoader && <Spinner />}

      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ background: "rgb(18 19 20)", borderBottom: "none" }}
      >
        <Modal.Title style={{ color: "#ffffff" }}>
          {initialData ? "Edit Influencer" : "Add Influencer"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "rgb(18 19 20)" }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-2" controlId="">
            <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
              Upload Profile Image :-
            </Form.Label>
            <Form.Control
              type="file"
              placeholder=""
              autoFocus
              accept=".png,.jpg,.jpeg"
              name="profileImage"
              //   value={formData.profileImage}
              onChange={handleChange}
            />
          </Form.Group>
          {errors.profileImage && (
            <div style={{ color: "red" }}>{errors.profileImage}</div>
          )}
          <Form.Group className="mb-2" controlId="">
            <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
              Influencer Name :-
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="influencerName"
              value={formData.influencerName}
              onChange={handleChange}
            />
          </Form.Group>
          {errors.influencerName && (
            <div style={{ color: "red" }}>{errors.influencerName}</div>
          )}
          <Form.Group className="mb-2" controlId="">
            <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
              Contact :-
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="contact"
              value={formData.contact}
              onChange={handleChange}
            />
          </Form.Group>
          {errors.contact && (
            <div style={{ color: "red" }}>{errors.contact}</div>
          )}
          <Form.Group className="mb-2" controlId="">
            <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
              Twitter ID :-
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="twitterId"
              value={formData.twitterId}
              onChange={handleChange}
              disabled={initialData ? true : false}
            />
          </Form.Group>
          {errors.twitterId && (
            <div style={{ color: "red" }}>{errors.twitterId}</div>
          )}
          <Form.Group className="mb-2" controlId="">
            <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
              Telegram Channel :-
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              disabled={initialData ? true : false}
              name="telegramChannel"
              value={formData.telegramChannel}
              onChange={handleChange}
            />
          </Form.Group>
          {errors.telegramChannel && (
            <div style={{ color: "red" }}>{errors.telegramChannel}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ background: "rgb(18 19 20)", borderTop: "none" }}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          disabled={
            !formData.influencerName ||
            !formData.telegramChannel ||
            (!initialData && !formData.twitterId) ||
            !formData.contact
          }
          type="submit"
          variant="primary"
          onClick={handleSubmit}
        >
          {initialData ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
