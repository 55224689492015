import axios from "axios";
import { CONFIG } from "../config";

const api = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  baseURL: CONFIG.BASE_URL,

  headers: {
    "Content-Type": "application/json",
  },
});

//Define common API methods
const _get = (url, config = {}) => {
  return api.get(url, config);
};
const _delete = (url, config = {}) => {
  return api.delete(url, config);
};
const _put = (url, data = {}, config = {}) => {
  return api.put(url, data, config);
};
const _post = (url, data = {}, config = {}) => {
  return api.post(url, data, config);
};

export { _get, _post, _put, _delete };
