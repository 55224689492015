import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { _post } from "../../../api/ApiClient";
import "./style.css";
import { BorderField } from "../../Filter/style";
import { handleCopy, timeAgo, chainLinkforDextool, chainText, chainLogo } from "../../commonFunction/commonFunction";
import { TokenCardGradientText, TokenCardStyle } from "./TokenCards.style";
import { ChainDrawer } from "./components/ChainDrawer";
import { SafeAnalyzerrModal } from "../../../pages/MainDashboard/components/SafeAnalyzerrModal"

import viewIcon from "../../../assets/images/viewAll.png";
import copyicon from "../../../assets/images/copyic.png";
import geekSwap from "../../../assets/images/geekswap.png";
import maestroPro from "../../../assets/images/MaestroPro.jpg";
import maestroSniper from "../../../assets/images/MastroSniper.jpg";
import ether from "../../../assets/images/ether.png"
import safeLogo from "../../../assets/images/safeLogo.png";
import dexTool from "../../../assets/images/dextools_logo.png";

function formatString(str) {
  if (str) {
    return str.substring(0, 4) + "..." + str.substring(str.length - 4);
  }
}
const UtilityCard = ({
  name,
  data,
  selectList,
  socket,
  setChatData,
  setTokenIds,
  setTokenAddress,
  setClick,
  click,
  setTokenLoader,
  trendingCardLoader,
  trendingTokenClick,
  setNewTokenClick,
  setCardType,
  preTokenClick,
  setPreTokenCLick,
  setTrendingTokenCLick,
  clickedIndexs,
  setClickedIndexs,
  loader,
  slectedContractAddress,
  currenctPage
}) => {
  const userData = useSelector((state) => state.ROOT);
  const [showAll, setShowAll] = useState(false);
  const [show, setShow] = useState(false);
  const [contractAddressForSafeAnalyzer, setContractAddressForSafeAnalyzer] = useState('');
  const [chianForGeekSwap, setChianForGeekSwap] = useState('');
  const [isSelectChain, setIsSelectChain] = useState({
    isOpen: false,
    current: "All Chains",
    value: ""
  });
  const [hover, setHover] = useState(false);
  const linkStyle = {
    color: hover ? 'white' : 'white',
    textDecoration: 'none',
    marginLeft: '5px',
    padding: '2px',
    borderRadius: '8px'
  };

  const [searchTerm, setSearchTerm] = useState("");

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleTokenClick = async (tokenId = '', tokenAddress = '') => {
    // Receive index as argument
    setCardType("new");
    setNewTokenClick(true);
    setTrendingTokenCLick(false);
    setPreTokenCLick(false);
    setTokenIds(tokenId);
    setTokenAddress(tokenAddress);
    setClick(true);

    const list = selectList?.selectedOptions?.map((item) => item.value);
    socket?.emit("tokenMessage", {
      data: {
        tokenId: tokenId,
        tokenAddress: tokenAddress,
        listType: "new",
        list: [...list || []],
        time: selectList.inputValue,
        page: 'utility'
      },
    });
    socket?.on("newMessage", (data) => {
      setChatData(data?.data);
    });

    setClickedIndexs(tokenAddress); // Set clicked item index
    setTokenLoader(false);
  };
  useEffect(() => {
    if (trendingTokenClick || preTokenClick) {
      setClickedIndexs(null);
    }
  }, [trendingTokenClick, preTokenClick]);

  /* const allTokenNamesWithMsgCount = data.map((token) => ({
    name: token.name,
    msgCount: token.msgCount,
    _id: token._id,
    address: token.address,
    createdAt: token?.createdAt,
    chain: token?.chain
  })) */

  const allTokenNamesWithMsgCount = data.flatMap((item) => ({
    name: item?.tag,
    msgCount: item?.msgCount,
    _id: item?._id,
    createdAt: item?.createdAt,
  }));

  // Filter the token names based on the search term
  const filteredTokenNamesWithMsgCount = allTokenNamesWithMsgCount?.filter(
    (token) => token.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      token.address?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // const displayedData = showAll ? filteredData : filteredData.slice(0, 3);
  let displayedTokenNamesWithMsgCount = showAll
    ? filteredTokenNamesWithMsgCount
    : filteredTokenNamesWithMsgCount.slice(0, 10);

  if (isSelectChain.value != "") {
    displayedTokenNamesWithMsgCount = displayedTokenNamesWithMsgCount.filter(item => item.chain == isSelectChain.value)
  }

  const selectToken = () => {
    displayedTokenNamesWithMsgCount.forEach(element => {
      if (element.address == slectedContractAddress) {
        handleTokenClick(element._id, element.address)
      }
    });
  }
  useEffect(() => {
    selectToken()
  }, [slectedContractAddress]);

  useEffect(() => {
    setShowAll(false);
  }, [trendingCardLoader]);

  const handleShow = (address, chain) => {
    setContractAddressForSafeAnalyzer(address)
    setChianForGeekSwap(chain)
    setShow(true)
  }


  return (
    <TokenCardStyle
      style={{
        height: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(163,163,163,0.2)",
          paddingBottom: "10px",
        }}
      >
        <div style={{ fontSize: "18px" }}>{name}</div>
        <div className="channels">
          <BorderField
            onClick={() => {
              setIsSelectChain((prevState) => ({
                ...prevState,
                isOpen: !prevState.isOpen,
              }));
            }}
            style={{
              height: "30px",
              minHeight: "30px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isSelectChain.current}
          </BorderField>
          <ChainDrawer
            isSelectChain={isSelectChain}
            setIsSelectChain={setIsSelectChain}
          />
        </div>
      </div>
      {trendingCardLoader || loader ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Spinner
            animation="border"
            style={{ height: "25px", width: "25px" }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "10px",
            overflowY: "auto",
          }}
        >
          <input
            style={{
              background: "rgba(21, 22, 24, 1)",
              border: "0.5px solid #fff",
              borderRadius: "5px",
              outline: "none",
              color: "#fff",
              padding: "5px",
            }}
            type="text"
            placeholder="Search Token..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {displayedTokenNamesWithMsgCount.length > 0 ? (
            displayedTokenNamesWithMsgCount.map((token, index) => (
              <div>
                <img src={token.chain ? chainLogo[token.chain] : ether} alt="" height={20} width={20} />
                <span
                  key={index}
                  className="select-items"
                  style={{
                    color: "#ffffff",
                    padding: "5px",
                    fontSize: "16px",
                    fontWeight: 400,
                    cursor: "pointer",
                    borderRadius: "5px",
                    background: clickedIndexs === token.address ? "linear-gradient(70.25deg, rgb(18, 226, 204) 5.93%, rgb(35, 209, 219) 21.64%, rgb(0, 157, 205) 58.29%)" : "", // Change background color based on clicked item
                  }}
                  onClick={() => handleTokenClick(token._id, token.address)} // Attach onClick handler
                >
                  {`${token.name}(${token.msgCount}) (${formatString(
                    token.address
                  )})`}
                </span>
                <img
                  onClick={() =>
                    handleCopy(token.address)
                  }
                  src={copyicon}
                  alt=""
                  height={20}
                  width={20}
                  style={{ cursor: "pointer" }}
                />
                {token.chain ? <>
                  <a target="_balnk" href={chainLinkforDextool[token.chain] + token.address} style={linkStyle}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    title="Dextool">
                    <img
                      src={dexTool}
                      alt=""
                      height={22}
                      width={22}
                    />
                  </a>
                  <a target="_balnk" href={chainText[token.chain] + token.address} style={linkStyle}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    title="Geek Swap Bot">
                    <img
                      src={geekSwap}
                      alt=""
                      height={22}
                      width={22}
                    />
                  </a>
                </> : ""}

                <a target="_balnk" href={'https://t.me/MaestroProBot?start=' + token.address + '-cogerstro'} style={linkStyle}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  title="Maestro Pro Bot">
                  <img
                    src={maestroPro}
                    alt=""
                    height={22}
                    width={22}
                  />
                </a>

                <a target="_balnk" href={'https://t.me/MaestroSniperBot?start=' + token.address + '-cogerstro'} style={linkStyle}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  title="Maestro Sniper Bot">
                  <img
                    src={maestroSniper}
                    alt=""
                    height={22}
                    width={22}
                  />
                </a>
                {token.chain ? <><img
                  src={safeLogo}
                  alt=""
                  height={20}
                  width={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShow(token.address, chainText[token.chain])}
                /></> : <></>}


                <span style={{ float: "right", color: "#11efc9" }}>{timeAgo(token.createdAt)}</span>
              </div>
            ))
          ) : (
            <div
              style={{
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Link to={"/influence-mangement"}>
                Follow more Influence to get latest trending Info
              </Link>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={toggleShowAll}
          >
            {filteredTokenNamesWithMsgCount.length > 10 ? <>
              <TokenCardGradientText>
                {showAll ? "View Less" : "View All"}
              </TokenCardGradientText>
              <div>
                <img src={viewIcon} alt="" />
              </div>
            </> : <></>}

          </div>
          <SafeAnalyzerrModal
            show={show}
            setShow={setShow}
            chianForGeekSwap={chianForGeekSwap}
            contractAddressForSafeAnalyzer={contractAddressForSafeAnalyzer}
          />
        </div>

      )}
    </TokenCardStyle>
  );
};

export default UtilityCard;
