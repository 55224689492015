import React, { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import { _get, _post } from "../../api/ApiClient";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { userInfo } from "../../redux/user/UserSlice";
import logoimg from "../../assets/images/KolsAILogo.png";
import loginX from "../../assets/images/login-x.svg";
import loginYoutube from "../../assets/images/youtubeLogin.svg";
import loginMedium from "../../assets/images/mediumLogin.svg";
import loginTelegram from "../../assets/images/login-telegram.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
const ForgetPassword = () => {
  const userData = useSelector((state) => state.ROOT);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    const url = window.location.href; // Get the current URL
    const token = url.split("/").pop(); // Extract the last part of the URL
    setToken(token);
    verifyPasswordResetToken(token);
  }, []);

  const verifyPasswordResetToken = async (token) => {
    try {
      const res = await _post("/v1/verify-password-reset-token", { token },);
      let resp = res?.data
      if (!resp?.status) {
        navigate("/login")
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  };

  const resetPassword = async () => {
    try {
      let reqData = {
        password: password,
        confirm_password: confirm_password,
        token: token
      }
      const res = await _post("/v1/reset-password", reqData);
      let resp = res?.data
      if (resp?.status) {
        navigate("/login")
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  };



  return (
    <div
      style={{
        width: "100%",
        background: '#171721',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div
        style={{
          background: "url('/curve.svg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container-fluid">
          <Row>
            <div className="row login-page-headre-logo">
              <div className="col-xl-10 col-lg-10 col-md-8 col-sm-6 col-12" style={{ paddingLeft: "50px" }}>
                <img src={logoimg} alt="headerLogo" height={75} width={220} style={{ marginTop: "10px", cursor: "pointer" }} onClick={() => navigate("/")} />
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <a href="https://x.com/kolsai_io" target="_blank">
                  <img src={loginX} width={40} height={40} />
                </a>
                <a href="https://t.me/Kolsai_io" target="_blank">
                  <img src={loginTelegram} width={40} height={40} style={{ marginLeft: "10px" }} />
                </a>
                <a href="https://www.youtube.com/@KolsAI-io" target="_blank">
                  <img src={loginYoutube} width={40} height={40} style={{ marginLeft: "10px" }} />
                </a>
                <a href="https://medium.com/@support_89738" target="_blank">
                  <img src={loginMedium} width={40} height={40} style={{ marginLeft: "10px" }} />
                </a>
              </div>
            </div>
          </Row>
          <Row>
            <div className="reset-pw-center pt-5">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 col-12">
                <div style={{ padding: "20px" }}>
                  <div style={{ background: "#272F3C7A", borderRadius: "50px" }}>
                    <div className="text-center login-div-bg">
                      <div style={{ padding: "30px" }}>

                        <div className="text-center">
                          <span className="login-username">
                            Reset Password
                          </span>
                        </div>

                        <div className="text-start pt-4">
                          <div>
                            <span className="login-username">
                              New Password
                            </span><span className="red-star">*</span>
                          </div>
                          <div className="pw-input-border">
                            <input className="login-username-input"
                              type="password"

                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Enter password" />
                          </div>
                        </div>
                        <div className="text-start pt-4">
                          <div>
                            <span className="login-username">
                              Confirm Password
                            </span><span className="red-star">*</span>
                          </div>
                          <div className="pw-input-border center-class">
                            <input className="login-username-input"
                              type={showPassword ? 'text' : 'password'}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              placeholder="Enter password" />
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility}
                              className="c-point"
                              style={{ color: "white" }} />
                          </div>
                        </div>

                        {error && <div className="text-center" style={{ color: "red", marginTop: "10px" }}>{error}</div>}
                        <div className="text-center pt-5">
                          <div className="sign-in-btn" onClick={resetPassword}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <span style={{ fontSize: "30px", fontWeight: "600", color: "white" }}>Save</span>
                              <div className="center-class">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-center pt-4">
                            <span className="login-username" style={{ color: "#13FFFF", cursor: "pointer" }} onClick={() => navigate("/login")}>
                              Back to Login
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="login-bg">
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </div>

  );
};

export default ForgetPassword;
