import React from "react";
import { Link } from "react-router-dom"
import Table from "react-bootstrap/Table";
import { Pagination } from "antd";

import TeligramLogo from "../../assets/images/teligram.png";
import TwitterLogo from "../../assets/images/x.png";
import copyIcon from "../../assets/images/copyic.png";
import geekSwap from "../../assets/images/geekswap.png";
import maestroPro from "../../assets/images/MaestroPro.jpg";
import maestroSniper from "../../assets/images/MastroSniper.jpg";
import webSite from "../../assets/images/website-icon.png";
import dexTool from "../../assets/images/dextools_logo.png";
import { chainLogo, calculateTimeAgo, formatNumber, handleCopy, chainText } from "../commonFunction/commonFunction";

import "./new_pair_style.css";

export const TokenTable = (props) => {
  return (
    <div className="mt-3">
      {/* <Table striped bordered hover responsive> */}
      <Table responsive className="text-start main-dashboard-table dashboard-table-common">
        <thead>
          <tr>
            <th>Token</th>
            {/* <th>PRICE <img src={DollarLogo} alt="" /></th> */}
            <th>Social Count</th>
            <th>Age</th>
            <th>FDV</th>
            <th>Buys</th>
            <th>Sells</th>
            <th>Volume</th>
            {/* <th>1H</th> */}
            <th>Liquidity/I.Liquidity</th>
            <th>Audit Report</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.tokenList?.map((token, index) => (
            <tr>
              <td>
                <div style={{ display: 'flex' }}>

                  <div style={{ display: 'flex' }}>
                    <img src={token.chain ? chainLogo[token.chain] : ''} alt="img" height={50} width={50} />

                    <div style={{ marginLeft: '5px' }}>

                      {/* {' '}{token.symbol} */}
                      <span style={{ display: 'block' }}>
                        <Link to={"/new-pair-deatils/" + token.address} className="text-white">
                          {' '}{token.name}{' '}
                        </Link>
                        <img
                          onClick={() => handleCopy(token.address)}
                          src={copyIcon}
                          alt=""
                          height={20}
                          width={20}
                          style={{ cursor: "pointer"}}
                        />
                        <a target="_balnk" href={chainText[token.chain] + token.address}
                          title="Dextool">
                          <img
                            src={dexTool}
                            alt=""
                            height={20}
                            width={20}
                          />
                        </a>
                      </span>
                      <div style={{ display: 'block' }}>
                        <a target="_balnk" href=''
                          title="Maestro Sniper Bot" style={{ marginLeft: '5px' }}>
                          <img
                            src={TeligramLogo}
                            alt=""
                            height={12}
                            width={12}
                          />
                        </a>
                        <a target="_balnk" href=''
                          title="Maestro Sniper Bot" style={{ marginLeft: '5px' }}>
                          <img
                            src={TwitterLogo}
                            alt=""
                            height={12}
                            width={12}
                          />
                        </a>
                        <a target="_balnk" href=''
                          title="Maestro Sniper Bot" style={{ marginLeft: '5px' }}>
                          <img
                            src={webSite}
                            alt=""
                            height={12}
                            width={12}
                          />
                        </a>
                      </div>
                    </div>
                  </div>


                </div>
              </td>
              {/* <td>$0.08707</td> */}
              <td>
                <img src={TeligramLogo} alt="img" width={18} /> {token?.messageCount} {' '}
                {/* <img src={TwitterLogo} alt="img" width={18} /> 20 */}
              </td>
              <td>{calculateTimeAgo(token.creationTime)}</td>
              <td>{formatNumber(token?.t1_fdv)}</td>
              <td>{token?.t1_txns?.h24?.buys}</td>
              <td>{token?.t1_txns?.h24?.sells}</td>
              <td>{token?.t1_volume}</td>
              {/* <td><span className="color-red">-12.06%</span></td> */}
              <td style={{ display: 'grid' }}>
                <span>$90K</span>
                <span>$20K</span>

              </td>
              <td>
                <div style={{ display: 'flex' }}>
                  <div className="audit-report-new-pair">
                    <span style={{ color: '#11f711' }}>Yes</span>
                    <span>Nomint</span>
                  </div>
                  <div className="audit-report-new-pair">
                    <span style={{ color: '#11f711' }}>No</span>
                    <span>Blacklist</span>
                  </div>
                  <div className="audit-report-new-pair">
                    <span style={{ color: '#11f711' }}>Yes</span>
                    <span>LP liq</span>
                  </div>
                  <div className="audit-report-new-pair">
                    <span style={{ color: '#11f711' }}>26.71%</span>
                    <span>Top10 hold</span>
                  </div>
                  <div className="audit-report-new-pair">
                    <span style={{ color: '#11f711' }}>0%</span>
                    <span>NoFreeze</span>
                  </div>
                </div>
              </td>
              <td>
                <a target="_balnk" href={chainText[token.chain] + token.address}
                  title="Geek Swap Bot">
                  <img
                    src={geekSwap}
                    alt=""
                    height={22}
                    width={22}
                  />
                </a>
                <a target="_balnk" href={'https://t.me/MaestroProBot?start=' + token.address + '-cogerstro'}
                  title="Maestro Pro Bot" style={{ marginLeft: '5px' }}>
                  <img
                    src={maestroPro}
                    alt=""
                    height={22}
                    width={22}
                  />
                </a>
                <a target="_balnk" href={'https://t.me/MaestroSniperBot?start=' + token.address + '-cogerstro'}
                  title="Maestro Sniper Bot" style={{ marginLeft: '5px' }}>
                  <img
                    src={maestroSniper}
                    alt=""
                    height={22}
                    width={22}
                  />
                </a>
              </td>
            </tr>
          ))}
          {/*  <tr>
            <td><Link to="/new-pair-deatils" className="text-white">#8 <img src={DexPolygonLogo} alt="img" /> <img src={DexRoundLogo} alt="img" /> 42069/SOl </Link> 42069</td>
            <td>$0.08707</td>
            <td><td><Link href="#" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>

          </tr>
          <tr>
            <td>#7 <img src={DexRoundLogo} alt="" /> $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>
          </tr>
          <tr>
            <td>#1 <img src={DexRoundLogo} alt="img" /> $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>
          </tr>
          <tr>
            <td>#2 <img src={DexPolygonLogo} alt="img" /> $KEHA/USDC  KID PEPE WIF</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>
          </tr>
          <tr>
            <td>#8 <img src={DexPolygonLogo} alt="img" /> <img src={DexRoundLogo} alt="img" /> 42069/SOl 42069</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>
          </tr>
          <tr>
            <td>#8 <img src={DexPolygonLogo} alt="img" /> <img src={DexRoundLogo} alt="img" /> 42069/SOl 42069</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>

          </tr>
          <tr>
            <td>#7 <img src={DexRoundLogo} alt="img" /> $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>
          </tr><tr>
            <td>#7 <img src={DexRoundLogo} alt="img" /> $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td><Link href="" className="text-965"><img src={TeligramLogo} alt="img" width={18} /> 10 <img src={TwitterLogo} alt="img" width={18} /> 20</Link></td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-red">-12.06%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td><span className="color-green">11,502%</span></td>
            <td>$90K</td>
            <td>$597K</td>
          </tr> */}
        </tbody>
      </Table>
      {/* <div>
        <nav>
          <ul className="pagination justify-content-end">
            <li className="page-item-1">
              <Link href="#" className="page-link-1">{"<"}</Link>
            </li>
            <li className="page-item-1">
              <Link href="#" className="page-link-1 active">1</Link>
            </li>
            <li className="page-item-1">
              <Link href="#" className="page-link-1">2</Link>
            </li>
            <li className="page-item-1">
              <Link href="#" className="page-link-1">3</Link>
            </li>
            <li className="page-item-1">
              <Link href="#" className="page-link-1">
                {">"}
              </Link>
            </li>
          </ul>
        </nav>
      </div> */}
      <Pagination
        current={props?.currentPage}
        pageSize={props?.pageSize}
        total={props?.totalRecords ?? 0}
        hideOnSinglePage={true}
        onChange={props.handlePageChange}
      />
    </div>
  );
};
