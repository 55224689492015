import styled from "styled-components";
export const TokenCardStyle = styled.div`
  width: 100%;
  height: 200px;
  padding: 20px;
  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
  //background: rgba(21, 22, 24, 1);
  background: #171721;
  //margin-top: 10px;
  position: relative;
  .ant-badge .ant-scroll-number-custom-component {
    inset-inline-end: -16px;
    top: 3px;
  }
  .info-header {
    color: rgb(0, 184, 216);
    font-size: 9px;
    border: 1px solid rgba(68, 217, 240, 1);
    font-weight: 600;
    padding: 3px 3px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-drawer-content-wrapper {
    top: 63px !important;
  }
  a {
    color: white;
  }
  a:hover {
    color: blue;
  }
`;
export const TokenCardGradientText = styled.div`
  background: radial-gradient(
      115.25% 794.89% at 38.14% 113.04%,
      #00f6bc 0%,
      #44d9f0 38.95%,
      #009dcd 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
  font-weight: 500;
`;
