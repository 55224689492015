import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import DashBoardHeader from "../../components/dashBoardHeader/DashBoardHeader";
import Common from "../../components/commonContent/Common";
import { GraphTable } from "../../components/DexScreener/GraphTable";
import { TokenDetailsInfo } from "../../components/DexScreener/TokenDetailsInfo";
import { GraphView } from "../../components/DexScreener/GraphView";
import { _get, _post } from "../../api/ApiClient";

const DexScreenDeatils = () => {
  const userData = useSelector((state) => state.ROOT);
  const [chartData, setChartData] = useState([]);
  const [tokenData, setTokenData] = useState([]);
  const { token_address } = useParams()
  const [showLoader, setShowLoader] = useState(false);

  const getNewTokenDetails = async () => {
    try {
      setShowLoader(true);
      const resp = await _get("/v1/token/new-pair-details/" + token_address, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      setShowLoader(false);
      if (resp?.data?.status) {
        setChartData(resp?.data?.olhc_data);
        setTokenData(resp?.data?.token_data);
      } else {
        toast.error(resp?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      console.log("error", err);
      setShowLoader(false);
    }
  }

  useEffect(() => {
    getNewTokenDetails()
  }, [])

  return (
    <>
      <DashBoardHeader />
      <Common>
        <div
          style={{
            width: "100%",
            height: "100vh",
            padding: "110px 20px 20px",
            background: "#27292A",
          }}
        >
          {showLoader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                top: 150,
              }}
            >
              <Spinner
                animation="border"
                style={{ height: "50px", width: "50px", color: '#FFF' }}
              />
            </div>
          ) :
            <div className="row">
              <div className="col-xl-8">
                <div className="col-xl-12">
                  <GraphView chartData={chartData} tokenData={tokenData} />
                </div>
                <div className="col-xl-12">
                  <GraphTable />
                </div>
              </div>
              <div className="col-xl-4">
                <TokenDetailsInfo />
              </div>

            </div>
          }

        </div>
      </Common>

    </>
  );
};

export default DexScreenDeatils;
