import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { EmailVerificationModal } from "./EmailVerificationModal";
import { ProfileCompletionModal } from "./ProfileCompletionModal";
//import { useAccount } from "wagmi";
const ProtectedRoute = ({ children }) => {
  const userData = useSelector((state) => state.ROOT);
  const location = useLocation();
  let content = null;
  const bypassRoutesForEmailVerification = ["/setting"];
  /* const [showModal, setShowModal] = useState(false);
  const [profileCompleteShowModal, setProfileCompleteShowModal] = useState(true); */

  /* useEffect(() => {
    if (
      userData?.jwtToken &&
      userData?.userInfo?.email &&
      !bypassRoutesForEmailVerification.includes(location.pathname) &&
      userData?.userInfo?.is_email_verified === false
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    if (location.pathname === "/dashboard") {
      setProfileCompleteShowModal(true);
    } else {
      setProfileCompleteShowModal(false);
    }

    if (userData?.userInfo?.is_email_verified && userData?.userInfo?.wallet_address && userData?.userInfo?.telegram_id) {
      setProfileCompleteShowModal(false);
    }
  }, [userData, location.pathname]); */


  if (userData?.jwtToken) {
    if (userData?.userInfo?.subscriptionDetails?.is_subscription_running) {
      if (userData?.userInfo?.email && !bypassRoutesForEmailVerification.includes(location.pathname)) {
        if (userData?.userInfo?.is_email_verified == false) {
          content = children;
        } else {
          content = children;
        }
      } else {
        content = children;
      }
    } else {
      content = <Navigate to="/subscription-plan" />;
    }
  } else {
    content = <Navigate to="/" />;
  }
  return (
    <>

      {/* <div className={userData?.userInfo?.email ? (!userData?.userInfo?.is_email_verified && !bypassRoutesForEmailVerification.includes(location.pathname) ? "blur-background" : "") : ""}>
        {content}
      </div> */}
      {/* <div className={profileCompleteShowModal ? "blur-background" : ""}>
        {content}
      </div> */}
      {content}
      {/* <EmailVerificationModal showModal={showModal} setShowModal={setShowModal} /> */}
      {/* <ProfileCompletionModal profileCompleteShowModal={profileCompleteShowModal} setProfileCompleteShowModal={setProfileCompleteShowModal} /> */}
    </>
  );
};

export default ProtectedRoute;
