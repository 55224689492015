import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ProgressBar from 'react-bootstrap/ProgressBar';
//import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faDollarSign, faCheckSquare, faEarth, faGift, faInfoCircle, faMinusCircle, faPeopleGroup, faWater } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from "react-router-dom";

import { TelegramCardStyle } from "../TelegramCards.style";
import { _post } from "../../../api/ApiClient";
import { StyledParagraph } from "./TagCards.style";
import { handleCopy, chainLinkforDextool, chainText } from "../../commonFunction/commonFunction";
import { SafeAnalyzerrModal } from "../../../pages/MainDashboard/components/SafeAnalyzerrModal"

//import telegramImg from "../../../assets/images/telegrammm.png";
import geekSwap from "../../../assets/images/geekswap.png";
import maestroPro from "../../../assets/images/MaestroPro.jpg";
import maestroSniper from "../../../assets/images/MastroSniper.jpg";
import defaultProfile from "../../../assets/images/default-profile.jpg";
import copyicon from "../../../assets/images/copyic.png";
import safeLogo from "../../../assets/images/safeLogo.png";
import dexTool from "../../../assets/images/dextools_logo.png";
import cognoFi from "../../../assets/images/cognofi_new.svg";
import copy from "../../../assets/images/copy.svg";
import twitter from "../../../assets/images/twitter.svg";
import telegram from "../../../assets/images/telegram.svg";
import youtube from "../../../assets/images/youtube.svg";
import tiktok from "../../../assets/images/tiktok.svg";
import reddit from "../../../assets/images/reddit.svg";
import document from "../../../assets/images/document.svg";
import DonutChart from "../../Chart/DonutChart"

const TagCards = ({
  tokenIds,
  tokenAddress,
  click,
  setClick,
  influencerData,
  setTokenNameDisplay,
  setInfluencerDataPage,
  socket
}) => {
  const [show, setShow] = useState(false);
  const [contractAddressForSafeAnalyzer, setContractAddressForSafeAnalyzer] = useState('');
  const [chianForGeekSwap, setChianForGeekSwap] = useState('');
  const [showReload, setShowReload] = useState(false);
  const userData = useSelector((state) => state.ROOT);
  const [tokensDetails, setTokenDetails] = useState();
  const [tagLoader, setTagLoader] = useState(false);
  const [mainGreenHighlight, setMainGreenHighlight] = useState(null)
  const [mainRedIssue, setMainRedIssue] = useState(null)
  const [mainYellowIssue, setMainYellowIssue] = useState(null)
  const location = useLocation();
  const thirdDivRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [tokenSentimentDetails, setTokenSentimentDetails] = useState(null);
  const [tokenSentimentChartData, setTokenSentimentChartData] = useState([]);

  useEffect(() => {
    if (socket) {
      socket?.on("matched_contract_link", (data) => {
        //console.log("matched_contract_link", data)
      });
      socket?.on("matched_contract_influencer", (data) => {
        //console.log("matched_contract_influencer", data)
      });

      socket?.on("token_sentiments", (data) => {
        if (data?.sentiment_detail) {
          const sentimentArray = Object.entries(data?.sentiment_detail).map(([type, data]) => ({
            type,
            image:
              type === "telegram"
                ? telegram
                : type === "tweet"
                  ? twitter
                  : type === "youtube-video"
                    ? youtube
                    : type === "tiktok-video"
                      ? tiktok
                      : type === "news"
                        ? document
                        : type === "reddit-post"
                          ? reddit
                          : "",
            ...data,
          }));
          setTokenSentimentDetails(sentimentArray);

          const totals = Object.values(data?.sentiment_detail).reduce(
            (acc, data) => {
              acc.positive += data.positive;
              acc.neutral += data.neutral;
              acc.negative += data.negative;
              return acc;
            },
            { positive: 0, neutral: 0, negative: 0 }
          );
          //const totalSum = totals.positive + totals.neutral + totals.negative;

          const sentimentPercentageArray = [
            { name: "Positive", y: totals.positive, color: "#007bff" },
            { name: "Neutral", y: totals.neutral, color: "#00e5ff" },
            { name: "Negative", y: totals.negative, color: "#ffffff" },
          ];
          setTokenSentimentChartData(sentimentPercentageArray);
        }
      });
    }
    return () => {
      if (socket) {
        socket.off("matched_contract_link");
        socket.off("matched_contract_influencer");
        socket.off("token_sentiments");
      }
    };
  }, [socket]);

  const covertFloat = (stringNumber) => {
    if (!stringNumber) {
      return "";
    } else {
      const number = parseFloat(stringNumber);
      const formattedNumber = number?.toFixed(2);
      return formattedNumber;
    }
  };
  const covertPrice = (stringNumber) => {
    if (!stringNumber) {
      return "";
    } else {
      const number = parseFloat(stringNumber);
      const formattedNumber = number?.toFixed(8);
      return formattedNumber;
    }
  };

  const [hover, setHover] = useState(false);
  const linkStyle = {
    color: hover ? 'white' : 'white',
    textDecoration: 'none',
    marginLeft: '10px',
    padding: '7px',
    border: '1px solid #7fbbbb',
    borderRadius: '5px'
  };

  const borderColor = '1px solid #7fbbbb'

  const formatScientificNotation = (num) => {
    // Convert the number to a string in fixed-point notation
    let strNum = num?.toFixed(20); // Using a large number of decimal places to ensure precision
    // Remove trailing zeros
    strNum = strNum?.replace(/0+$/, '');

    // Find the first non-zero digit after the decimal point
    const match = strNum?.match(/0\.(0*)([1-9])/);

    if (match) {
      const zeros = match[1].length;
      const remainingDigits = strNum?.slice(2 + zeros);
      return `0.0(${zeros + 1})${remainingDigits}`;
    } else {
      // Handle the case where the number is an integer
      return strNum;
    }
  }

  const redIssue = []
  const yellowIssue = []
  const greenHighlight = []

  const tokenInfo = async () => {
    try {
      setTokenNameDisplay('')
      setTagLoader(true);
      if (tokenAddress) {
        //let payload = { tokenId: tokenIds };
        let payload = { tokenAddress: tokenAddress };
        const res = await _post("/v1/token/info", payload, {
          headers: { Authorization: `Bearer ${userData.jwtToken}` },
        });
        if (res.data) {
          setShowReload(false);
          // setTokenLoader(false);
          setTagLoader(false);
          setTokenDetails(res.data);
          setTokenNameDisplay(res?.data?.tokenInfo?.name)
          if (tokensDetails?.data?.ownershipRenounced == 'yes') {
            greenHighlight.push({
              'Contract renounced': tokensDetails?.data?.ownershipRenounced
            })
          } else if (tokensDetails?.data?.mintable == 'warning') {
            redIssue.push({
              'Contract renounced': tokensDetails?.data?.ownershipRenounced
            })
          }

          if (covertFloat(tokensDetails?.data?.top10HoldersPtg) < 35.00) {
            greenHighlight.push({
              'Top 10 user wallets': covertFloat(tokensDetails?.data?.top10HoldersPtg) + "%"
            })
          } else if (covertFloat(tokensDetails?.data?.top10HoldersPtg) > 45.00) {
            redIssue.push({
              'Top 10 user wallets': covertFloat(tokensDetails?.data?.top10HoldersPtg) + "%"
            })
          } else {
            yellowIssue.push({
              'Top 10 user wallets': covertFloat(tokensDetails?.data?.top10HoldersPtg) + "%"
            })
          }

          if (typeof tokensDetails?.data?.buyTax?.max != 'undefined' && typeof tokensDetails?.data?.sellTax?.max != 'undefined') {
            if (Number(tokensDetails?.data?.buyTax.max * 100).toFixed(2) < 5.00 || Number(tokensDetails?.data?.sellTax?.max * 100).toFixed(2) < 5.00) {
              greenHighlight.push({
                'High Buy/Sell Tax': Number(tokensDetails?.data?.buyTax?.max * 100).toFixed(2) + "%"
              })
            } else if (Number(tokensDetails?.data?.buyTax?.max * 100).toFixed(2) > 10.00 || Number(tokensDetails?.data?.sellTax?.max * 100).toFixed(2) > 10.00) {
              redIssue.push({
                'High Buy/Sell Tax': Number(tokensDetails?.data?.buyTax?.max * 100).toFixed(2) + "%"
              })
            } else {
              yellowIssue.push({
                'High Buy/Sell Tax': Number(tokensDetails?.data?.buyTax?.max * 100).toFixed(2) + "%"
              })
            }
          }


          if (typeof tokensDetails?.data?.honeypot != 'undefined') {
            if (tokensDetails?.data?.honeypot == 'no') {
              greenHighlight.push({
                'honeypot': tokensDetails?.data?.honeypot
              })
            } else if (tokensDetails?.data?.honeypot == 'yes') {
              redIssue.push({
                'honeypot': tokensDetails?.data?.honeypot
              })
            } else {
              yellowIssue.push({
                'honeypot': tokensDetails?.data?.honeypot
              })
            }
          }

          if (typeof tokensDetails?.data?.mintable != 'undefined') {
            if (tokensDetails?.data?.mintable == 'no') {
              greenHighlight.push({
                'mintable': tokensDetails?.data?.mintable
              })
            } else if (tokensDetails?.data?.mintable == 'yes') {
              redIssue.push({
                'mintable': tokensDetails?.data?.mintable
              })
            } else {
              yellowIssue.push({
                'mintable': tokensDetails?.data?.mintable
              })
            }
          }
          setMainGreenHighlight(greenHighlight)
          setMainRedIssue(redIssue)
          setMainYellowIssue(yellowIssue)

          if (res?.data?.data === null) {
            setShowReload(true);
          }
        }
      } else {
        setTagLoader(false);
        setTokenDetails();
      }
    } catch (err) {
      // setTokenLoader(false);
      setClick(false);
      setShowReload(true);
      setTokenDetails();

      setTagLoader(false);
      console.log("err", err);
    }
    setTagLoader(false);
    // setTokenLoader(false);
  };
  useEffect(() => {
    if (click) {
      tokenInfo();
      // setClick(false);
    } else {
      setTokenDetails();
    }
  }, [click, tokenAddress]);

  const handleShow = (address, chain) => {
    setContractAddressForSafeAnalyzer(address)
    setChianForGeekSwap(chain)
    setShow(true)
  }

  const getProfileImage = (item) => {
    let profileImage = defaultProfile;
    if (item?.platform && item?.platform != 'telegram') {
      profileImage = item?.profile
    }
    return profileImage;
  }

  const handleScroll = () => {
    const thirdDiv = thirdDivRef.current;
    if (
      thirdDiv.scrollTop + thirdDiv.clientHeight >=
      thirdDiv.scrollHeight - 0 &&
      hasMore &&
      !loading
    ) {
      setInfluencerDataPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const thirdDiv = thirdDivRef.current;
    if (thirdDiv) {
      thirdDiv.addEventListener("scroll", handleScroll);
      return () => thirdDiv.removeEventListener("scroll", handleScroll);
    }
  }, [hasMore, loading, influencerData]);

  return (
    <TelegramCardStyle

      style={{ width: "100%" }}
    >
      {location.pathname === "/trending-contract" && click ? (<>
        <div className={window.innerWidth < 992 ? "pt-2" : ""}>
          <div className="border-style-background">
            <div style={{ background: "#13202f", borderRadius: "15px" }}>
              {tagLoader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    top: 150,
                  }}
                >
                  <Spinner
                    animation="border"
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              ) : (
                <div
                  className={window.innerWidth < 992 ? "pt-2" : ""}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {tokensDetails?.data ? (
                    <>
                      <div
                        style={{
                          minHeight: "48px",
                          textAlign: "center",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          margin: "0 auto",
                          padding: "15px",
                          borderBottom: borderColor
                        }}
                      >
                        {/* <StyledParagraph></StyledParagraph> */}
                        <StyledParagraph >
                          <span style={{ fontSize: "20px" }}>
                            {` ${tokensDetails?.tokenInfo?.name || "-"} (${tokensDetails?.tokenInfo?.symbol || "-"
                              })`} <a target="_balnk" href={chainLinkforDextool[tokensDetails?.tokenInfo?.chain] + tokensDetails?.contractAddress}
                                title="Dextool"
                                rel="noopener noreferrer"
                              >
                              <img
                                src={dexTool}
                                alt=""
                                height={22}
                                width={22}
                              />
                            </a>
                            <a href='#' style={{ marginLeft: '5px' }}
                              onClick={() => handleShow(tokensDetails?.contractAddress, chainText[tokensDetails?.tokenInfo?.chain])}>
                              <img
                                src={safeLogo}
                                alt=""
                                height={22}
                                width={22}
                              />
                            </a>
                          </span>
                          <div style={{ marginTop: "8px" }}>
                            {/* <a target="_balnk" href={chainText[tokensDetails?.tokenInfo?.chain] + tokensDetails?.contractAddress} style={linkStyle}
                              onMouseEnter={() => setHover(true)}
                              onMouseLeave={() => setHover(false)}>
                              <img
                                src={geekSwap}
                                alt=""
                                height={22}
                                width={22}
                              />
                              <span style={{ marginLeft: "5px" }}>
                                Geek Swap
                              </span>
                            </a> */}

                            <a target="_balnk" href={'https://t.me/MaestroProBot?start=' + tokensDetails?.contractAddress + '-cogerstro'} style={linkStyle}
                              onMouseEnter={() => setHover(true)}
                              onMouseLeave={() => setHover(false)}>
                              <img
                                src={maestroPro}
                                alt=""
                                height={22}
                                width={22}
                              />
                              <span style={{ marginLeft: "5px" }}>
                                Maestro Pro
                              </span>
                            </a>

                            <a target="_balnk" href={'https://t.me/MaestroSniperBot?start=' + tokensDetails?.contractAddress + '-cogerstro'} style={linkStyle}
                              onMouseEnter={() => setHover(true)}
                              onMouseLeave={() => setHover(false)}>
                              <img
                                src={maestroSniper}
                                alt=""
                                height={22}
                                width={22}
                              />
                              <span style={{ marginLeft: "5px" }}>
                                Maestro Sniper
                              </span>
                            </a>
                            <SafeAnalyzerrModal
                              show={show}
                              setShow={setShow}
                              chianForGeekSwap={chianForGeekSwap}
                              contractAddressForSafeAnalyzer={contractAddressForSafeAnalyzer}
                            />
                          </div>

                        </StyledParagraph>
                      </div>
                      <div style={{ display: "flex", width: "100%", borderBottom: borderColor }}>
                        <div
                          style={{
                            textAlign: "center",
                            width: "50%",
                            borderRight: '1px solid white',
                            padding: '10px'
                          }}
                        >
                          <StyledParagraph>Price</StyledParagraph>
                          <StyledParagraph>
                            {tokensDetails?.data?.priceData?.price
                              ? `$ ${formatScientificNotation(tokensDetails?.data?.priceData.price)}`
                              : "-"}
                          </StyledParagraph>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            width: "50%",
                            padding: '10px'
                          }}
                        >
                          <StyledParagraph>Market Cap</StyledParagraph>
                          <StyledParagraph>
                            {tokensDetails?.data?.marketCap
                              ? `$ ${covertFloat(tokensDetails?.data?.marketCap)}`
                              : "-"}
                          </StyledParagraph>
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "20px", width: "100%", background: "#08568b", borderBottom: borderColor }}>
                        <div
                          className="token-card-info-div"
                        >
                          <StyledParagraph>5m</StyledParagraph>
                          <StyledParagraph style={{
                            color: covertFloat(tokensDetails?.data?.priceData?.variation5m) > 0 ? '#29ff29' : '#ff6161'
                          }}>
                            {tokensDetails?.data?.priceData?.price5m
                              ? `${covertFloat(tokensDetails?.data?.priceData?.variation5m)} %`
                              : "-"}
                          </StyledParagraph>
                        </div>
                        <div
                          className="token-card-info-div"
                        >
                          <StyledParagraph>1h</StyledParagraph>
                          <StyledParagraph style={{
                            color: covertFloat(tokensDetails?.data?.priceData?.variation1h) > 0 ? '#29ff29' : '#ff6161'
                          }}>
                            {tokensDetails?.data?.priceData?.price1h
                              ? `${covertFloat(tokensDetails?.data?.priceData?.variation1h)} %`
                              : "-"}
                          </StyledParagraph>{" "}
                        </div>
                        <div
                          className="token-card-info-div"
                        >
                          <StyledParagraph>6h</StyledParagraph>

                          <StyledParagraph style={{
                            color: covertFloat(tokensDetails?.data?.priceData?.variation6h) > 0 ? '#29ff29' : '#ff6161'
                          }}>
                            {tokensDetails?.data?.priceData?.price6h
                              ? `${covertFloat(tokensDetails?.data?.priceData?.variation6h)} %`
                              : "-"}
                          </StyledParagraph>
                        </div>
                        <div
                          className="token-card-info-div"
                          style={{
                            border: 'none'
                          }}
                        >
                          <StyledParagraph>24h</StyledParagraph>
                          <StyledParagraph style={{
                            color: covertFloat(tokensDetails?.data?.priceData?.variation24h) > 0 ? '#29ff29' : '#ff6161'
                          }}>
                            {tokensDetails?.data?.priceData?.price24h
                              ? `${covertFloat(tokensDetails?.data?.priceData?.variation24h)} %`
                              : "-"}
                          </StyledParagraph>
                        </div>
                      </div>


                      <StyledParagraph>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            borderBottom: borderColor
                          }}
                        >
                          <div style={{
                            width: "50%",
                            padding: "20px",
                            borderRight: borderColor
                          }}>
                            <div>
                              <FontAwesomeIcon icon={faClock} />
                              <span style={{ marginLeft: "5px" }}>Pair Age: </span>
                            </div>
                            <div>
                              <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#7070ff" }} />
                              <span style={{ marginLeft: "5px" }}>Holders: </span>
                            </div>
                          </div>

                          <div style={{
                            width: "50%",
                            padding: "20px",
                          }}>

                            <div>
                              <FontAwesomeIcon icon={faGift} style={{ color: "#f35757" }} />
                              <span style={{ marginLeft: "5px" }}>Total Supply: {tokensDetails?.data?.totalSupply
                                ? covertFloat(tokensDetails?.data?.totalSupply)
                                : "-"}</span>
                            </div>
                            <div>
                              <FontAwesomeIcon icon={faWater} style={{ color: "##86edf7" }} />
                              <span style={{ marginLeft: "5px" }}>Liquidity: {tokensDetails?.data?.liquidity
                                ? `$ ${covertFloat(tokensDetails?.data?.liquidity)}`
                                : "-"}</span>
                            </div>
                          </div>


                        </div>

                      </StyledParagraph>


                      <StyledParagraph>

                        <div
                          style={{
                            minHeight: "48px",
                            width: "100%",
                            display: 'flex',
                            borderBottom: borderColor
                          }}
                        >
                          <div style={{
                            width: "50%",
                            padding: '20px',
                            borderRight: borderColor
                          }}>

                            <div>
                              <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
                              <span style={{ marginLeft: "5px" }}>Highlights:</span>
                            </div>
                            {
                              mainGreenHighlight.map((item, index) => (
                                Object.entries(item).map(([key, value], idx) => (
                                  <div key={key}>
                                    <span>-</span>
                                    <span className="dot green"></span>
                                    <span style={{ marginLeft: "5px" }}>{key + " - " + value}</span>
                                  </div>
                                ))
                              ))}


                          </div>
                          <div style={{
                            width: "50%",
                            padding: '20px',
                          }}>
                            <div>

                              <div>
                                <FontAwesomeIcon icon={faMinusCircle} style={{ color: "red" }} />
                                <span style={{ marginLeft: "5px" }}>Issues (6):</span>
                              </div>
                              {
                                mainYellowIssue.map((item, index) => (
                                  Object.entries(item).map(([key, value], idx) => (
                                    <div key={key}>
                                      <span>-</span>
                                      <span className="dot yellow"></span>
                                      <span style={{ marginLeft: "5px" }}>{key + " - " + value}</span>
                                    </div>
                                  ))
                                ))}
                              {
                                mainRedIssue.map((item, index) => (
                                  Object.entries(item).map(([key, value], idx) => (
                                    <div key={key}>
                                      <span>-</span>
                                      <span className="dot red"></span>
                                      <span style={{ marginLeft: "5px" }}>{key + " - " + value}</span>
                                    </div>
                                  ))
                                ))}
                              <div>
                                <span>-</span>
                                <span className="dot red"></span>
                                <span style={{ marginLeft: "5px" }}>Liquidity not Locked - NA</span>
                              </div>
                              <div>
                                <span>-</span>
                                <span className="dot red"></span>
                                <span style={{ marginLeft: "5px" }}>Liquidity Partially Burnt - NA</span>
                              </div>
                              <div>
                                <span>-</span>
                                <span className="dot red"></span>
                                <span style={{ marginLeft: "5px" }}>Contract Wallet - Na</span>
                              </div>
                              <div>
                                <span>-</span>
                                <span className="dot red"></span>
                                <span style={{ marginLeft: "5px" }}>Socials Not Found - NA</span>
                              </div>
                            </div>

                          </div>

                        </div>

                      </StyledParagraph>



                      <StyledParagraph>

                        <div
                          style={{
                            width: "100%",
                            padding: "20px",
                          }}
                        >
                          <div>

                            <div>
                              <FontAwesomeIcon icon={faInfoCircle} style={{ color: "blue" }} />
                              <span style={{ marginLeft: "5px" }}>Other Details:</span>
                            </div>
                            <div>
                              <span>-</span>
                              <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#8888f5", marginLeft: "5px" }} />
                              <span style={{ marginLeft: "5px" }}>Contract Address: {tokensDetails?.contractAddress
                                ? tokensDetails?.contractAddress
                                : "-"}</span>
                              <img
                                onClick={() =>
                                  handleCopy(tokensDetails?.contractAddress)
                                }
                                src={copyicon}
                                alt=""
                                height={20}
                                width={20}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div>
                              <span>-</span>
                              <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#8888f5", marginLeft: "5px" }} />
                              <span style={{ marginLeft: "5px" }}>Pair Address: {tokensDetails?.pairAddress
                                ? tokensDetails?.pairAddress
                                : "-"}</span>
                              <img
                                onClick={() =>
                                  handleCopy(tokensDetails?.pairAddress)
                                }
                                src={copyicon}
                                alt=""
                                height={20}
                                width={20}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div>
                              <span>-</span>
                              {/* <FontAwesomeIcon icon={faFaceAngry} style={{ color: "yellow", marginLeft: "5px" }} /> */}
                              <span className="dot yellow"></span>
                              <span style={{ marginLeft: "5px" }}>Owner Address: {tokensDetails?.data?.ownerAddress}</span>
                            </div>
                            <div>
                              <span>-</span>
                              <FontAwesomeIcon icon={faEarth} style={{ color: "#e972ff", marginLeft: "5px" }} />
                              <span style={{ marginLeft: "5px" }}>Online Presence: Web/socials not found</span>
                            </div>
                          </div>
                        </div>

                      </StyledParagraph>

                      {/* {tokensDetails?.data?.socialInfo?.telegram ||
                    tokensDetails?.data?.socialInfo?.twitter ? (
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <StyledParagraph style={{ fontFamily: "20px" }}>
                        Socials
                      </StyledParagraph>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {tokensDetails?.data?.socialInfo.twitter && (
                          <Link
                            to={tokensDetails?.data?.socialInfo?.telegram}
                            target="_blank"
                          >
                            <img
                              src={telegramImg}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        )}
                        {tokensDetails?.data?.socialInfo.telegram && (
                          <Link
                            to={tokensDetails?.data?.socialInfo?.twitter}
                            target="_blank"
                          >
                            <img
                              src={twitter}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>No Social found</div>
                  )} */}
                    </>
                  ) : showReload ? (
                    <div
                      style={{
                        height: "500px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Button onClick={() => tokenInfo()} variant="outline-info">
                        Reload
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Select the Token from New Tokens list to view the Token details.{" "}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>) : (<>
        {typeof influencerData != 'undefined' && influencerData.length > 0 ? (<>
          {(location.pathname === "/token-trending" && click) ? <>
            <div className={window.innerWidth < 992 ? "pt-2" : ""}>

              {tagLoader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    height: "300px",
                  }}
                >
                  <Spinner
                    animation="border"
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              ) : (
                <div style={{ padding: "5px", width: "100%" }} className="border-style">
                  {/* <Table responsive className="token-trending-info-table">
                  <thead>
                    <tr>
                      <th>$ Genki matching Token</th>
                      <th>MC/Change</th>
                      <th>Security</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          1# <img src={cognoFi} alt="" height={20} width={20} /><span style={{ marginLeft: '5px', marginRight: '5px' }}>CGF/Weth</span>
                          <img src={copy} alt="" height={20} width={20} />
                        </span><br />
                        <span style={{ marginLeft: "20px" }}>
                          <img src={twitter} alt="" height={20} width={20} />
                          <img src={telegram} alt="" height={20} width={20} style={{ marginLeft: "5px" }} />
                        </span>

                      </td>
                      <td>
                        <span className="font-color">2</span><br />
                        <span className="font-color">120%</span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span className="token-trending-dot aqua"></span>
                          <span className="token-trending-dot red" style={{ marginLeft: "5px" }}></span>
                          <span className="token-trending-dot yellow" style={{ marginLeft: "5px" }}></span>
                          <span className="token-trending-dot aqua" style={{ marginLeft: "5px" }}></span>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          2# <img src={cognoFi} alt="" height={20} width={20} /><span style={{ marginLeft: '5px', marginRight: '5px' }}>CGF/Weth</span>
                          <img src={copy} alt="" height={20} width={20} />
                        </span><br />
                        <span style={{ marginLeft: "20px" }}>
                          <img src={twitter} alt="" height={20} width={20} />
                          <img src={telegram} alt="" height={20} width={20} style={{ marginLeft: "5px" }} />
                        </span>

                      </td>
                      <td>
                        <span className="font-color">2</span><br />
                        <span className="font-color">120%</span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span className="token-trending-dot aqua"></span>
                          <span className="token-trending-dot red" style={{ marginLeft: "5px" }}></span>
                          <span className="token-trending-dot yellow" style={{ marginLeft: "5px" }}></span>
                          <span className="token-trending-dot aqua" style={{ marginLeft: "5px" }}></span>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          3# <img src={cognoFi} alt="" height={20} width={20} /><span style={{ marginLeft: '5px', marginRight: '5px' }}>CGF/Weth</span>
                          <img src={copy} alt="" height={20} width={20} />
                        </span><br />
                        <span style={{ marginLeft: "20px" }}>
                          <img src={twitter} alt="" height={20} width={20} />
                          <img src={telegram} alt="" height={20} width={20} style={{ marginLeft: "5px" }} />
                        </span>

                      </td>
                      <td>
                        <span className="font-color">2</span><br />
                        <span className="font-color">120%</span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span className="token-trending-dot aqua"></span>
                          <span className="token-trending-dot red" style={{ marginLeft: "5px" }}></span>
                          <span className="token-trending-dot yellow" style={{ marginLeft: "5px" }}></span>
                          <span className="token-trending-dot aqua" style={{ marginLeft: "5px" }}></span>
                        </div>

                      </td>
                    </tr>
                  </tbody>
                </Table> */}
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "0 auto",
                      padding: "5px",
                      borderBottom: borderColor
                    }}
                  >
                    <StyledParagraph >
                      <span style={{ fontSize: "20px" }}>
                        {` ${tokensDetails?.tokenInfo?.name || "Young Peezy (PEEZY)"} (${tokensDetails?.tokenInfo?.symbol || "-"
                          })`} <a target="_balnk" href={chainLinkforDextool[tokensDetails?.tokenInfo?.chain] + tokensDetails?.contractAddress}
                            title="Dextool"
                            rel="noopener noreferrer"
                          >
                          <img
                            src={dexTool}
                            alt=""
                            height={22}
                            width={22}
                          />
                        </a>
                        <a href='#' style={{ marginLeft: '5px' }}
                          onClick={() => handleShow(tokensDetails?.contractAddress, chainText[tokensDetails?.tokenInfo?.chain])}>
                          <img
                            src={safeLogo}
                            alt=""
                            height={22}
                            width={22}
                          />
                        </a>
                      </span>
                      <a target="_balnk" href={'https://t.me/MaestroProBot?start=' + tokensDetails?.contractAddress + '-cogerstro'}
                        style={{ marginLeft: "5px" }}>
                        <img
                          src={maestroPro}
                          alt=""
                          height={22}
                          width={22}
                        />
                      </a>
                      <a target="_balnk" href={'https://t.me/MaestroSniperBot?start=' + tokensDetails?.contractAddress + '-cogerstro'}
                        style={{ marginLeft: "5px" }}>
                        <img
                          src={maestroSniper}
                          alt=""
                          height={22}
                          width={22}
                        />
                      </a>
                      <SafeAnalyzerrModal
                        show={show}
                        setShow={setShow}
                        chianForGeekSwap={chianForGeekSwap}
                        contractAddressForSafeAnalyzer={contractAddressForSafeAnalyzer}
                      />

                    </StyledParagraph>
                  </div>
                  <div style={{ display: "flex", width: "100%", borderBottom: borderColor }}>
                    <div
                      style={{
                        textAlign: "center",
                        width: "50%",
                        borderRight: '1px solid white',
                        padding: '5px'
                      }}
                    >
                      <StyledParagraph>Price - {tokensDetails?.data?.priceData?.price
                        ? `$ ${formatScientificNotation(tokensDetails?.data?.priceData.price)}`
                        : "-"}</StyledParagraph>
                      {/* <StyledParagraph>
                      {tokensDetails?.data?.priceData?.price
                        ? `$ ${formatScientificNotation(tokensDetails?.data?.priceData.price)}`
                        : "-"}
                    </StyledParagraph> */}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "50%",
                        padding: '5px'
                      }}
                    >
                      <StyledParagraph>Market Cap - {tokensDetails?.data?.marketCap
                        ? `$ ${covertFloat(tokensDetails?.data?.marketCap)}`
                        : "-"}</StyledParagraph>
                      {/* <StyledParagraph>
                      {tokensDetails?.data?.marketCap
                        ? `$ ${covertFloat(tokensDetails?.data?.marketCap)}`
                        : "-"}
                    </StyledParagraph> */}
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "100%", background: "#08568b", borderBottom: borderColor }}>
                    <div
                      className="token-card-info-div-for-sentiment"
                    >
                      <StyledParagraph>1h : <span style={{
                        color: covertFloat(tokensDetails?.data?.priceData?.variation1h) > 0 ? '#29ff29' : '#ff6161'
                      }}>{tokensDetails?.data?.priceData?.price1h
                        ? `${covertFloat(tokensDetails?.data?.priceData?.variation1h)} %`
                        : "-"}</span></StyledParagraph>
                    </div>
                    <div
                      className="token-card-info-div-for-sentiment"
                      style={{
                        border: 'none'
                      }}
                    >
                      <StyledParagraph>24h : <span style={{
                        color: covertFloat(tokensDetails?.data?.priceData?.variation24h) > 0 ? '#29ff29' : '#ff6161'
                      }}>{tokensDetails?.data?.priceData?.price24h
                        ? `${covertFloat(tokensDetails?.data?.priceData?.variation24h)} %`
                        : "-"}</span></StyledParagraph>
                    </div>
                  </div>


                  <StyledParagraph>

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        borderBottom: borderColor
                      }}
                    >
                      <div style={{
                        width: "50%",
                        padding: "5px",
                        borderRight: borderColor
                      }}>
                        <div>
                          <FontAwesomeIcon icon={faClock} />
                          <span style={{ marginLeft: "5px" }}>Pair Age: </span>
                        </div>
                        <div>
                          <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#7070ff" }} />
                          <span style={{ marginLeft: "5px" }}>Holders: </span>
                        </div>
                      </div>

                      <div style={{
                        width: "50%",
                        padding: "5px",
                      }}>

                        <div>
                          <FontAwesomeIcon icon={faGift} style={{ color: "#f35757" }} />
                          <span style={{ marginLeft: "5px" }}>Total Supply: {tokensDetails?.data?.totalSupply
                            ? covertFloat(tokensDetails?.data?.totalSupply)
                            : "-"}</span>
                        </div>
                        <div>
                          <FontAwesomeIcon icon={faWater} style={{ color: "##86edf7" }} />
                          <span style={{ marginLeft: "5px" }}>Liquidity: {tokensDetails?.data?.liquidity
                            ? `$ ${covertFloat(tokensDetails?.data?.liquidity)}`
                            : "-"}</span>
                        </div>
                      </div>


                    </div>

                  </StyledParagraph>


                  <StyledParagraph>

                    <div
                      style={{
                        minHeight: "48px",
                        width: "100%",
                        display: 'flex',
                        borderBottom: borderColor
                      }}
                    >
                      <div style={{
                        width: "50%",
                        padding: '5px',
                        borderRight: borderColor
                      }}>

                        <div>
                          <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
                          <span style={{ marginLeft: "5px" }}>Highlights:</span>
                        </div>
                        {
                          mainGreenHighlight?.map((item, index) => (
                            Object.entries(item)?.map(([key, value], idx) => (
                              <div key={key}>
                                <span>-</span>
                                <span className="dot green"></span>
                                <span style={{ marginLeft: "5px" }}>{key + " - " + value}</span>
                              </div>
                            ))
                          ))}


                      </div>
                      <div style={{
                        width: "50%",
                        padding: '5px',
                      }}>
                        <div>

                          <div>
                            <FontAwesomeIcon icon={faMinusCircle} style={{ color: "red" }} />
                            <span style={{ marginLeft: "5px" }}>Issues (6):</span>
                          </div>
                          {
                            mainYellowIssue?.map((item, index) => (
                              Object.entries(item)?.map(([key, value], idx) => (
                                <div key={key}>
                                  <span>-</span>
                                  <span className="dot yellow"></span>
                                  <span style={{ marginLeft: "5px" }}>{key + " - " + value}</span>
                                </div>
                              ))
                            ))}
                          {
                            mainRedIssue?.map((item, index) => (
                              Object.entries(item)?.map(([key, value], idx) => (
                                <div key={key}>
                                  <span>-</span>
                                  <span className="dot red"></span>
                                  <span style={{ marginLeft: "5px" }}>{key + " - " + value}</span>
                                </div>
                              ))
                            ))}
                          <div>
                            <span>-</span>
                            <span className="dot red"></span>
                            <span style={{ marginLeft: "5px" }}>Liquidity not Locked - NA</span>
                          </div>
                          <div>
                            <span>-</span>
                            <span className="dot red"></span>
                            <span style={{ marginLeft: "5px" }}>Liquidity Partially Burnt - NA</span>
                          </div>
                          <div>
                            <span>-</span>
                            <span className="dot red"></span>
                            <span style={{ marginLeft: "5px" }}>Contract Wallet - Na</span>
                          </div>
                          <div>
                            <span>-</span>
                            <span className="dot red"></span>
                            <span style={{ marginLeft: "5px" }}>Socials Not Found - NA</span>
                          </div>
                        </div>

                      </div>

                    </div>

                  </StyledParagraph>



                  <StyledParagraph>

                    <div
                      style={{
                        width: "100%",
                        padding: "5px",
                      }}
                    >
                      <div>

                        <div>
                          <FontAwesomeIcon icon={faInfoCircle} style={{ color: "blue" }} />
                          <span style={{ marginLeft: "5px" }}>Other Details:</span>
                        </div>
                        <div>
                          <span>-</span>
                          <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#8888f5", marginLeft: "5px" }} />
                          <span style={{ marginLeft: "5px" }}>Contract Address: {tokensDetails?.contractAddress
                            ? tokensDetails?.contractAddress
                            : "-"}</span>
                          <img
                            onClick={() =>
                              handleCopy(tokensDetails?.contractAddress)
                            }
                            src={copyicon}
                            alt=""
                            height={20}
                            width={20}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div>
                          <span>-</span>
                          <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#8888f5", marginLeft: "5px" }} />
                          <span style={{ marginLeft: "5px" }}>Pair Address: {tokensDetails?.pairAddress
                            ? tokensDetails?.pairAddress
                            : "-"}</span>
                          <img
                            onClick={() =>
                              handleCopy(tokensDetails?.pairAddress)
                            }
                            src={copyicon}
                            alt=""
                            height={20}
                            width={20}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div>
                          <span>-</span>
                          {/* <FontAwesomeIcon icon={faFaceAngry} style={{ color: "yellow", marginLeft: "5px" }} /> */}
                          <span className="dot yellow"></span>
                          <span style={{ marginLeft: "5px" }}>Owner Address: {tokensDetails?.data?.ownerAddress}</span>
                        </div>
                        <div>
                          <span>-</span>
                          <FontAwesomeIcon icon={faEarth} style={{ color: "#e972ff", marginLeft: "5px" }} />
                          <span style={{ marginLeft: "5px" }}>Online Presence: Web/socials not found</span>
                        </div>
                      </div>
                    </div>

                  </StyledParagraph>

                </div>)}



            </div>
            <div className="mt-3">
              <div>
                <span className="font-color" style={{ fontSize: "20px" }}>Activity</span>&nbsp;<span style={{ fontSize: "20px" }}>Frequency</span>
              </div>
              <Row>
                <div className="col-5">
                  {/* <h5>Sentiment</h5> */}
                  <DonutChart data={tokenSentimentChartData} title="Sentiment" name="Sentiment" />
                </div>
                <div className="col-7" style={{ marginTop: "5px" }}>
                  <h5>Sentiment by Network</h5>
                  {tokenSentimentDetails?.map((item, index) => (
                    <div style={{ display: "flex", alignItems: "center" }} className="mt-2" key={index}>
                      <img src={item.image} alt="" height={25} width={25} />
                      <ProgressBar style={{ height: "5px", width: "100%", marginLeft: "5px" }}>
                        <ProgressBar variant="success" now={item.positive} key={1} />
                        <ProgressBar variant="warning" now={item.neutral} key={2} />
                        <ProgressBar variant="danger" now={item.negative} key={3} />
                      </ProgressBar>
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          </> :
            <div className={window.innerWidth < 992 ? "pt-2" : ""}>
              < div style={{ padding: "1px", width: "100%" }} className="border-style-background">
                <div ref={thirdDivRef} className="trending-contract-table-scroll livefeed" style={{ borderRadius: "15px", background: "#171721" }}>
                  <Table responsive striped className="text-start main-dashboard-table dashboard-table-common">
                    <tbody>
                      {influencerData.map((item, index) => (
                        <tr key={index}>
                          <td width="15%">
                            <img
                              src={getProfileImage(item.influencerDetails)}
                              alt="User Profile"
                              width={45}
                              height={45}
                              style={{
                                border: "1px solid white",
                                borderRadius: "45px",
                                padding: "3px",
                              }}
                            />
                          </td>
                          <td width="70%">
                            <div
                              style={{
                                marginLeft: "20px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item?.influencerDetails?.influncerName ?? "-"}
                              <div
                                style={{
                                  width: "100%",
                                  fontSize: "11px",
                                  lineHeight: "17.64px",
                                  fontWeight: 400,
                                  color: "rgba(210, 210, 210, 1)",
                                  marginTop: "5px",
                                }}
                              >
                                {item?.influencerDetails?.followers ?? "-"} Followers
                              </div>
                            </div>
                          </td>
                          <td width="15%">
                            {item?.total_calls ?? "-"} Calls
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
        </>
        ) : (<></>)}
      </>)
      }
    </TelegramCardStyle >
  );
};

export default TagCards;
