import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { _get, _post } from "../../api/ApiClient";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../components/commonFunction/commonFunction";
import { Spinner } from "react-bootstrap";


const RedeemPointHistory = () => {
  const userData = useSelector((state) => state.ROOT);
  const [redeemPointHistory, setRedeemPointHistory] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getRedeemPointHistory()

  }, [])

  const getRedeemPointHistory = async () => {
    try {
      const response = await _get("/v1/user/get-redeem-points-history", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const res = response.data
      if (res.status) {
        setIsLoading(false)
        setRedeemPointHistory(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Layout>
      <Row>
        <div className="col-12 center-class" style={{ justifyContent: "space-between" }}>
          <div className="center-class">
            <span className="font-color" style={{ fontSize: "33px", fontWeight: "500" }}>Redeem</span>
            <span style={{ fontSize: "33px", fontWeight: "500", color: "white", marginLeft: "10px" }}>Point History</span>
          </div>
        </div>
      </Row>
      <Row className="pt-2">
        <div className="col-12">
          {isLoading ?
            <div className="pt-4 text-center">
              <Spinner
                animation="border"
                style={{ height: "100px", width: "100px", color: "white" }}
              />
            </div> :
            redeemPointHistory.length > 0 ? <div className="border-style-background">
              <Table responsive striped className="text-center referral-page-table">
                <thead>
                  <tr>
                    <th>Points</th>
                    <th>Redeem Url</th>
                    <th>Redeem On</th>
                  </tr>
                </thead>
                <tbody>
                  {redeemPointHistory?.map((item, index) => (
                    <tr className="referral-page-table-bottom-border">
                      <td className="referral-page-table-right-border">{item?.points}</td>
                      <td className="referral-page-table-right-border">
                        <a href={item?.redeem_url} target="_blank" style={{ color: "white" }}>{item?.redeem_url}</a>
                      </td>
                      <td>{formatDate(item?.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div> : <div className="pt-4 text-center" style={{ color: "white" }}>
              No Data Found..
            </div>
          }
        </div>
      </Row>
    </Layout>
  );
};

export default RedeemPointHistory;
