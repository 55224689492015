import { Button, Form, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { chainDataForDrawer } from "../../components/commonFunction/commonFunction"
import { Input } from "antd";
const { Search } = Input;
export const ChainDrawerModal = ({
  showModal,
  setShowModal,
  isSelectChain, setIsSelectChain
}) => {

  const [selectedCard, setSelectedCard] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const handleClose = () => {
    setShowModal(false);
  };

  const filteredChains = chainDataForDrawer.filter((item) =>
    item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const handleCardClick = (index, item) => {
    setSelectedCard(index);
    setIsSelectChain((prevState) => ({
      isOpen: !prevState.isOpen,
      current: item.name,
      value: item.value
    }));
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      className="chain-drawer-model"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ background: "#091420", borderBottom: "none" }}
      >
        <Modal.Title style={{ color: "#ffffff" }}>
          Chain Filter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#091420" }}>
        <div className="search-container">
          <Search
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            onSearch={handleSearch}
            enterButton
          />
        </div>
        <div className="chain-grid">
          {filteredChains.map((item, index) => (
            <div
              key={index}
              className={`card-item ${selectedCard === index ? "active" : ""}`}
              onClick={() => handleCardClick(index, item)}
            >
              {/* {index !== 0 && <img src={item.imgSrc} alt="" />} */}
              <img src={item.imgSrc} alt="" width={25} height={25} />
              <p style={{ color: "#fff", fontSize: "11px" }}>{item.name}</p>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ background: "#091420", borderTop: "none" }}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
