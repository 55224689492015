import React from "react";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { Button, Row } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { _get } from "../../api/ApiClient";
import { handleCopy, formatNumber, convertTo24HrFormat, platformLogo, twoDigitNumber, chainLinkforDextool } from "../../components/commonFunction/commonFunction";
import { getSocket } from "../../socket";
import newContract from "../../assets/images/new_contract.svg";
import preSale from "../../assets/images/presell.svg";
import trendingToken from "../../assets/images/trending_token.svg";
import aiDrop from "../../assets/images/airdrop.svg";
import fireDrop from "../../assets/images/fire.svg";
import globe from "../../assets/images/glob.svg";
import cognoFi from "../../assets/images/cognofi_new.svg";
import copy from "../../assets/images/copy.svg";
import tweet from "../../assets/images/twitter.svg";
import telegram from "../../assets/images/telegram.svg";
import geek_swap from "../../assets/images/geek_swap.svg";
import safeanalyzer from "../../assets/images/safeAnalyzer.svg";
import defaultProfile from "../../assets/images/default-profile.jpg"
import AreaChart from "./AreaChart"
import CustomBarChart from "./CustomBarChart"
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
import maestroSniper from "../../assets/images/MastroSniper.jpg";
import maestroPro from "../../assets/images/MaestroPro.jpg";
import dexTool from "../../assets/images/dextools_logo.png";
import { useLocation, useNavigate } from "react-router-dom";

const MainDashboardInner = () => {
  const userData = useSelector((state) => state.ROOT);
  const [newDashboardData, setNewDashboardData] = useState(null)
  const [topPerfomingInfluencers, setTopPerfomingInfluencers] = useState(null)
  const [topTrendingToken, setTopTrendingToken] = useState(null)
  const [topTrendingTokenChart, setTopTrendingTokenChart] = useState(null)
  const [topPerformingToken, setTopPerformingToken] = useState(null)
  const [activeButton, setActiveButton] = useState("all");
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      socket?.emit("get_dashboard_influencer_activity", {
        data: {},
      });
      if (userData?.jwtToken) {
        socket?.emit("get_dashboard_top_performing_tokens", {
          data: {},
        });
        socket?.emit("get_dashboard_top_performing_influencers", {
          data: {},
        });
      }
      socket?.emit("get_dashboard_top_trending_tokens", {
        data: {},
      });
      socket?.emit("get_dashboard_top_trending_tokens_chart", {
        data: {},
      });

      socket.on("dashboard_influencer_activity", (data) => {
        setNewDashboardData(data)
      });
      if (userData?.jwtToken) {
        socket.on("dashboard_top_performing_tokens", (data) => {
          const limitedData = data.slice(0, 5);
          setTopPerformingToken(limitedData)
        });
        socket.on("dashboard_top_performing_influencers", (data) => {
          setTopPerfomingInfluencers(data)
        });
      }
      socket.on("dashboard_top_trending_tokens", (data) => {
        setTopTrendingToken(data)
      });
      socket.on("dashboard_top_trending_tokens_chart", (data) => {
        setTopTrendingTokenChart(data)
      });

      return () => {
        socket.off("dashboard_influencer_activity");
        socket.off("dashboard_top_performing_tokens");
        socket.off("dashboard_top_performing_influencers");
        socket.off("dashboard_top_trending_tokens");
        socket.off("dashboard_top_trending_tokens_chart");
      };
    }
  }, [])

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const getMatchedContractLink = (token, contractLink) => {
    token = token?.slice(1)
    let obj = contractLink?.find(link => link?.symbol?.toLowerCase() === token || link?.name?.toLowerCase() === token);
    return obj
  }

  const getMatchedContractLinkLogo = (token, contractLink) => {
    let logo = cognoFi
    let obj = getMatchedContractLink(token, contractLink)
    if (obj) {
      logo = obj?.logo ? (obj?.logo != "" ? obj?.logo : cognoFi) : cognoFi
    }
    return logo
  }

  const getMatchedContractLinkForDextool = (token, contractLink, copy = false) => {
    let obj = getMatchedContractLink(token, contractLink)
    if (obj?.chain && !copy) {
      return chainLinkforDextool[obj?.chain] + obj?.address
    } else if (obj?.chain && copy) {
      return obj?.address
    }
    return ""
  }

  return (
    <>
      <Row style={{ color: 'white' }}>
        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
          <h3>Influencer Activity</h3>
          <div>
            <span style={{ fontSize: '22px', color: '#8A8A96' }} className="mt-2">Scanned <h5 className="font-color" style={{ display: 'inline' }}>{newDashboardData?.messageCount?.total_messages}</h5> messages in past 24 hour from <h5 className="font-color" style={{ display: 'inline' }}>{newDashboardData?.influencersCount?.total}</h5> influencers {/* (<span className="font-color">{newDashboardData?.influencersCount?.tweet}</span> Twitter, <span className="font-color">{newDashboardData?.influencersCount?.telegram}</span> Telegram) */}</span>
          </div>
          <div className="dashboard-main-box-wrapper mt-3">
            <div className="dashboard-main-box border-style mobile-d-block">
              <h1 className="dashboard-main-box-font-count mobile-d-block">{newDashboardData?.messageCount?.new_contracts}</h1>
              <span className="dashboard-main-box-font-name mobile-d-block">New Contract
                Shared</span>
              <div className="dashboard-main-box-font-name image-container display-none">
                <img src={newContract} alt="" className="responsive-image" />
              </div>
            </div>
            <div className="dashboard-main-box border-style mobile-d-block">

              <h1 className="dashboard-main-box-font-count mobile-d-block">{newDashboardData?.messageCount?.presell}</h1>
              <span className="dashboard-main-box-font-name mobile-d-block">Presale</span>
              <div className="dashboard-main-box-font-name image-container display-none">
                <img src={preSale} alt="" className="responsive-image" />
              </div>
            </div>
          </div>
          <div className="dashboard-main-box-wrapper" style={{ marginTop: '35px' }}>
            <div className="dashboard-main-box border-style mobile-d-block">
              <h1 className="dashboard-main-box-font-count mobile-d-block">{newDashboardData?.messageCount?.token_trending}</h1>
              <span className="dashboard-main-box-font-name mobile-d-block">Trending $token</span>
              <div className="dashboard-main-box-font-name image-container display-none">
                <img src={trendingToken} alt="" className="responsive-image" />
              </div>
            </div>
            <div className="dashboard-main-box border-style mobile-d-block">

              <h1 className="dashboard-main-box-font-count mobile-d-block">{newDashboardData?.messageCount?.airdrop}</h1>
              <span className="dashboard-main-box-font-name mobile-d-block">Airdrop</span>
              <div className="dashboard-main-box-font-name image-container display-none">
                <img src={aiDrop} alt="" className="responsive-image" />
              </div>
            </div>
          </div>

        </div>
        <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12 text-center border-style margin-top">
          <AreaChart data={newDashboardData?.token_creation_chart} />
        </div>

      </Row>

      {userData?.jwtToken && location.pathname === "/dashboard" ? <>
        <Row className="mt-3 text-start">
          <div className="p-0">
            <img src={fireDrop} alt="" height={40} width={40} />
            {/* <Button
              variant="primary" className={`dashboard-btn ${activeButton === "your" ? "active" : ""}`}
              onClick={() => handleButtonClick("your")}
            >
              <div className="globe-div">
                <img src={globe} alt="" height={30} width={30} />
              </div>
              <span style={{ marginLeft: '5px' }}>Your Influencer</span>
            </Button> */}
            <Button
              variant="primary" className={`dashboard-btn ${activeButton === "all" ? "active" : ""}`} style={{ marginLeft: '10px' }}
              onClick={() => handleButtonClick("all")}
            >
              <div className="globe-div">
                <img src={globe} alt="" height={30} width={30} />
              </div>
              <span style={{ marginLeft: '5px' }}>24hr top Signal</span>
            </Button>
          </div>

        </Row>

        <Row className="mt-3">
          <div>
            <div className="border-style-background">
              {topPerformingToken?.length > 0 ? <Table responsive striped className="text-start main-dashboard-table dashboard-table-common">
                <thead>
                  <tr>
                    <th></th>
                    <th>Social</th>
                    <th>Price</th>
                    <th>Signal Price</th>
                    <th>Call Growth</th>
                    {/* <th>Liquidity/I. Liquidity</th> */}
                    <th>Market Cap</th>
                    <th>Max ROI</th>
                    <th>1st Call</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {topPerformingToken?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {index + 1}# <img className="round-img" src={item?.token_info?.logo ? item?.token_info?.logo : cognoFi} alt="" height={20} width={20} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>{item.symbol}/{item?.pair_info?.quote_token_symbol}</span>
                        <img className="c-point" src={copy} alt="" height={20} width={20} onClick={() => handleCopy(item.contract_address)} />
                        {item?.token_info?.chain && <a target="_balnk" href={chainLinkforDextool[item?.token_info?.chain] + item.contract_address}
                          title="Dextool" rel="noopener noreferrer">
                          <img
                            src={dexTool}
                            alt=""
                            height={22}
                            width={22}
                          />
                        </a>}
                      </td>
                      <td>
                        <span>{item.message_stats.find(p => p._id === "x.com")?.total_messages || 0}-</span><img src={tweet} alt="" height={20} width={20} />
                        {" "}<span>{item.message_stats.find(p => p._id === "telegram")?.total_messages || 0}-</span>
                        <img src={telegram} alt="" height={20} width={20} />
                      </td>
                      <td>
                        {/* {twoDigitNumber(item?.price_data?.latestPrice, 5)} */}
                        ${item?.price_data?.latestPrice?.toExponential(3)}
                      </td>
                      <td><span className="font-color">${formatNumber(item?.price1)} </span></td>
                      <td>
                        {twoDigitNumber(item?.price_data?.priceChange)}%
                      </td>
                      {/* <td><span className="font-color">${formatNumber(item?.price_data?.liquidity)} </span>/ ${formatNumber(item?.price_data?.initialLiquidity)}</td> */}
                      <td><span className="font-color">${formatNumber(item.lcap)}</span></td>
                      <td><span className="font-color">{twoDigitNumber(item.performance)}X</span></td>
                      <td>
                        <div style={{ display: "flex" }}>
                          <div onClick={() => navigate(`/influencer-details/${item?.first_message_from?._id}`)} style={{ cursor: "pointer" }}>
                            <img src={item?.first_message_from?.platform == "telegram" ? defaultProfile : item.first_message_from.profile} alt="" height={30} width={30} />
                            <span style={{ marginLeft: '5px' }}>{item.first_message_from.influncerName}</span>
                          </div>
                          <div>
                            <a target="_balnk" href={item.first_message_from.profile_link} style={{ marginLeft: "5px" }}>
                              <img
                                src={platformLogo[item.first_message_from.platform]}
                                alt=""
                                height={20}
                                width={20}
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      {/* <td><img src={geek_swap} alt="" height={30} width={30} /> <img src={safeanalyzer} alt="" height={30} width={30} style={{ marginLeft: '5px' }} /></td> */}
                      <td>
                        <a target="_balnk" href={'https://t.me/MaestroSniperBot?start=' + item.contract_address + '-cogerstro'}
                          title="Maestro Sniper Bot">
                          <img
                            src={maestroSniper}
                            alt=""
                            height={30}
                            width={30}
                          />
                        </a>
                        <a target="_balnk" href={'https://t.me/MaestroProBot?start=' + item.contract_address + '-cogerstro'}
                          title="Maestro Pro Bot" style={{ marginLeft: "10px" }}>
                          <img
                            src={maestroPro}
                            alt=""
                            height={30}
                            width={30}
                          />
                        </a>
                        <a target="_balnk" href={'https://t.me/SafeAnalyzerbot?start=' + item.contract_address}
                          title="Safe Analyzer" style={{ marginLeft: "10px" }}>
                          <img
                            src={safeanalyzer}
                            alt=""
                            height={30}
                            width={30}
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table> :
                <div style={{ background: "#13273c", borderRadius: "15px", padding: "15px" }} className="text-center">
                  <Spinner animation="border" style={{ height: "50px", width: "50px", color: "white" }} />
                </div>
              }
            </div>
          </div>
        </Row>

        <Row className="mt-3 text-start" style={{ color: 'white' }}>
          <div className="p-0" style={{ display: 'flex', alignItems: 'center' }}>
            <img src={fireDrop} alt="" height={40} width={40} />
            <h5>Kols Board (past 24hr)</h5>
          </div>
        </Row>

        <Row className="mt-3">
          <div>
            <div className="border-style-background">
              {topPerfomingInfluencers?.length > 0 ? <Table responsive striped className="text-center main-dashboard-table dashboard-table-common">
                <thead>
                  <tr>
                    <th></th>
                    <th>Total</th>
                    <th>Contract</th>
                    <th>Presale</th>
                    <th>$token</th>
                    <th>Air Drop</th>
                    <th>Average ROI</th>
                    <th>Success ratio</th>
                    <th>Followers</th>
                    <th>5Min Volume</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                <td>1# <img src={token} alt="" height={30} width={30} /> <span style={{ marginLeft: '5px', marginRight: '5px' }}>MadApes</span>
                  <img src={telegram} alt="" height={20} width={20} /></td>
                <td>650</td>
                <td>50</td>
                <td>150</td>
                <td>25</td>
                <td><span className="font-color">150</span></td>
                <td><span className="font-color">2X</span></td>
                <td><span className="font-color">50%</span></td>
                <td><span className="font-color">90K</span></td>
                <td><span className="font-color">$100K</span></td>
              </tr> */}
                  {topPerfomingInfluencers?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-start">
                        <div style={{ display: "flex" }}>
                          <div onClick={() => navigate(`/influencer-details/${item?.influencer_id}`)} style={{ cursor: "pointer" }}>
                            {index + 1}#&nbsp;
                            <img className="round-img" src={item.platform == "telegram" ? defaultProfile : item.profile_image} alt="" height={30} width={30} />
                            <span style={{ marginLeft: '5px', marginRight: '5px' }}>{item.influncerName}</span>
                          </div>
                          <div>
                            <a target="_balnk" href={item.profile_link} >
                              <img
                                src={platformLogo[item.platform]}
                                alt=""
                                height={20}
                                width={20}
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>{item.total_messages}</td>
                      <td>50</td>
                      <td>{item.total_prelaunch_tags}</td>
                      <td>{item.total_dollar_tokens}</td>
                      <td><span className="font-color">{item.total_airdrop}</span></td>
                      <td><span className="font-color">{twoDigitNumber(item.performance)}X</span></td>
                      <td><span className="font-color">{item.success_ratio}%</span></td>
                      <td><span className="font-color">{formatNumber(item.followers)}</span></td>
                      <td><span className="font-color">${formatNumber(item?.m5transactions?.volume)}</span></td>
                    </tr>
                  ))}
                </tbody>
              </Table> :
                <div style={{ background: "#13273c", borderRadius: "15px", padding: "15px" }} className="text-center">
                  <Spinner animation="border" style={{ height: "50px", width: "50px", color: "white" }} />
                </div>}
            </div>
          </div>
        </Row>
      </> : <></>}

      <Row className="mt-3 text-start" style={{ color: 'white' }}>
        <div className="p-0" style={{ display: 'flex', alignItems: 'center' }}>
          <img src={fireDrop} alt="" height={40} width={40} />
          <h5>Top trending $Token</h5>
        </div>
      </Row>
      <Row className="mt-3">
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 ">
          <div className="border-style" style={{ padding: '7px' }}>
            <CustomBarChart data={topTrendingTokenChart} />
          </div>
        </div>

        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 margin-top" style={{ color: 'white' }}>
          {/* <h4>Top trending $Token</h4> */}
          <div>
            <div className="border-style-background">
              <Table responsive striped className="text-center main-dashboard-table dashboard-table-common">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-start">Social</th>
                    <th>1st Call</th>
                  </tr>
                </thead>
                <tbody>
                  {topTrendingToken?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-start">
                        {index + 1}# <img className="round-img" src={getMatchedContractLinkLogo(item?.tag, item?.matched_contract_link)} alt="" height={20} width={20} /><span style={{ marginLeft: '5px', marginRight: '5px' }}>{item.tag}</span>
                        {getMatchedContractLinkForDextool(item?.tag, item?.matched_contract_link, true) &&
                          <img className="c-point" src={copy} alt="" height={20} width={20} onClick={() => handleCopy(getMatchedContractLinkForDextool(item?.tag, item?.matched_contract_link, true))} />}
                        {getMatchedContractLinkForDextool(item?.tag, item?.matched_contract_link) && <a target="_balnk" href={getMatchedContractLinkForDextool(item?.tag, item?.matched_contract_link)}
                          title="Dextool" rel="noopener noreferrer">
                          <img
                            src={dexTool}
                            alt=""
                            height={22}
                            width={22}
                          />
                        </a>}

                      </td>
                      <td className="text-start">
                        <span>{item.platforms.find(p => p.platform === "x.com")?.count || 0}-</span><img src={tweet} alt="" height={20} width={20} />
                        {" "}<span>{item.platforms.find(p => p.platform === "telegram")?.count || 0}-</span><img src={telegram} alt="" height={20} width={20} />
                      </td>
                      <td>{convertTo24HrFormat(item.first_call_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default MainDashboardInner;
