import React, { useCallback, useEffect, useState } from "react";
import { ContainerStyled } from "./styled";
// import logoimg from "../../assets/images/header-logo-new.png";
import logoimg from "../../assets/images/KolsAILogo.png";
import TwitterImg from "../../assets/images/twitter.png";
import TelegramImg from "../../assets/images/telegram.png";
import DexToolImg from "../../assets/images/dextools.webp";
import RobotImg from "../../assets/images/robot2.png";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import {
  useDisconnect,
  useAccount,
  useSignMessage,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  walletAddress,
  userId,
  profileUrl,
  role,
  jwtToken,
  firstName,
  lastName,
  twitterAc,
  telegramAc,
  subscriptionDetails,
  balance
} from "../../redux/user/UserSlice";
import { _post } from "../../api/ApiClient";
import { Button, Modal, Spinner, Row } from "react-bootstrap";
import loginX from "../../assets/images/login-x.svg";
import loginTelegram from "../../assets/images/login-telegram.svg";
import bulleteIcon from "../../assets/images/bullet-icon.svg";
import loginMail from "../../assets/images/login-mail.svg";
import loginYoutube from "../../assets/images/youtubeLogin.svg";
import loginMedium from "../../assets/images/mediumLogin.svg";
import MainDashboardInner from "../../../src/pages/MainDashboard/MainDashboardInnerPart";
import LoginWithWallet from "./LoginWithWallet"
const LoginPage = () => {
  const userData = useSelector((state) => state.ROOT);
  const { chains, error, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const [isSigned, setIsSigned] = useState({ status: false, signed: "" });
  const [loading, setLoading] = useState(false);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const { data: signMessageData, isLoading, signMessage } = useSignMessage();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate(); // Initialize useHistory
  const { chain } = useNetwork();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const isLoggedIn = userData?.jwtToken
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard")
    } else {
      setIsCheckingAuth(false);
    }
  }, [isLoggedIn, navigate])

  if (isCheckingAuth) {
    return null;
  }

  const signInWithEmail = () => {
    navigate("/login")
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          background: '#171721',
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div
          style={{
            background: "url('/curve.svg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div className="container-fluid">
            <Row>
              <div className="row login-page-headre-logo">
                <div className="col-xl-10 col-lg-10 col-md-8 col-sm-6 col-12" style={{ paddingLeft: "50px" }}>
                  <img src={logoimg} alt="headerLogo" height={75} width={220} style={{ marginTop: "10px" }} />
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <a href="https://x.com/kolsai_io" target="_blank">
                    <img src={loginX} width={40} height={40} />
                  </a>
                  <a href="https://t.me/Kolsai_io" target="_blank">
                    <img src={loginTelegram} width={40} height={40} style={{ marginLeft: "10px" }} />
                  </a>
                  <a href="https://www.youtube.com/@KolsAI-io" target="_blank">
                    <img src={loginYoutube} width={40} height={40} style={{ marginLeft: "10px" }} />
                  </a>
                  <a href="https://medium.com/@support_89738" target="_blank">
                    <img src={loginMedium} width={40} height={40} style={{ marginLeft: "10px" }} />
                  </a>
                </div>
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-lg-7">
                <div style={{ paddingLeft: "50px" }}>
                  <div >
                    <span className="font-color" style={{ fontSize: "35px", fontWeight: "600" }}>Your Crypto Insights Hub:</span><br />
                    <span style={{ fontSize: "50px", fontWeight: "600", color: "white", lineHeight: "1" }}>Never Miss a KOL Signal</span>
                  </div>
                  <div className="text-div-login mt-3">
                    <div style={{ fontSize: "20px", fontWeight: "400", padding: "10px" }}>
                      Currently in Beta: Connect your wallet to access real-time signals from top KOLs, track trending tokens, and explore actionable insights tailored to your trading needs. Your feedback helps us improve!
                    </div>
                  </div>
                  <div style={{ color: "white", }} className="mt-2">
                    <span style={{ fontSize: "30px", fontWeight: "500" }} className="font-color">Key Benefits</span><br />
                    <div className="center-class mt-3">
                      <img src={bulleteIcon} width={30} height={30} />
                      <div style={{ marginLeft: "10px" }}>
                        <span className="login-text-bullet main-point">Real-Time Insights:</span>&nbsp;
                        <span className="login-text-bullet main-answer">Track token signals shared across multiple chains in real-time.</span>
                      </div>
                    </div>
                    <div className="center-class mt-2">
                      <img src={bulleteIcon} width={30} height={30} />
                      <div style={{ marginLeft: "10px" }}>
                        <span className="login-text-bullet main-point">Trending Tokens:</span>&nbsp;
                        <span className="login-text-bullet main-answer">Explore top-performing tokens with detailed charts and analytics.</span>
                      </div>
                    </div>
                    <div className="center-class mt-2">
                      <img src={bulleteIcon} width={30} height={30} />
                      <div style={{ marginLeft: "10px" }}>
                        <span className="login-text-bullet main-point">Secure & Private:</span>&nbsp;
                        <span className="login-text-bullet main-answer">Your wallet stays secure while unlocking powerful features.</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-div-login mt-3" style={{ border: "none" }}>
                    <div style={{ fontSize: "20px", fontWeight: "400", padding: "10px" }}>
                      KolsAI is in Beta. Some features are under development and may evolve based on community feedback.
                    </div>
                  </div>
                  <div className="mt-3 center-class" style={{ display: "flex", justifyContent: "center", width: "100%", maxWidth: "800px" }}>
                    {/* <LoginWithWallet origin={"landing_page"} /> */}
                    <button type="button" className="btn btn-primary login-connect-btn" onClick={signInWithEmail}>
                      <span
                        style={{ fontSize: "22px", fontWeight: 600, color: "black" }}
                      >
                        Login
                      </span>
                    </button>
                    {/* <span style={{ color: "white", marginLeft: "10px" }}>Or Create an Account with:</span>
                    <img src={loginX} width={50} height={50} style={{ marginLeft: "10px" }} />
                    <img src={loginTelegram} width={50} height={50} style={{ marginLeft: "10px" }} />
                    <img src={loginMail} width={50} height={50} style={{ marginLeft: "10px" }} /> */}
                    <span style={{ color: "white", marginLeft: "10px" }}>Or Sign Up Here</span>
                    {/* <img className="c-point" src={loginMail} width={50} height={50} style={{ marginLeft: "10px" }} onClick={signInWithEmail} /> */}
                    <button type="button" className="btn btn-primary sign-up-connect-btn" onClick={() => navigate("/sign-up")} style={{ marginLeft: "10px" }}>
                      <span
                        style={{ fontSize: "22px", fontWeight: 600, color: "black" }}
                      >
                        Sign Up
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div>
                  <img src={RobotImg} className="robot-img-login" />
                </div>
              </div>
            </Row>
            <Row className="mt-4">
              <div className="text-center">
                <span style={{ color: "white", fontSize: "50px", fontWeight: "500" }}>How to Get Started</span>
              </div>
              <div className="text-center">
                <span style={{ color: "white", fontSize: "28px", fontWeight: "500" }}>Sign up with your preferred method and unlock access to trending tokens, real-time signals, and personalized analytics.</span>
              </div>
            </Row>
            <Row className="mt-4">
              <div style={{ padding: "20px" }}>
                <MainDashboardInner />
              </div>
            </Row>
            <Modal centered show={show} onHide={handleClose}>
              <Modal.Body style={{ background: "#272727", borderRadius: "5px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "28px",
                    fontWeight: 600,
                    fontFamily: "Plus Jakarta Sans",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  Level Up Your Access!
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    padding: "8px",
                    textAlign: "center",
                    fontFamily: "Plus Jakarta Sans",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  This Cognofi.app requires holding at least 10,000 $CGF (CognoFi
                  Token). Visit the "Buy $CGF" section to increase your holdings and
                  unlock full access
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="secondary"
                    style={{ width: "300px" }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
