import React, { useState } from 'react';
import "./new_pair_style.css";
import DexGN from "../../assets/images/dexgn.png";
import { render } from 'react-dom';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Chart from 'react-apexcharts';

export const GraphView = (props) => {

  let data = props?.chartData;
  let token = props?.tokenData

  const series = [
    {
      name: 'Stock Price',
      data: [
        { x: new Date('2024-11-01').getTime(), y: [110, 115, 108, 113] },
        { x: new Date('2024-11-02').getTime(), y: [113, 118, 110, 115] },
        { x: new Date('2024-11-03').getTime(), y: [115, 120, 113, 118] },
        { x: new Date('2024-11-04').getTime(), y: [118, 122, 116, 119] },
        { x: new Date('2024-11-05').getTime(), y: [119, 124, 117, 121] },
        { x: new Date('2024-11-06').getTime(), y: [121, 125, 119, 123] },
      ]
    }
  ];
  
  /*data = data?.map(item => {
    const [timestamp, ...rest] = item;
    return [timestamp * 1000, ...rest]; // Convert to milliseconds
  }); */

  /* const options = {
    rangeSelector: {
      selected: 1,
    },
    title: {
      text: 'AAPL Stock Price',
    },
    series: [
      {
        type: 'candlestick',
        name: 'AAPL Stock Price',
        data: data,
        dataGrouping: {
          units: [
            ['minute', [1, 5, 15]], // Group by 1, 5, or 15 minutes when zoomed in
            ['hour', [1]],          // Group by hours when zoomed out
            ['day', [1]],           // Group by days for larger intervals
          ],
          approximation: 'ohlc', // Ensure correct data approximation for candlesticks
        },
      },
    ],
  }; */

  const outputData = data.map(item => {
    return {
      x: item[0] * 1000,  // Convert to milliseconds
      y: item.slice(1, 5) // Get the first four values after the timestamp
    };
  });

  const options = {
    chart: {
      type: 'candlestick',
      height: 350,
    },
    title: {
      text: token.name,
      align: 'center'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    },
    tooltip: {
      shared: true,
      intersect: false
    }
  };

  return (
    <div style={{ backgroundColor: '#FFF' }}>
      {/* <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      /> */}
      <Chart options={options} /* series={outputData} */
      series={series} type="candlestick" height={350} />
    </div>
  );
};
