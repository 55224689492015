import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { _post } from "../../api/ApiClient";
import { Select } from "antd";
import { StyledInfluencerHeading } from "./styles";
import { useAddInfluencerMutation } from "../../services/influencerServices";
import { toast } from "react-toastify";
import { AddInfluencerModal } from "./components/AddInfluencerModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const TelegramButton = styled.button`
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #272727;
  color: #ffffff;
  border: solid 1px #272727;
  font-weight: 600;
`;

export const InfluencerHeading = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const userData = useSelector((state) => state.ROOT);
  const [formData, setFormData] = useState({
    profileImage: "",
    influencerName: "",
    twitterId: "",
    telegramChannel: "",
  });
  const [errors, setErrors] = useState({});
  const [addInfluencer, { isLoading: influencerLoader }] =
    useAddInfluencerMutation();
  const handleClose = () => {
    setShow(false);
    // Reset form fields and errors when modal is closed
    setFormData({
      profileImage: "",
      influencerName: "",
      twitterId: "",
      telegramChannel: "",
    });
    setErrors({});
  };

  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Check for empty field
    handleAddInfluencer();
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    // If there are errors, stop form submission
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    // You can access form values here: formData.profileImage, formData.influencerName, formData.twitterId, formData.telegramChannel
  };
  const options = [
    {
      value: "1",
      label: (
        <div>
          {" "}
          Influencer <span style={{ color: "#44D9F0" }}>Management</span>
        </div>
      ),
    },
    {
      value: "2",
      label: (
        <div>
          {" "}
          Approval <span style={{ color: "#44D9F0" }}>Request Management</span>
        </div>
      ),
    },
    {
      value: "3",
      label: (
        <div>
          {" "}
          Claim <span style={{ color: "#44D9F0" }}>Request Management</span>
        </div>
      ),
    },
  ];

  const { Option } = Select;

  const handleAddInfluencer = useCallback(async () => {
    try {
      const {
        profileImage,
        influencerName,
        telegramChannel,
        twitterId,
        contact,
      } = formData;
      const formBody = {
        profile: formData.profileImage,
        influncerName: formData.influencerName,
        telegramUserName: formData.telegramChannel,
        twitterId: formData.twitterId,
      };
      const influencerData = new FormData();
      influencerData.append("profile", profileImage);
      influencerData.append("influncerName", influencerName);
      influencerData.append("telegramUserName", telegramChannel);
      influencerData.append("twitterId", twitterId);
      influencerData.append("contact", contact);
      const payload = await addInfluencer({ body: influencerData });
      if (payload.data) {
        toast.success("Influencer added successfully !");
        handleClose();
      } else {
        toast.error(payload?.error?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [addInfluencer, formData]);

  const followAllInfluencer = async () => {
    const userConfirmed = window.confirm('Are you sure you want to follow all influencer?');
    if (!userConfirmed) {
      return;
    }
    let payload = {
      follow_all: 'all',
      status: 'follow'
    }
    try {
      //const res = await _post("/v1/user/addChannel", payload, {
      const res = await _post("/v1/user/follow-unfollow", payload, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const data = res.data
      if (data.status) {
        toast.success(data.message);
        window.location.reload()
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <StyledInfluencerHeading>
      <div className="row">
        <div
          style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "32.38px",
            color: " rgba(255, 255, 255, 1)",
            width: "50%",
          }}
        >
          {/* <Select
            defaultValue="1"
            optionLabelProp="label"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {options.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                label={option.label}
              >
                {option.label}
              </Option>
            ))}
          </Select> */}
          {/* {location.pathname === "/influence-mangement" ? 'Telegram ' : 'Twitter '}<span style={{ color: "#44D9F0" }}>Influencer</span> */}
          <span style={{ color: "#44D9F0" }}>Influencers</span>
        </div>
      </div>
      <div className="row">
        <div style={{ textAlign: "end" }}>
          {userData?.role === "Admin" && (
            <div>
              <Button variant="primary" onClick={() => { followAllInfluencer() }}>
                Follow All Influencer
              </Button>
              <Button variant="primary" onClick={handleShow} style={{ marginLeft: "5px" }}>
                Add Influencer
              </Button>
            </div>
          )}

          <AddInfluencerModal
            show={show}
            handleSubmit={handleSubmit}
            setShow={setShow}
            setAddInfluencerData={setFormData}
            influencerLoader={influencerLoader}
          />
        </div>
      </div>
    </StyledInfluencerHeading>
  );
};

export default InfluencerHeading;
