import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { TelegramCardStyle } from "../TelegramCards.style";
import { timeAgo } from "../../commonFunction/commonFunction"
import defaultProfile from "../../../assets/images/default-profile.jpg"
//import frame from "../../../assets/images/Frame.svg"
//import eye from "../../../assets/images/eye.svg"

function formatString(str) {
  if (str) {
    return str.substring(0, 4) + "..." + str.substring(str.length - 4);
  }
}

const TelegramChatScreen = ({ data, tokenLoader, cardType }) => {

  const getProfileImage = (item) => {
    let profileImage = defaultProfile;
    if (item?.platform && item?.platform != 'telegram') {
      profileImage = item?.influencerDetails?.profile
    }
    return profileImage;
  }

  return (
    <div className="border-style-background">
      <TelegramCardStyle
        style={{ width: "100%", borderRadius: "15px" }}
      >
        {!tokenLoader ? (
          <div style={{ borderRadius: "15px", width: "100%", background: "#171721" }}>
            {data.length > 0 ? (
              <div className="trending-contract-table-scroll livefeed" style={{ borderRadius: "15px" }}>
                <Table responsive striped className="text-start main-dashboard-table dashboard-table-common">
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <td width="15%"><img
                          src={getProfileImage(item)}
                          alt="" width={40} height={40} /></td>
                        <td width="60%">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={item.msg_url}
                            target="_blank"
                          >
                            <div
                              style={{
                                width: "90%",
                                fontSize: "14px",
                                lineHeight: "20.16px",
                                fontWeight: 600,
                                color: "rgba(233, 233, 233, 1)",
                              }}
                            >
                              {" "}
                              {item?.influencerDetails?.influncerName}
                              {item.is_utility && (
                                <span className="utility-text" style={{ marginLeft: '10px' }}>Utility</span>
                              )}
                            </div>
                          </Link>
                          {item.type === "Channel"
                            ? " Channel - "
                            : item.type === "Group"
                              ? " Grpoup - "
                              : item?.platform + " - "}
                          {formatString(item?.contract_link)}
                          {/* <div style={{ display: 'flex' }}>
                            <div>
                              <img src={frame} height={15} width={15} />
                              <span style={{ marginLeft: '5px', fontSize: '12px' }}>1.5K Followers</span>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                              <img src={eye} height={15} width={15} />
                              <span style={{ marginLeft: '5px', fontSize: '12px' }}>25K Followers</span>
                            </div>
                          </div> */}
                        </td>
                        <td width="25%" className="text-end">
                          <div style={{ display: 'grid' }}>
                            <span>{timeAgo(item?.createdAt)}</span>
                            {/* <span className="font-color" style={{ marginTop: '22px', textDecoration: 'underline', fontSize: '14px' }}>View Message</span> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "calc(100vh - 230px)"
                }}
              >
                <Link to={"/influence-mangement"}>
                  Follow more Influence to get latest trending Info
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              top: 200,
            }}
          >
            <Spinner
              animation="border"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        )}
      </TelegramCardStyle>
    </div>
  );
};

export default TelegramChatScreen;