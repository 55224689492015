import React from "react";

export const PaymentFail = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          padding: "20px",
          background: "url('/bg1.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          paddingTop: "10px"
        }}
      >
        Payment Failed
      </div>

    </div>
  );
};
