import { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { _get } from "../../../api/ApiClient";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import geekSwap from "../../../assets/images/MaestroPro.jpg"
import maestroPro from "../../../assets/images/MaestroPro.jpg";
import maestroSniper from "../../../assets/images/MastroSniper.jpg";
import safeLogo from "../../../assets/images/safeLogo.png";
export const SafeAnalyzerrModal = ({
    show,
    setShow,
    chianForGeekSwap,
    contractAddressForSafeAnalyzer
}) => {
    const userData = useSelector((state) => state.ROOT);
    const [safeDataAnalyzorResponse, setSafeDataAnalyzorResponse] = useState({})
    const [spinner, setSpinner] = useState(true)
    const [failMessage, setFailMessage] = useState('')
    const [resStatus, setResStatus] = useState(false)
    const handleClose = () => {
        setShow(false);
    };

    const getSafeAnalyzerData = async () => {
        try {
            if (contractAddressForSafeAnalyzer == '') {
                return
            }
            setSpinner(true)
            setSafeDataAnalyzorResponse({})
            const res = await _get("/v1/user/get-safeanalyzer-report/" + contractAddressForSafeAnalyzer, {
                headers: { Authorization: `Bearer ${userData.jwtToken}` },
            });
            if (res?.data?.status) {
                setResStatus(true)
                setSafeDataAnalyzorResponse(res?.data?.data)
                setSpinner(false)
            } else if (!res?.data?.status) {
                setResStatus(false)
                setSpinner(false)
                setFailMessage(res?.data?.message)
            }

        } catch (err) {
            console.log("error", err);
        }
    }

    useEffect(() => {
        getSafeAnalyzerData()
    }, [show])

    const HtmlStringParser = (string) => {
        const htmlString = string;

        return (
            <span
                dangerouslySetInnerHTML={{ __html: htmlString }}
            />
        );
    };

    const [hover, setHover] = useState(false);
    const linkStyle = {
        color: hover ? 'white' : 'white',
        textDecoration: 'none',
        marginLeft: '5px',
        background: '#212529',
        padding: '8px',
        borderRadius: '8px',
        cursor: 'pointer'
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            //size="lg"
            //style={{ maxWidth: "600px", margin: 'auto' }}
            // style={{ background: "rgb(118, 118, 118)" }}
            dialogClassName="modal-custom-width"
        >
            <Modal.Header
                closeButton
                closeVariant="white"
                style={{ background: "rgb(18 19 20)", borderBottom: "none" }}
            >
                <Modal.Title style={{ color: "#ffffff" }}>
                    <img src={safeLogo} width={30} height={30} alt="" />
                    <span style={{marginLeft: '10px'}}>
                        Safe Analyzer Bot
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ background: "rgb(18 19 20)" }}>
                {spinner ?

                    (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "white"
                            }}
                        >
                            <Spinner className="text-center"
                                animation="border"
                                style={{ height: "50px", width: "50px" }}
                            /></div>)

                    :
                    resStatus ? <>
                        {/* <Table striped bordered hover responsive>
                            <tbody>
                                <tr>
                                    <td style={{ width: "30%" }}>Chain</td>
                                    <td>{safeDataAnalyzorResponse.chain}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{safeDataAnalyzorResponse.address}</td>
                                </tr>
                                <tr>
                                    <td>👨‍💻Owner</td>
                                    <td>{safeDataAnalyzorResponse.owner}</td>
                                </tr>
                                <tr>
                                    <td>💧LP Ratio</td>
                                    <td>{safeDataAnalyzorResponse.lp_ratio}</td>
                                </tr>
                                <tr>
                                    <td>First LP</td>
                                    <td>{safeDataAnalyzorResponse.first_lp}</td>
                                </tr>
                                <tr>
                                    <td>👥Holders</td>
                                    <td>{safeDataAnalyzorResponse.holders}</td>
                                </tr>
                                <tr>
                                    <td>💲Mcap</td>
                                    <td>{safeDataAnalyzorResponse.mcap}</td>
                                </tr>
                                <tr>
                                    <td>💰Liquid</td>
                                    <td>{safeDataAnalyzorResponse.liquid}</td>
                                </tr>
                                <tr>
                                    <td>Inital</td>
                                    <td>{safeDataAnalyzorResponse.initial}</td>
                                </tr>
                                <tr>
                                    <td>ATH</td>
                                    <td>{safeDataAnalyzorResponse.ath}</td>
                                </tr>
                                <tr>
                                    <td>⚖️Vol24h</td>
                                    <td>{safeDataAnalyzorResponse.vol24h}</td>
                                </tr>
                                <tr>
                                    <td>LP Lock</td>
                                    <td>{safeDataAnalyzorResponse.lp_lock}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>{HtmlStringParser(safeDataAnalyzorResponse.tax)}</td>
                                </tr>
                                <tr>
                                    <td>Age</td>
                                    <td>{safeDataAnalyzorResponse.age}</td>
                                </tr>
                            </tbody>
                        </Table> */}
                        <div style={{ color: 'white' }}>
                            <span>Chain:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.chain}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>Address:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.address}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>👨‍💻Owner:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.owner}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>💧LP Ratio:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.lp_ratio}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>First LP:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.first_lp}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>👥Holders:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.holders}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>💲Mcap:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.mcap}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>💰Liquid:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.liquid}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>Inital:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.initial}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>ATH:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.ath}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>⚖️Vol24h:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.vol24h}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>LP Lock:</span>
                            <span style={{ marginLeft: '5px' }}>{HtmlStringParser(safeDataAnalyzorResponse.lp_lock)}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>Tax:</span>
                            <span style={{ marginLeft: '5px' }}>{HtmlStringParser(safeDataAnalyzorResponse.tax)}</span>
                        </div>
                        <div style={{ color: 'white' }}>
                            <span>Age:</span>
                            <span style={{ marginLeft: '5px' }}>{safeDataAnalyzorResponse.age}</span>
                        </div>

                    </>
                        : <><span style={{ color: 'white' }}>{failMessage}</span></>}


            </Modal.Body>
            <Modal.Footer style={{ background: "rgb(18 19 20)", borderTop: "none" }}>

                <a target="_balnk" href={chianForGeekSwap + contractAddressForSafeAnalyzer}
                    style={linkStyle}
                >
                    <img
                        src={geekSwap}
                        alt=""
                        height={22}
                        width={22}
                    />
                    <span style={{ marginLeft: "5px" }}>
                        Geek Swap
                    </span>

                </a>
                <a target="_balnk" href={'https://t.me/MaestroProBot?start=' + contractAddressForSafeAnalyzer + '-cogerstro'}
                    style={linkStyle}
                >
                    <img
                        src={maestroPro}
                        alt=""
                        height={22}
                        width={22}
                    />
                    <span style={{ marginLeft: "5px" }}>
                        Maestro Pro
                    </span>

                </a>
                <a target="_balnk" href={'https://t.me/MaestroSniperBot?start=' + contractAddressForSafeAnalyzer + '-cogerstro'}
                    style={linkStyle}
                >
                    <img
                        src={maestroSniper}
                        alt=""
                        height={22}
                        width={22}
                    />
                    <span style={{ marginLeft: "5px" }}>
                        Maestro Sniper
                    </span>

                </a>
                <Button variant="dark" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
