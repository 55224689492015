import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import userReducer, { resetAll } from "./user/UserSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { Apis } from "../services/api";
import { setupListeners } from "@reduxjs/toolkit/query";

const rootReducer = combineReducers({
  ROOT: userReducer,
  chat: {},
  [Apis.reducerPath]: Apis.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rtkQueryErrorLogger = (storeAPI) => (next) => async (action) => {
  try {
    if (isRejectedWithValue(action)) {
      if (action.payload?.data?.msg === "Missing Authorization Header") {
        localStorage.removeItem("token");
        storeAPI.dispatch(resetAll());
      }
    }
  } catch (err) {
    /* empty */
  }

  return next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    //getDefaultMiddleware().concat(Apis.middleware).concat(rtkQueryErrorLogger),
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions to prevent warnings
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
          "persist/FLUSH",
        ],
        ignoredPaths: ["register", "rehydrate"], // Ignore paths that contain non-serializable values
      },
    }).concat(Apis.middleware).concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
