import React from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DonutChart = ({ data, title = "", name = "" }) => {
  let toolTipFormat = ""
  let showLegend = false
  if (name == "Sentiment") {
    toolTipFormat = '{series.name}: <b>{point.percentage:.1f}%</b>'
  } else {
    toolTipFormat = '{series.name}: <b>{point.y}</b>'
    showLegend = true
  }
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent', // Dark background color
      height: '200px',
    },
    title: {
      text: title,
      style: {
        color: 'white' // Sets the title color to white
      },
    },
    tooltip: {
      /* pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>', */
      pointFormat: toolTipFormat,
    },
    plotOptions: {
      pie: {
        innerSize: '60%', // Creates the donut effect
        depth: 45,
        dataLabels: {
          enabled: showLegend, // Hides default data labels
        },
      },
    },
    series: [
      {
        name: name,
        colorByPoint: true,
        /* data: [
          { name: 'Positive', y: 36, color: '#007bff' }, // Blue color for Positive
          { name: 'Neutral', y: 48, color: '#00e5ff' },  // Light blue for Neutral
          { name: 'Negative', y: 16, color: '#ffffff' }, // White color for Negative
        ], */
        data: data
      },
    ],
    credits: {
      enabled: false
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DonutChart;
