import React from 'react'
import Table from "react-bootstrap/Table";
import ExportLogo from "../../assets/images/export-arrow.png";

export const TopTraders = () => {
  return (
    <div>
      <div className="mt-3">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>TYPE</th>
            <th>USD</th>
            <th>$BOON</th>
            <th>WETH</th>
            <th>PRICE </th>
            <th>MARKERS</th>
            <th>TXN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>42069/SOl 42069</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
            <td>#7 $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
          <td>#1 $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td> 
          </tr>
          <tr>
          <td>#2 $KEHA/USDC  KID PEPE WIF</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
            <td>#8 42069/SOl 42069</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
          <tr>
            <td>42069/SOl 42069</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>  
          </tr>
          <tr>
            <td>#7 $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td> 
          </tr><tr>
            <td>#7 $BOON/WETH Base Baboo</td>
            <td>$0.08707</td>
            <td>4h34m</td>
            <td>16,017</td>
            <td>11,003</td>
            <td>$10.8M</td>
            <td>6,104</td>
            <td className='text-center'><img src={ExportLogo} alt="" /></td>
          </tr>
        </tbody>
      </Table>
    </div>
    </div>
  )
}
