import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { arbitrum, mainnet, polygon, goerli } from "wagmi/chains";
import { Web3Modal } from "@web3modal/react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

import ProtectedRoute from "./routes/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { CONFIG } from "./config";

import MainSection from "./components/mainSection/MainSection";
import LoginPage from "./components/LoginPage/LoginPage";
import Login from "./components/LoginPage/Login";
import SignUp from "./components/LoginPage/SignUp";
import Filters from "./components/filters/Filters";
import { InfluencerMangement } from "./components/InfluencerManagement/InfluencerMangement";
import InfluencerDetails from "./components/InfluencerManagement/InfluencerDetails";

import Setting from "./pages/setting/Setting";
import AddPages from "./pages/AddPages/AddPages";
import Notifications from "./pages/notifications/Notifications";
import PrelaunchCalls from "./pages/PrelaunchCalls/PrelaunchCalls";
import TokenTrending from "./pages/TokenTrending/TokenTrending";
import UtilityTrending from "./pages/UtilityTrending/UtilityTrending";
import DexScreen from "./pages/DexScreen/DexScreen";
import DexScreenDeatils from "./pages/DexScreen/DexScreenerDeatils";
import { Subscriptionplan } from "./pages/SubscriptionPlan/Subscriptionplan";
import { PaymentFail } from "./pages/SubscriptionPlan/PaymentFail";
import MainDashboard from "./pages/MainDashboard/MainDashboard";
import CryptonautAI from "./pages/CryptonautAI/KolsAIChatbot";
import KolsBoard from "./pages/KolsBoard/KolsBoard";
import SmartTraders from "./pages/SmartTraders/SmartTraders";
import ReferralProgram from "./pages/ReferralProgram/ReferralProgram";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import EditProfile from "./pages/EditProfile/EditProfile";
import ChainDataAnalysis from "./pages/ChainDataAnalysis/ChainDataAnalysis";

import { initializeSocket, getSocket } from "./socket";
import RedeemPoint from "./pages/RedeemPoint/RedeemPoint";
import RedeemPointHistory from "./pages/RedeemPointHistory/RedeemPointHistory";

const App = () => {
  const userData = useSelector((state) => state.ROOT);
  const chains = [arbitrum, mainnet, polygon, goerli];
  const projectId = CONFIG.PROJECT_ID;
  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  if (userData && userData?.jwtToken) {
    initializeSocket(userData.jwtToken);

    const socket = getSocket();

    socket.on("connect", () => {
      console.log("Socket connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected from server");
    });
  } else {
    initializeSocket();
  }


  if (window.location.pathname.includes("/referral/")) {
    const url = window.location.href;
    const code = url.split("/").pop();
    localStorage.setItem("referral_code", code);
    if (userData?.jwtToken) {
      window.location.href = "/dashboard";
    } else {
      window.location.href = "/";
    }
  }


  /* useEffect(() => {
    if (userData && userData.jwtToken) {
      initializeSocket(userData.jwtToken);
 
      const socket = getSocket();
 
      socket.on("connect", () => {
        console.log("Socket connected to server");
      });
 
      socket.on("disconnect", () => {
        console.log("Socket disconnected from server");
      });
 
      return () => {
        socket.off("connect");
        socket.off("disconnect");
      };
    } else {
      initializeSocket();
    }
  }, [userData]); */

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);
  return (
    <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        {/* <Provider store={store}> */}
        <Routes>
          <Route path="/new-pair" element={<ProtectedRoute children={<DexScreen />} />} />
          <Route path="/live-trending" element={<ProtectedRoute children={<DexScreen />} />} />
          <Route path="/new-pair-deatils/:token_address?" element={<ProtectedRoute children={<DexScreenDeatils />} />} />
          <Route path="/subscription-plan" element={<ProtectedRoute children={<Subscriptionplan />} />} />
          <Route
            path="/payment-fail"
            element={<ProtectedRoute children={<PaymentFail />} />}
          />
          <Route
            path="/influence-mangement"
            element={<ProtectedRoute children={<InfluencerMangement />} />}
          />
          <Route
            path="/influence-twitter"
            element={<ProtectedRoute children={<InfluencerMangement />} />}
          />
          <Route
            path="/influencer-details/:influencer_id?"
            element={<ProtectedRoute children={<InfluencerDetails />} />}
          />
          <Route
            path="/trending-contract/:address?"
            element={<ProtectedRoute children={<MainSection />} />}
          />
          {/* <Route
              path="/dashboard"
              element={<ProtectedRoute children={<Dashboard />} />}
            /> */}
          <Route
            path="/filter"
            element={<ProtectedRoute children={<Filters />} />}
          />
          <Route
            path="/notifications"
            element={<ProtectedRoute children={<Notifications />} />}
          />
          <Route
            path="/setting"
            element={<ProtectedRoute children={<Setting />} />}
          />
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/add-pages"
            element={<ProtectedRoute children={<AddPages />} />}
          />
          <Route
            path="/prelaunch-calls"
            element={<ProtectedRoute children={<PrelaunchCalls />} />}
          />
          {/* <Route
            path="/utility-trending"
            element={<ProtectedRoute children={<UtilityTrending />} />}
          /> */}
          <Route
            path="/token-trending"
            element={<ProtectedRoute children={<TokenTrending />} />}
          />
          <Route
            path="/kols-board"
            element={<ProtectedRoute children={<KolsBoard />} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute children={<MainDashboard />} />}
          />
          <Route
            path="/kols-ai-chatbot/:chat_id?"
            element={<ProtectedRoute children={<CryptonautAI />} />}
          />
          <Route
            path="/smart-traders"
            element={<ProtectedRoute children={<SmartTraders />} />}
          />
          <Route
            path="/referral-program"
            element={<ProtectedRoute children={<ReferralProgram />} />}
          />
          <Route
            path="/redeem-point"
            element={<ProtectedRoute children={<RedeemPoint />} />}
          />
          <Route
            path="/redeem-point-history"
            element={<ProtectedRoute children={<RedeemPointHistory />} />}
          />
          <Route
            path="/verify-email/:token?"
            element={<VerifyEmail />}
          />
          <Route
            path="/reset-password/:token?"
            element={<ResetPassword />}
          />
          <Route
            path="/edit-profile"
            element={<ProtectedRoute children={<EditProfile />} />}
          />
          <Route
            path="/chart-data-analysis"
            element={<ProtectedRoute children={<ChainDataAnalysis />} />}
          />
        </Routes>
        {/* </Provider> */}
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
      />
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </WagmiConfig>
  );
};

export default App;
