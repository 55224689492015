import React from "react";
import DashBoardHeader from "../../components/dashBoardHeader/DashBoardHeader";
import Common from "../../components/commonContent/Common";
import DropdownBtn from "../../components/Buttons/Dropdown";
import TelegramListCard from "../../components/Cards/TelegramCards";
import { TelegramCardStyle } from "../../components/Cards/TelegramCards.style";
import { Button } from "react-bootstrap";
import ApplyBtn from "../../components/Buttons/ApplyBtn/Applybtn";
import { TokenTable } from "../../components/DexScreener/TokenTable";
import { HeadTabs } from "../../components/DexScreener/HeadTabs";
import { InfluencerHeading } from "./InfluencerHeading";
import { InfluencerTable } from "./InfluencerTable";
import Layout from "../layout/Layout";
const optionList = [
  {
    value: "Telegram",
    label: "Telegram",
  },
  { value: "Twitter", label: "Twitter" },
];

export const InfluencerMangement = () => {
  return (
    <>
      {/* <DashBoardHeader /> */}
      {/* <Common>
        <div
          style={{
            width: "100%",
            height: "auto",
            padding: "110px 15px 15px 15px",
            background: "#27292A",
            paddingTop: "110px"
          }}
        >
          <InfluencerHeading />
          <InfluencerTable />
        </div>
      </Common> */}
      <Layout>
        <InfluencerHeading />
        <InfluencerTable />
      </Layout>
    </>
  );
};
