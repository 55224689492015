import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, Spinner, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { TLoginButton, TLoginButtonSize } from "react-telegram-auth";

import LoginWithWallete from "./LoginWithWallet.jsx"

import {
  walletAddress,
  userId,
  profileUrl,
  role,
  jwtToken,
  firstName,
  lastName,
  twitterAc,
  telegramAc,
  subscriptionDetails,
  balance,
  userInfo
} from "../../redux/user/UserSlice";
import { _post } from "../../api/ApiClient";
import logoimg from "../../assets/images/KolsAILogo.png";
import loginX from "../../assets/images/login-x.svg";
import loginTelegram from "../../assets/images/login-telegram.svg";
import bulleteIcon from "../../assets/images/bullet-icon.svg";
//import loginMail from "../../assets/images/login-mail.svg";
import loginYoutube from "../../assets/images/youtubeLogin.svg";
import loginMedium from "../../assets/images/mediumLogin.svg";
import errowImg from "../../assets/images/errow.svg";
import { CONFIG } from "../../config/index.js";

const Login = () => {
  const userData = useSelector((state) => state.ROOT);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = userData?.jwtToken
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginBlueBackground, setLoginBlueBackground] = useState(false);
  const [showModalForSignature, setShowModalForSignature] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard")
    } else {
      setIsCheckingAuth(false);
    }
  }, [isLoggedIn, navigate])
  if (isCheckingAuth) {
    return null;
  }
  const handleLogin = async (user = null) => {
    setError("");
    setIsLoading(true);

    let postdata = {}
    if (user) {
      postdata = user
    } else if (!username || !password) {
      setError("Both fields are required.");
      setIsLoading(false);
      return;
    } else {
      postdata = {
        email: username,
        password,
      }
    }

    try {
      const res = await axios.post(CONFIG.BASE_URL + "/v1/login", postdata);

      if (res.data.success) {
        sessionStorage.setItem("profileModal", true);
        localStorage.setItem("sessionValidTill", Date.now() + (24 * 60 * 60 * 1000));
        dispatch(jwtToken(res.data.jwt_token));
        localStorage.setItem("token", res.data.jwt_token);
        dispatch(walletAddress(res.data.user.wallet_address));
        dispatch(profileUrl(res.data.user.profile_image_url));
        dispatch(role(res.data.user.role));
        dispatch(firstName(res.data.user.first_name));
        dispatch(lastName(res.data.user.last_name));
        dispatch(subscriptionDetails(res.data.user.subscriptionDetails));
        dispatch(twitterAc(res.data.user.twitter_ac));
        dispatch(telegramAc(res.data.user.telegram_ac));
        dispatch(balance(res?.data?.user?.balance));
        dispatch(userInfo(res?.data?.user));
        toast.success(res?.data?.message)
        navigate("/dashboard");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (!user) {
          setError("Invalid credentials.");
        }
        toast.error(res?.data?.message)
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  const goingSignUpPage = () => {
    navigate("/sign-up");
  }
  const handleClose = () => {
    setShow(false);
  };

  const handleUpdate = async () => {

    try {
      const res = await _post("/v1/forgot-password", {
        email,
      });

      if (res?.data?.status) {
        toast.success(res?.data?.message)
        setShow(false);
      } else {
        toast.error(res?.data?.message)
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }
  return (
    <>
      <div className={loginBlueBackground ? "blur-background" : ""}>
        <div
          style={{
            width: "100%",
            background: '#171721',
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div
            style={{
              background: "url('/curve.svg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <div className="container-fluid">
              <Row>
                <div className="row login-page-headre-logo">
                  <div className="col-xl-10 col-lg-10 col-md-8 col-sm-6 col-12" style={{ paddingLeft: "50px" }}>
                    <img src={logoimg} alt="headerLogo" height={75} width={220} style={{ marginTop: "10px", cursor: "pointer" }} onClick={() => navigate("/")} />
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <a href="https://x.com/kolsai_io" target="_blank">
                      <img src={loginX} width={40} height={40} />
                    </a>
                    <a href="https://t.me/Kolsai_io" target="_blank">
                      <img src={loginTelegram} width={40} height={40} style={{ marginLeft: "10px" }} />
                    </a>
                    <a href="https://www.youtube.com/@KolsAI-io" target="_blank">
                      <img src={loginYoutube} width={40} height={40} style={{ marginLeft: "10px" }} />
                    </a>
                    <a href="https://medium.com/@support_89738" target="_blank">
                      <img src={loginMedium} width={40} height={40} style={{ marginLeft: "10px" }} />
                    </a>
                  </div>
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="text-start">
                    <div>
                      <span className="login-main-text">
                        Welcome Back to <span className="font-color">Kols</span>AI
                      </span><br />
                      <span style={{ color: "white", fontSize: "18px", fontWeight: "500" }}>
                        Your gateway to smarter decisions and powerful insights awaits.
                      </span>
                    </div>

                    <div style={{ color: "white", }} className="pt-4">
                      <div style={{ textAlign: "start", display: "inline-block" }}>
                        <div>
                          <span style={{ fontSize: "30px", fontWeight: "500" }} className="font-color">Why Login?</span><br />
                        </div>
                        <div className="center-class mt-3">
                          <img src={bulleteIcon} width={30} height={30} />
                          <div style={{ marginLeft: "10px" }}>
                            <span className="login-text-bullet main-answer">Access your personalized dashboard.</span>
                          </div>
                        </div>
                        <div className="center-class mt-2">
                          <img src={bulleteIcon} width={30} height={30} />
                          <div style={{ marginLeft: "10px" }}>
                            <span className="login-text-bullet main-answer">Stay updated with the latest trends and signals.</span>
                          </div>
                        </div>
                        <div className="center-class mt-2">
                          <img src={bulleteIcon} width={30} height={30} />
                          <div style={{ marginLeft: "10px" }}>
                            <span className="login-text-bullet main-answer">Continue exploring the tools that empower your crypto journey.</span>
                          </div>
                        </div>
                        <div className="center-class mt-2">
                          <img src={bulleteIcon} width={30} height={30} />
                          <div style={{ marginLeft: "10px" }}>
                            <span className="login-text-bullet main-answer"> Enter your details and get started now!</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5" style={{ fontWeight: "400", fontSize: "18px", color: "white" }}>
                      <span>If you don't have an Account?</span><br />
                      <div>
                        <Button className="sign-in-here" onClick={() => goingSignUpPage()}>Sign Up here</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 co-sm-12 col-12">
                  <div style={{ padding: "20px" }}>
                    <div style={{ background: "#272F3C7A", borderRadius: "50px" }}>
                      <div className="text-center login-div-bg">
                        <div style={{ padding: "30px" }}>
                          <div>
                            <span style={{ fontSize: "72px", fontWeight: "700", color: "#3DD1E5" }}>
                              Sign In
                            </span>
                          </div>
                          <div className="text-start">
                            <div>
                              <span className="login-username">
                                Username
                              </span><span className="red-star">*</span>
                            </div>
                            <div className="pw-input-border">
                              <input className="login-username-input" type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter username" />
                            </div>
                          </div>
                          <div className="text-start pt-4">
                            <div>
                              <span className="login-username">
                                Password
                              </span><span className="red-star">*</span>
                            </div>
                            <div className="pw-input-border center-class">
                              <input className="login-username-input"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password" />

                              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility}
                                className="c-point"
                                style={{ color: "white" }} />

                            </div>
                          </div>
                          <div className="text-end">
                            <span className="font-color" style={{ cursor: "pointer" }} onClick={() => setShow(true)}>Forget Password</span>
                          </div>
                          {error && <div className="text-center" style={{ color: "red", marginTop: "10px" }}>{error}</div>}
                          <div className="text-center pt-5">
                            <div className="sign-in-btn" onClick={() => handleLogin()}>
                              <div style={{ display: "flex", justifyContent: "space-around" }}>
                                {isLoading ? <Spinner animation="border" /> : <><span style={{ fontSize: "30px", fontWeight: "600", color: "white" }}>Sign In</span>
                                  <div className="center-class">
                                    <div className="errow-bg">
                                      <img src={errowImg} width={25} height={25} />
                                    </div>
                                  </div></>}
                              </div>
                            </div>
                          </div>
                          <div className="pt-5">
                            <div>
                              <span style={{ color: "grey" }}>-----------------------{" "}</span>
                              <span style={{ color: "white", fontSize: "18px", fontWeight: "400" }}>OR</span>
                              <span style={{ color: "grey" }}>{" "}-----------------------</span>
                            </div>
                          </div>
                          <div className="pt-3">
                            {/* <img className="c-point" src={loginX} width={50} height={50} style={{ marginLeft: "10px" }} />
                          <img className="c-point" src={loginTelegram} width={50} height={50} style={{ marginLeft: "10px" }} />
                          <img className="c-point" src={loginMail} width={50} height={50} style={{ marginLeft: "10px" }} /> */}
                            <LoginWithWallete origin={"login"} setLoginBlueBackground={setLoginBlueBackground}
                              setShowModalForSignature={setShowModalForSignature} />
                          </div>
                          <div className="pt-3">
                            <TLoginButton
                              botName="Kolsailoginbot"
                              buttonSize={TLoginButtonSize.Large}
                              lang="en"
                              usePic={true}
                              cornerRadius={20}
                              onAuthCallback={(user) => {
                                handleLogin(user)
                              }}
                              requestAccess={'write'}
                              additionalClasses={'css-class-for-wrapper'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="login-bg">
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          style={{ background: "rgb(9, 20, 32)", borderBottom: "none" }}
        >
          <Modal.Title style={{ color: "#ffffff" }}>
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "rgb(9, 20, 32)" }}>
          <Form onSubmit={handleUpdate}>
            <Form.Group className="mb-2" controlId="">
              <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
                Enter Your Email
              </Form.Label>
              <Form.Control
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ background: "rgb(9, 20, 32)", borderTop: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleUpdate}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalForSignature}
        backdrop="static"
        centered
        className="modal"
      >
        <Modal.Header
          closeVariant="white"
          style={{ background: "#262d37", borderBottom: "none", borderRadius: "20px 20px 0 0" }}
        >
          <Modal.Title style={{ color: "#3DD1E5" }}>
            Secure Sign-In Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#262d37", color: "white", fontWeight: "500" }}>
          Please confirm the Sign-In request in your wallet to securely access your KolsAI account. If you haven't received the request, please click the button below to try again.
        </Modal.Body>
        <Modal.Footer style={{ background: "#262d37", borderTop: "none", borderRadius: "0 0 20px 20px" }}>
          <LoginWithWallete origin={"secure_sign_modal"} setLoginBlueBackground={setLoginBlueBackground}
            setShowModalForSignature={setShowModalForSignature} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
