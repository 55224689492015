import React from "react";
import { SubscriptionHeading } from "./SubscriptionHeading";
import { SubscriptionTable } from "./SubscriptionTable";

export const SubscriptionDeatils = () => {
  return (
    <div>
      <SubscriptionHeading />
      <SubscriptionTable />
    </div>
  );
};
