import React, { useEffect, useState } from "react";
import { TokenCardGradientText, TokenCardStyle } from "./TokenCards.style";
import { Spinner } from "react-bootstrap";
import viewIcon from "../../../assets/images/viewAll.png";
import { _post } from "../../../api/ApiClient";
import "./style.css";
import { Link } from "react-router-dom";
import { timeAgo, formatStringtoLimit } from "../../commonFunction/commonFunction";
import Switch from "../../../components/Switch/Switch"
import Table from "react-bootstrap/Table";
import search from "../../../assets/images/search.svg";
const PrelaunchCard = ({
  socket,
  name,
  data,
  setChatPrelaunchData,
  setTokenIds,
  setClick,
  click,
  setTokenLoader,
  trendingCardLoader,
  selectList,
  setCardType,
  newTokenClick,
  trendingTokenClick,
  setPreTokenCLick,
  setNewTokenClick,
  setTrendingTokenCLick,
  selectedTime,
  isTokenUpdateLive,
  setIsTokenUpdateLive,
  selectedInfluencerListOption
}) => {
  const [showAll, setShowAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null); // State to keep track of clicked item index
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  const handleTokenClick = async (tokenId) => {
    setPreTokenCLick(true);
    setTrendingTokenCLick(false);
    setNewTokenClick(false);
    // Receive index as argument
    // setTokenIds(tokenId);
    // setClick(true);
    setCardType("prelaunch");

    const list = selectList?.selectedOptions?.map((item) => item.value);
    socket?.emit("tokenMessage", {
      data: {
        tokenId: tokenId,
        listType: "prelaunch",
        list: [...list || []],
        //time: Number(selectList.inputValue * 60),
        //time: selectList.inputValue,
        time: selectedTime,
        page: 'prelaunch',
        selected_influencer_type: selectedInfluencerListOption,
        is_token_update_live: isTokenUpdateLive
      },
    });
    socket?.on("newMessage", (data) => {
      setChatPrelaunchData(data?.data);
    });

    setClickedIndex(tokenId); // Set clicked item index
    setTokenLoader(false);
  };
  useEffect(() => {
    if (newTokenClick || trendingTokenClick) {
      setClickedIndex(null);
    }
  }, [newTokenClick, trendingTokenClick]);
  const allTokenNamesWithMsgCount = data.flatMap((item) => ({
    name: item?.tag,
    msgCount: item.msgCount,
    _id: item?._id,
    //createdAt: item.createdAt,
    createdAt: item.msg_time,
    is_utility: item?.is_utility,
  }));

  // Filter the token names based on the search term
  const filteredTokenNamesWithMsgCount = allTokenNamesWithMsgCount?.filter(
    (token) => token.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // const displayedData = showAll ? filteredData : filteredData.slice(0, 3);
  const displayedTokenNamesWithMsgCount = filteredTokenNamesWithMsgCount

  useEffect(() => {
    setShowAll(false);
  }, [trendingCardLoader]);

  const handleToggle = () => {
    setIsTokenUpdateLive(!isTokenUpdateLive)
  };
  return (
    <div className="border-style-background">
      <TokenCardStyle
        style={{
          height: "auto",
          padding: "10px 5px 5px",
          borderRadius: "15px"
        }}
      >
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(163,163,163,0.2)",
            paddingBottom: "10px",
          }}
        >
          <div style={{ fontSize: "18px" }}>{name}</div>
          <div>
            <span style={{ marginRight: '5px', position: 'relative', top: '5px' }}>Live</span>
            <Switch
              isOn={isTokenUpdateLive}
              handleToggle={handleToggle}
            />
          </div>
        </div> */}
        {trendingCardLoader ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Spinner
              animation="border"
              style={{ height: "25px", width: "25px" }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              overflowY: "auto",
            }}
          >
            <div style={{ padding: '7px', position: 'relative' }}>
              <input
                style={{
                  background: "rgba(21, 22, 24, 1)",
                  border: "0.5px solid #fff",
                  borderRadius: "5px",
                  outline: "none",
                  color: "#fff",
                  padding: "5px",
                  width: "100%"
                }}
                type="text"
                placeholder="Search Token..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img src={search} alt="search" style={{ position: 'absolute', right: '12px', top: '12px' }} />
            </div>
            {displayedTokenNamesWithMsgCount.length > 0 ? (
              <div className="trending-contract-table-scroll token">
                <Table responsive striped className="text-start main-dashboard-table dashboard-table-common">
                  <tbody>
                    {displayedTokenNamesWithMsgCount.map((token, index) => (
                      <tr key={index} className={clickedIndex === token._id ? "select-token-class" : ""}
                        onClick={() => handleTokenClick(token._id)}
                        style={{cursor: "pointer"}}
                      >
                        <td width="80%">
                          <div
                            key={index}
                            /* className="select-items" */
                            style={{
                              color: clickedIndex === token._id ? "black" : "#ffffff",
                              padding: "2px",
                              fontSize: "16px",
                              fontWeight: 400,
                              cursor: "pointer",
                              borderRadius: clickedIndex === token._id ? "5px" : "",
                              //background: clickedIndex === token._id ? "#545353" : "", // Change background color based on clicked item
                            }}
                          >
                            <span>
                              {`${formatStringtoLimit(token.name)}`}
                            </span>
                            {token.is_utility && (
                              <span className="utility-text">Utility</span>
                            )}</div>
                        </td>
                        <td width="20%">
                          <div style={{ float: "right" }}>
                            <span style={{ color: "#baffe9" }}>
                              {timeAgo(token.createdAt)}
                            </span>
                            <span className="common-text-color">{` (${token.msgCount})`}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div
                style={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Link to={"/influence-mangement"}>
                  Follow more Influence to get latest trending Info
                </Link>
              </div>
            )}
            {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={toggleShowAll}
          >
            {filteredTokenNamesWithMsgCount.length > 10 ? <>
              <TokenCardGradientText>
                {showAll ? "View Less ▲" : "View All ▼"}
              </TokenCardGradientText>
            </> : <></>}
          </div> */}
          </div>
        )}
      </TokenCardStyle>
    </div>
  );
};

export default PrelaunchCard;
