import React from "react";
import MainSection from "../../components/mainSection/MainSection";

const PrelaunchCalls = () => {
  return (
    <MainSection />
  );
};

export default PrelaunchCalls;
