import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Button, Row, Form, Modal } from "react-bootstrap";
import './styles.css'
//import ComingSoon from "../../components/ComingSoon/ComingSoon";
import historyImg from "../../assets/images/influenecricon.png"
import { _get, _post } from "../../api/ApiClient";
import axios from 'axios';
import { CONFIG } from "../../config/index.js";
import { firstName, userInfo, walleteSignature } from "../../redux/user/UserSlice";
//import Tabs from '../../components/Tabs/Tabs';
//import Tab from '../../components/Tabs/Tab';
import Layout from "../../components/layout/Layout";
import { handleCopy } from "../../components/commonFunction/commonFunction";
import LoginWithWallete from "../../../src/components/LoginPage/LoginWithWallet"
import copy from "../../assets/images/copy.svg";
import editIcon from "../../assets/images/edit-icon.svg";
import lockIcon from "../../assets/images/lock_icon.svg";
import errowImg from "../../assets/images/errow.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Setting = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.ROOT);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState({ index: 0, title: 'Tab 1' });

  const [current_password, setCurrentPassword] = useState("");
  const [email, setEmail] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showLogin, setShowLogin] = useState(false);

  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [first_name, setFirstName] = useState('')
  const [selectedImage, setSelectedImage] = useState(historyImg);
  const [imageFile, setImageFile] = useState(null)
  const fileInputRef = useRef(null);
  const [inputName, setInputName] = useState(true)
  const [imgUpdate, setImgUpdate] = useState(false)
  const [telegramKey, setTelegramKey] = useState("");
  const [inputEmail, setInputEmail] = useState(true)

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [referUrl, setReferUrl] = useState("")

  const togglePasswordVisibility = (type) => {
    if (type === "current") {
      setShowCurrentPassword((prevShowCurrentPassword) => !prevShowCurrentPassword);
    } else if (type === "new") {
      setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
    } else if (type === "confirm") {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };


  const inputStyle = {
    background: "transparent",
    color: "#fff",
    width: "130px",
    height: "40px",
    border: "1px solid gray",
    outline: "none",
    borderRadius: "8px",
    padding: "5px",
    marginTop: '5px'
  }

  // Callback function to handle tab change
  const handleTabChange = (index, title) => {
    setActiveTab({ index, title });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file)
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  useEffect(() => {
    const baseUrl = new URL(window?.location?.href).origin;
    setReferUrl(baseUrl + "/referral/" + userData?.userInfo?.referral_code)
    if (userData) {
      setSelectedImage(userData?.userInfo?.profile_image_url)
    }
  }, [])


  const handleImageClick = () => {
    setImgUpdate(true)
    fileInputRef.current.click();
  };

  const editUserInfo = async () => {
    const editProfieData = new FormData();
    if (imageFile) {
      editProfieData.append("profile", imageFile);
    }
    editProfieData.append("first_name", first_name != '' ? first_name : userData.firstName);

    const res = await axios.post(CONFIG.BASE_URL + "/v1/user/update-profile", editProfieData, {
      headers: { 'Authorization': `Bearer ${userData.jwtToken}` }
    });
    if (res.data.status) {
      if (!imageFile) {
        setInputName(!inputName)
      }
      let finalUserInfo = { ...userData.userInfo };
      if (!finalUserInfo?.profile_image_url) {
        finalUserInfo.profile_image_url = res?.data?.data?.profile_image_url
        dispatch(userInfo(finalUserInfo));
      }
      if (imageFile) {
        toast.success("Image updated successfully..")
      } else {
        toast.success("Name updated successfully..")
      }
      dispatch(firstName(res.data.data.first_name));
    }

  }

  const handleClose = () => {
    setShow(false);
    setShowTelegramModal(false)
  };
  const handleShow = () => setShow(true);

  const goingToSubscriptionPage = () => {
    navigate("/subscription-plan")
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    setError("");
    if (userData.userInfo.email) {
      if (!current_password || !new_password || !confirm_password) {
        setError("All fields are required.");
        return;
      }
    } else {
      if (!email || !new_password || !confirm_password) {
        setError("All fields are required.");
        return;
      }
    }

    if (new_password !== confirm_password) {
      setError("New password and confirm password do not match.");
      return;
    }

    try {
      let reqData = userData?.userInfo?.email ? {
        current_password,
        new_password,
        confirm_password,
      } : {
        email,
        new_password,
        confirm_password,
      }
      const response = await _post("/v1/user/change-password", reqData, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });

      if (response.data.status) {
        let finalUserInfo = { ...userData.userInfo };
        if (!finalUserInfo?.email) {
          finalUserInfo.email = email
          dispatch(userInfo(finalUserInfo));
        }
        toast.success(response.data.message);
        handleClose();
      } else {
        setError("Failed to update the password. Please try again.");
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred while updating the password.");
    }
  };

  useEffect(() => {
    if (userData?.walleteSignature && showLogin) {
      createWalleteAddress()
    }
  }, [userData?.walleteSignature])

  const createWalleteAddress = async () => {

    try {
      const res = await _post("/v1/user/connect-wallet", { signature: userData?.walleteSignature }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.success) {
        dispatch(walleteSignature(null));
        let finalUserInfo = { ...userData.userInfo || {} };
        finalUserInfo.wallet_address = resp?.data
        dispatch(userInfo(finalUserInfo));
        setShowLogin(!showLogin)
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const generateTelegramKey = async () => {
    setTelegramKey("")
    setShowTelegramModal(true)
    try {
      const res = await _post("/v1/user/generate-telegram-key", { signature: userData?.walleteSignature }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.telegram_username) {
        setShowTelegramModal(false);
        toast.success(resp?.message);
        let finalUserInfo = { ...userData.userInfo };
        finalUserInfo.telegram_username = resp?.telegram_username
        finalUserInfo.telegram_id = resp?.telegram_id
        dispatch(userInfo(finalUserInfo));
      } else if (resp?.status) {
        setTelegramKey(resp?.data)
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const unlinkTelegramAccount = async () => {
    setTelegramKey("")
    try {
      const res = await _get("/v1/user/unlink-telegram-account", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data

      if (resp?.status) {
        let finalUserInfo = { ...userData.userInfo };
        finalUserInfo.telegram_username = null
        finalUserInfo.telegram_id = null
        dispatch(userInfo(finalUserInfo));
      } else {
        toast.error(resp?.message);
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const updateEmailAddress = async () => {
    try {
      const res = await _post("/v1/user/update-email", { email }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.status) {
        let finalUserInfo = { ...userData.userInfo };
        finalUserInfo.email = email
        dispatch(userInfo(finalUserInfo));
        toast.success(resp?.message);
        setInputEmail(!inputEmail)
      } else {
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const verifyEmailAddress = async () => {
    try {
      const res = await _post("/v1/user/send-verify-email", {}, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.status) {
        toast.success(resp?.message);
      } else {
        if (resp?.is_email_verified) {

          let finalUserInfo = { ...userData.userInfo || {} };
          finalUserInfo.is_email_verified = true
          dispatch(userInfo(finalUserInfo));
        }
        toast.error(resp?.message);
      }

    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const generateApiKey = async () => {
    const userConfirmed = window.confirm('Are you sure you want to generate api key?');
    if (!userConfirmed) {
      return;
    }
    try {
      const res = await _get("/v1/user/create-api-key", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.status) {
        let finalUserInfo = { ...userData.userInfo || {} };
        finalUserInfo.api_key = resp?.data
        dispatch(userInfo(finalUserInfo));
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  const deleteApiKey = async () => {
    const userConfirmed = window.confirm('Are you sure you want to delete api key?');
    if (!userConfirmed) {
      return;
    }
    try {
      const res = await _get("/v1/user/delete-api-key", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp?.status) {
        let finalUserInfo = { ...userData.userInfo || {} };
        finalUserInfo.api_key = null
        dispatch(userInfo(finalUserInfo));
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  return (
    <Layout>
      {/* <Tabs onTabChange={handleTabChange}>
        <Tab title="Quick Buy">
          <div>
            <div style={{ color: 'white' }}>
              <span>Slippage</span>
            </div>
            <div style={{ color: '#a5a5a5' }}>
              <span>How much less tokens you're willing to receive from a trade due to price volatility.</span>
            </div>
            <div>
              <input
                type="text"
                placeholder=""
                style={inputStyle}
              />
              <span style={{ color: '#a5a5a5', marginLeft: '5px' }}>%</span>
            </div>
          </div>
          <div className='mt-2'>
            <div style={{ color: 'white' }}>
              <span>Max Gas Limit</span>
            </div>
            <div style={{ color: '#a5a5a5' }}>
              <span>The maximum amount of gas you are willing to offer for ANY transaction. We highly recommend that you refrain from adjusting this setting.</span>
            </div>
            <div>
              <input
                type="text"
                placeholder=""
                style={inputStyle}
              />
            </div>
          </div>
          <div className='mt-2'>
            <div style={{ color: 'white' }}>
              <span>Priority Fee</span>
            </div>
            <div style={{ color: '#a5a5a5' }}>
              <span>Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner.</span>
            </div>
            <div>
              <input
                type="text"
                placeholder=""
                style={inputStyle}
              />
              <span style={{ color: '#a5a5a5', marginLeft: '5px' }}>gwei</span>
            </div>
          </div>
          <div className='mt-2'>
            <div style={{ color: 'white' }}>
              <span>Mev Protection</span>
            </div>
            <div style={{ color: '#a5a5a5' }}>
              <span>Enable this for protection against sandwich attacks from MEV bots and save on gas fees in the event of a failed transaction.</span>
            </div>
            <div>
              <input
                type="checkbox"
                placeholder=""

              />
              <span style={{ color: '#a5a5a5', marginLeft: '5px' }}>Enable</span>
            </div>
          </div>
          <div className='mt-2'>
            <div style={{ color: 'white' }}>
              <span>Bribery Amount</span>
            </div>
            <div style={{ color: '#a5a5a5' }}>
              <span>Set an additional bribe amount on top of your priority fee for the block builder to place your transaction as soon as possible.</span>
            </div>
            <div>
              <input
                type="text"
                placeholder=""
                style={inputStyle}
              />
              <span style={{ color: '#a5a5a5', marginLeft: '5px' }}>ETH</span>
            </div>
          </div>
          <div className='mt-2'>
            <div style={{ color: 'white' }}>
              <span>Customize</span>
            </div>
            <div style={{ color: '#a5a5a5' }}>
              <span>Customize your Quick Buy buttons with your own preset amounts.</span>
            </div>
            <div>
              <input
                type="text"
                placeholder=""
                style={inputStyle}
              />
              <input
                type="text"
                placeholder=""
                style={{ ...inputStyle, marginLeft: '5px' }}
              />
              <input
                type="text"
                placeholder=""
                style={{ ...inputStyle, marginLeft: '5px' }}
              />
              <input
                type="text"
                placeholder=""
                style={{ ...inputStyle, marginLeft: '5px' }}
              />
              <input
                type="text"
                placeholder=""
                style={{ ...inputStyle, marginLeft: '5px' }}
              />
              <input
                type="text"
                placeholder=""
                style={{ ...inputStyle, marginLeft: '5px' }}
              />

            </div>
          </div>
          <div className='mt-2'>
            <Button
              variant="primary"
              style={{
                width: "100px",
                height: "40px",
                cursor: "pointer",
                background: "linear-gradient(70.25deg, #12E2CC 5.93%, #23D1DB 21.64%, #009DCD 58.29%)",
              }}
            >
              Save
            </Button>
          </div>
        </Tab>
        <Tab title="Quick Sell">
          <div style={{ height: "100vh", color: "white" }}>
            <h2>Tab Quick Sell Content</h2>
            <p>This is the content of the second tab.</p>
          </div>

        </Tab>
        <Tab title="Approve">
          <div style={{ height: "100vh", color: "white" }}>
            <h2>Tab Approve Content</h2>
            <p>This is the content of the third tab.</p>
          </div>
        </Tab>
        <Tab title="Auto-Buy">
          <div style={{ height: "100vh", color: "white" }}>
            <h2>Tab Auto-Buy Content</h2>
            <p>This is the content of the third tab.</p>
          </div>
        </Tab>
        <Tab title="Auto Sell">
          <div style={{ height: "100vh", color: "white" }}>
            <h2>Tab Auto Sell Content</h2>
            <p>This is the content of the third tab.</p>
          </div>
        </Tab>
      </Tabs> */}
      <h4 style={{ color: "white" }}>Setting</h4>
      <Row>
        <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12">
          <div className="border-style-background" style={{ height: "100%" }}>

            <div className="edit-profile-img-div">
              <div className="edit-profile-img-section">
                <img src={selectedImage} height={175} width={175} alt="Selected" style={{ borderRadius: "50%" }} />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <img height={18} width={18} src={editIcon} className='img-edit-icn-setting' onClick={handleImageClick} />
              </div>
              <div className="edit-profile-img-text">
                {imgUpdate && <div className='pt-2'>
                  <button type="button" className="btn btn-primary edit-profile-save-btn"
                    onClick={() => editUserInfo()} style={{ width: "100px" }}
                  >Update</button>
                  <button type="button" className="btn btn-primary edit-profile-save-btn"
                    onClick={() => setImgUpdate(!imgUpdate)} style={{ width: "100px", marginLeft: "5px" }}
                  >Cancel</button>
                </div>}
                <div>
                  <span className='font-color' style={{ fontSize: '35px', fontWeight: "500" }}>
                    {userData.firstName}
                  </span>
                </div>
                {/* <div style={{ color: '#d5d5d5' }}>{userData.role}</div> */}
              </div>
            </div>

          </div>
        </div>
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 margin-top-cls">
          <div className="border-style-background">
            <div className="setting-edit-div">
              <Row>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="edit-profile-label">Name</label>
                  <input type="text" className={`edit-profile-input ${!inputName ? "edit-name-div" : ""}`} placeholder="Enter your first name"
                    defaultValue={userData.firstName} onChange={(e) => setFirstName(e.target.value)}
                    disabled={inputName}
                  />
                  <img height={18} width={18} src={editIcon} style={{ cursor: "pointer" }} onClick={() => setInputName(!inputName)} />
                  {!inputName && <button type="button" className="btn btn-primary edit-profile-save-btn"
                    onClick={() => editUserInfo()} style={{ marginLeft: "10px" }}
                  >Save</button>}
                  {!inputName && <button type="button" className="btn btn-primary edit-profile-save-btn"
                    onClick={() => setInputName(!inputName)} style={{ marginLeft: "10px" }}
                  >Cancel</button>}
                </div>
              </Row>
              {userData?.userInfo?.email && <Row className="mt-2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="edit-profile-label">Email</label>
                  <input type="text" placeholder="Enter your email"
                    defaultValue={userData?.userInfo?.email} disabled={inputEmail}
                    className={`edit-profile-input ${!inputEmail ? "edit-name-div" : ""}`}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* {userData?.userInfo?.is_email_verified && <img height={18} width={18} src={lockIcon} />} */}
                  {userData?.userInfo?.is_email_verified && <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#62ff62" }} />}
                  {inputEmail && !userData?.userInfo?.is_email_verified && <button type="button" className="btn btn-primary setting-connet-btn"
                    style={{ marginLeft: "20px" }} onClick={verifyEmailAddress}
                  >Verify</button>}

                  {inputEmail && !userData?.userInfo?.is_email_verified ? <button type="button" className="btn btn-primary setting-connet-btn"
                    style={{ marginLeft: "20px" }} onClick={() => setInputEmail(!inputEmail)}
                  >Update</button> : null}

                  {!inputEmail && <button type="button" className="btn btn-primary setting-connet-btn"
                    style={{ marginLeft: "20px" }} onClick={updateEmailAddress}
                  >Save</button>}

                  {!inputEmail && <button type="button" className="btn btn-primary setting-connet-btn"
                    style={{ marginLeft: "20px" }} onClick={() => setInputEmail(!inputEmail)}
                  >Cancel</button>}
                </div>
              </Row>}
              <Row className="pt-4">
                <div className={`change-pw-btn ${userData?.userInfo?.email ? "pw-width" : "email-width "}`} onClick={handleShow}>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <span style={{ fontSize: "22px", fontWeight: "600", color: "white" }}>
                      {userData?.userInfo?.email ? "Change Password" : "Set Email & Password"}
                    </span>
                    <div className="center-class">
                      <div className="errow-bg">
                        <img src={errowImg} width={18} height={18} />
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="pt-4">
                <div>
                  <div className='setting-edit-wallet-div width-100'>
                    <label className="edit-profile-label" style={{ color: "white" }}>Twitter A/C Link</label>
                    <button type="button" className="btn btn-primary setting-connet-btn"
                      style={{ marginLeft: "20px" }}
                    >Comming Soon</button>
                  </div>
                </div>
              </Row>
              <Row className="pt-4">
                <div>
                  <div className='setting-edit-wallet-div width-100'>
                    <label className="edit-profile-label" style={{ color: "white" }}>Telegram A/C Link</label>
                    {!userData.userInfo.telegram_id ? <button type="button" className="btn btn-primary setting-connet-btn"
                      style={{ marginLeft: "20px" }}
                      onClick={generateTelegramKey}
                    >Link Now</button> :
                      <div style={{ display: 'inline-block' }}>
                        <span style={{ marginLeft: "20px", color: "white" }}>{userData.userInfo.telegram_username}</span>
                        <button type="button" className="btn btn-primary setting-connet-btn"
                          style={{ marginLeft: "20px" }}
                          onClick={unlinkTelegramAccount}
                        >Unlink</button>
                      </div>
                    }
                  </div>
                </div>
              </Row>
              <Row className="pt-4">
                <div>
                  <div className='setting-edit-wallet-div width-100'>
                    <label className="edit-profile-label" style={{ color: "white" }}>Subscribe Plan</label>
                    <span style={{ marginLeft: "20px" }} className='font-color'>{userData?.userInfo?.subscriptionDetails?.plan_name}</span>
                    <button type="button" className="btn btn-primary setting-connet-btn"
                      style={{ marginLeft: "20px" }} onClick={goingToSubscriptionPage}
                    >Change Plan</button>
                  </div>
                </div>

              </Row>
              <Row className="pt-4">
                <div>
                  <div className='setting-edit-wallet-div'>
                    <label className="edit-profile-label" style={{ color: "white" }}>Linked Wallet</label>
                    <span style={{ color: "white", wordWrap: "break-word" }}>{" - " + (userData?.userInfo?.wallet_address || "")}</span>
                    {userData?.userInfo?.wallet_address && <img src={copy} height={18} width={18} onClick={() => handleCopy(userData?.userInfo?.wallet_address)} style={{ marginLeft: "10px" }} className='c-point' />}

                    {!userData?.userInfo?.wallet_address && <button type="button" className="btn btn-primary setting-connet-btn"
                      style={{ marginLeft: "20px" }} onClick={() => setShowLogin(!showLogin)}
                    >Connect Wallete</button>}
                  </div>
                </div>
              </Row>
              <Row className="pt-4">
                <div>
                  <div className='setting-edit-wallet-div'>
                    <label className="edit-profile-label" style={{ color: "white" }}>Referral Link</label>
                    <span style={{ color: "white", wordWrap: "break-word" }}>{" - " + (referUrl || "")}</span>
                    {userData?.userInfo?.referral_code && <img src={copy} height={18} width={18} onClick={() => handleCopy(referUrl)} style={{ marginLeft: "10px" }} className='c-point' />}
                  </div>
                </div>
              </Row>
              <Row className="pt-4">
                <div className="col-12">
                  <label className="edit-profile-label" style={{ color: "white" }}>API Key</label>
                  <span style={{ color: "white", wordWrap: "break-word" }}>{" - " + (userData?.userInfo?.api_key || "")}</span>
                  {!userData?.userInfo?.api_key && <button type="button" className="btn btn-primary setting-connet-btn"
                    style={{ marginLeft: "10px" }} onClick={generateApiKey}
                  >Generate</button>}
                  {userData?.userInfo?.api_key && <img src={copy} height={18} width={18} onClick={() => handleCopy(userData?.userInfo?.api_key)} style={{ marginLeft: "10px" }} className='c-point' />}
                  {userData?.userInfo?.api_key && <button type="button" className="btn btn-primary setting-connet-btn"
                    style={{ marginLeft: "10px" }} onClick={deleteApiKey}
                  >Delete</button>}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      {showLogin && <LoginWithWallete origin="settings" />}
      {/* <ComingSoon /> */}
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          style={{ background: "rgb(9, 20, 32)", borderBottom: "none" }}
        >
          <Modal.Title style={{ color: "#ffffff" }}>
            {!userData?.userInfo?.email ? "Add Email and Password" : "Change Password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "rgb(9, 20, 32)" }}>
          <Form onSubmit={handleUpdate}>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {userData?.userInfo?.email ? <Form.Group className="mb-2" controlId="">
              <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
                Current Password
              </Form.Label>
              <div className='center-class'>
                <Form.Control
                  type={showCurrentPassword ? 'text' : 'password'}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye} onClick={() => togglePasswordVisibility('current')}
                  className="c-point"
                  style={{ color: "white", marginLeft: "5px" }} />
              </div>
            </Form.Group> : <Form.Group className="mb-2" controlId="">
              <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
                Email
              </Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>}
            <Form.Group className="mb-2" controlId="">
              <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
                New Password
              </Form.Label>
              <div className='center-class'>
                <Form.Control
                  type={showNewPassword ? 'text' : 'password'}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} onClick={() => togglePasswordVisibility('new')}
                  className="c-point"
                  style={{ color: "white", marginLeft: "5px" }} />
              </div>
            </Form.Group>
            <Form.Group className="mb-2" controlId="">
              <Form.Label style={{ color: "#ffffff", fontWeight: "600" }}>
                Confirm Password
              </Form.Label>
              <div className='center-class'>
                <Form.Control
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} onClick={() => togglePasswordVisibility('confirm')}
                  className="c-point"
                  style={{ color: "white", marginLeft: "5px" }} />
              </div>

            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ background: "rgb(9, 20, 32)", borderTop: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleUpdate}
          >
            {userData?.userInfo?.email ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTelegramModal}
        onHide={handleClose}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          style={{ background: "rgb(9, 20, 32)", borderBottom: "none" }}
        >
          <Modal.Title style={{ color: "#ffffff" }}>
            Telegram Account Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "rgb(9, 20, 32)", color: "white" }}>
          <span className='font-color'>your Account Key - </span>
          <div className='pt-2'>
            <span style={{ wordWrap: "break-word" }}>{telegramKey}</span>
            {telegramKey && <img src={copy} height={25} width={25} style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => handleCopy(telegramKey)} />}
          </div>
          <div className='pt-4'>
            <span className='font-color'>use this key to link account with <a className='font-color text-underline' href="https://t.me/kolsAisignalbot" target='blank' style={{ textDecoration: "underline" }}>"https://t.me/kolsAisignalbot"</a> in telegram</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "rgb(9, 20, 32)", borderTop: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button
            type="submit"
            variant="primary"
            onClick={handleUpdate}
          >
            Link
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Setting;
