import React from "react";
import DashBoardHeader from "../dashBoardHeader/DashBoardHeader";
import Common from "../commonContent/Common";
import Layout from "../layout/Layout";
import ComingSoon from "../ComingSoon/ComingSoon";

const Filters = () => {
  return (
    <Layout>
      <ComingSoon />
    </Layout>
  );
};

export default Filters;
