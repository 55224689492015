import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../config";

export const Apis = createApi({
  reducerPath: "apis",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.BASE_URL,
    // eslint-disable-next-line no-empty-pattern
    prepareHeaders: async (headers, { getState }) => {
      const token = localStorage.getItem("token");

      if (token) {
        // Check if token is expired
        const tokenExpiration = localStorage.getItem("tokenExpiration");
        if (tokenExpiration && Date.now() >= parseInt(tokenExpiration)) {
          // Token expired, refresh it
          try {
            const refreshedToken = await fetch(`${CONFIG.BASE_URL}/refresh`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }).then((res) => res.json());

            // Update token and token expiration
            localStorage.setItem("token", refreshedToken.accessToken);
            const newExpiration = Date.now() + refreshedToken.expiresIn * 1000;
            localStorage.setItem("tokenExpiration", newExpiration);

            headers.set(
              "Authorization",
              `Bearer ${refreshedToken.accessToken}`
            );
          } catch (error) {
            // Handle token refresh error
            console.error("Failed to refresh token:", error);
            // Optionally, you can redirect to login page or handle it as you prefer
            window.location.href = "/";
            // throw error;
          }
        } else {
          headers.set("Authorization", `Bearer ${token}`);
        }
      }

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
