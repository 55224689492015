import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { Web3Modal } from "@web3modal/react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderStyled, StyledDrawerContainer } from "./styled";
import HeaderLogo from "../../assets/images/cognofiBetaLogo.png";
import MenuImg from "../../assets/images/hamburger.png";
import ProfileImage from "../../assets/images/profile.png";
import LeftSidebar from './LeftSidebar'
import { StyledSideBar } from "../SiderBarMenue/style";
import {
  isSideBarVisible,
  resetAll,
  isMobile,
} from "../../redux/user/UserSlice";
import { handleCopy } from "../commonFunction/commonFunction";
import { StyledHeaderWrapper } from "../../styles/header.style";
import search from "../../assets/images/search.svg";

const DashBoardHeader = () => {
  const dispatch = useDispatch();
  const { isConnected } = useAccount();
  const userData = useSelector((state) => state.ROOT);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth < 1100);
      dispatch(isMobile(window.innerWidth < 1100));
    }

    // Initial check
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const location = useLocation();

  const [isSideBarMenue, setSideBarMenue] = useState(false);
  // Initialize state for sidebar visibility
  const [isVisible, setIsVisible] = useState(true);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/trending-contract" || location.pathname === "/prelaunch-calls" || location.pathname === "/token-trending") {
      setIsSubmenuOpen(true)
    }
  }, [location.pathname])

  const handleLiveTrendingClick = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };
  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    if (!isMobileScreen) {
      setIsVisible(!isVisible);
      dispatch(isSideBarVisible(isVisible));
    } else {
      setSideBarMenue(true);
    }
  };
  function formatString(str) {
    if (str) {
      return str.substring(0, 4) + "..." + str.substring(str.length - 4);
    }
  }

  const handleDisconnectWallet = async () => {
    disconnect();
    dispatch(resetAll());
    const referalCode = localStorage.getItem("referral_code");
    localStorage.clear();
    if (referalCode) {
      localStorage.setItem("referral_code", referalCode);
    }

    if (Web3Modal?.cachedProvider) {
      await Web3Modal?.clearCachedProvider();
    }
    window.location.reload(true);
    /* if (!isConnected) {
      navigate("/");
    } */
  };

  const sessionValidTill = localStorage.getItem("sessionValidTill");
  if (sessionValidTill && Date.now() >= parseInt(sessionValidTill)) {
    handleDisconnectWallet()
  }
  return (
    <div>
      {isVisible && !isMobileScreen ? (
        <HeaderStyled>
          {/* <div className="menu">
            <img
              src={MenuImg}
              alt=""
              onClick={toggleSidebar}
              style={{ cursor: "pointer" }}
            />
          </div> */}
          <div style={{ display: "flex", justifyContent: 'space-between' }} className="header-border">
            <div className="search-header">
              <input type="text" className="search-header-input border-style"
                placeholder="Search..."
              />
              <img src={search} alt="search" style={{ position: 'absolute', right: '15px' }} />
            </div>
            {localStorage.getItem("token") && <div
              style={{
                display: 'flex',
                marginRight: '20px',
                alignItems: 'center'
              }}
            >
              <img src={userData?.userInfo?.profile_image_url} alt="" width={45} height={45} style={{
                borderRadius: "50%",
                marginRight: "10px"
              }} />
              <div style={{}}>
                <span
                  style={{
                    fontSize: "16px",
                    color: "#D8D8D8",
                    marginRight: "5px",
                  }}
                >
                  {userData?.userInfo?.first_name}
                </span>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCopy(userData?.userInfo?.wallet_address)}
                >
                  {formatString(userData?.userInfo?.wallet_address)}
                </div>
              </div>
            </div>}

          </div>

        </HeaderStyled>
      ) : (
        <StyledHeaderWrapper>
          <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
            <img
              src={HeaderLogo}
              alt="headerLogo"
              style={{
                marginLeft: "10px",
                display: isMobileScreen ? "none" : "",
                width: "35%",
              }}
            />
            <div className="menu">
              <img
                src={MenuImg}
                alt=""
                onClick={toggleSidebar}
                style={{
                  cursor: "pointer",
                  marginLeft: isMobileScreen ? "15px" : "",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "40px",
            }}
          >
            <img src={ProfileImage} alt="" />
            <div style={{}}>
              <span
                style={{
                  fontSize: "16px",
                  color: "#D8D8D8",
                  marginRight: "5px",
                }}
              >
                {userData?.firstName}
              </span>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => handleCopy(userData?.walletAddress)}
              >
                {formatString(userData?.walletAddress)}
              </div>
            </div>
          </div>
        </StyledHeaderWrapper>
      )}

      {isVisible && !isMobileScreen &&
        <LeftSidebar handleDisconnectWallet={handleDisconnectWallet} isActive={false} />
      }
      <StyledDrawerContainer>
        {/* <ResponsiveSideBar
          isOpen={isSideBarMenue && isMobileScreen}
          setIsOpen={setSideBarMenue}
          handleDisconnectWallet={handleDisconnectWallet}
        /> */}
        <StyledSideBar
          placement={"left"}
          closable={false}
          getContainer={false}
          onClose={() => setSideBarMenue(false)}
          open={isSideBarMenue && isMobileScreen}
        >
          <LeftSidebar handleDisconnectWallet={handleDisconnectWallet} isActive={true} />
        </StyledSideBar>
      </StyledDrawerContainer>
    </div>
  );
};

export default DashBoardHeader;
