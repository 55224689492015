import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import MainDashboardInner from "./MainDashboardInnerPart";
import { ProfileCompletionModal } from "../../routes/ProfileCompletionModal";
import { useSelector } from "react-redux";
import { userInfo } from "../../redux/user/UserSlice";
const MainDashboard = () => {
  const userData = useSelector((state) => state.ROOT);
  const [profileCompleteShowModal, setProfileCompleteShowModal] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("profileModal") === "true") {
      setProfileCompleteShowModal(true);
    }
    if (userData?.userInfo?.is_email_verified && userData?.userInfo?.wallet_address && userData?.userInfo?.telegram_id) {
      setProfileCompleteShowModal(false);
    }
    sessionStorage.setItem("profileModal", false);
  }, []);

  return (
    <Layout>
      <div className={profileCompleteShowModal ? "blur-background" : ""}>
        <MainDashboardInner />
      </div>
      <ProfileCompletionModal profileCompleteShowModal={profileCompleteShowModal} setProfileCompleteShowModal={setProfileCompleteShowModal} />
    </Layout>

  );
};

export default MainDashboard;
