import { io } from "socket.io-client";
import { CONFIG } from "./config";

let socket;

const initializeSocket = (token) => {
    /* socket = io(CONFIG.BASE_SOCKET_URL, { // replace with your server URL
        extraHeaders: {
            Authorization: `Bearer ${token}`,
        },
    }); */
    if (token) {
        socket = io(CONFIG.BASE_SOCKET_URL, { // replace with your server URL
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    } else {
        socket = io(CONFIG.BASE_SOCKET_URL);
    }
    return socket;
};

initializeSocket();

const getSocket = () => socket;

export { initializeSocket, getSocket };