import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { userInfo } from "../../redux/user/UserSlice";
import "./style-for-subscription.css";
import { _get, _post } from "../../api/ApiClient";
import { SubscriptionPlanModal } from "./SubscriptionPlanModal";
import { subscriptionDetails } from "../../redux/user/UserSlice";
import LoginWithWallete from "../../../src/components/LoginPage/LoginWithWallet"

export const SubscriptionTable = () => {
  const userData = useSelector((state) => state.ROOT);
  const dispatch = useDispatch();
  const [suscriptionPlanList, setSuscriptionPlanList] = useState([])
  const [show, setShow] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState('')
  const [selectedPaymentType, setSelectedPaymentType] = useState('token')
  const [selectedTimeSpan, setSelectedTimeSpan] = useState('monthly')
  const [showLogin, setShowLogin] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("")

  useEffect(() => {
    if (selectedPlanId) {
      if (userData?.walleteSignature) {
        selectPlan()
      } else if (userData?.userInfo?.wallet_address) {
        selectPlan()
      } else {
        setShowLogin(true);
      }
    }
  }, [selectedPlanId, userData?.walleteSignature]);

  const getSubscriptionPlan = async () => {
    try {
      const res = await _get("/v1/user/get-subscription-plans", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (res.data) {
        setSuscriptionPlanList(res.data.data)

        const transformedArray = res?.data?.data?.header_data?.map(item => ({
          ...item,
          id: item.id.toString() // Example transformation
        }));

        const index = transformedArray?.findIndex(
          item => item.id === userData?.userInfo?.subscriptionDetails?.plan_id
        );

        setSelectedIndex(index)
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  function formatText(text) {
    let str = text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase())
    if (text == 'aiSmartSearch' || text == 'aiTechnicalAlert' || text == 'newPairAlert' || text == 'contractInsight' || text == 'autoBuySellFees') {
      str = str + '</br>(Coming Soon)'
    }
    return (
      <span
        dangerouslySetInnerHTML={{ __html: str }}
      />
    );
  }

  useEffect(() => {
    getSubscriptionPlan()
  }, [])

  const handleShow = (item) => {
    setShow(true)
    setSelectedPlanId(item)
  }

  const getTableData = (key, value, planIndex) => {
    if (key === 'monthlyCost') {
      if (planIndex === 0) {
        let str = `Free for <span style="text-decoration: line-through">${value.actual_price}Days</span> ${value.effective_price}Days`
        return (
          <span
            dangerouslySetInnerHTML={{ __html: str }}
          />
        );
      }
      if (planIndex === 3) {
        return ""
      }
      let str = `<span style="text-decoration: line-through">$${value.actual_price}/month</span> </br> $${value.effective_price}/month`;
      return (
        <span
          dangerouslySetInnerHTML={{ __html: str }}
        />
      );
    } else if (key === 'yearlyCost') {
      if (planIndex === 0) {
        return 0
      }
      if (value.actual_price == -1 || value.effective_price == -1) {
        return ''
      }
      let str = `<span style="text-decoration: line-through">$${value.actual_price}/year</span> </br> $${value.effective_price}/year`;
      return (
        <span
          dangerouslySetInnerHTML={{ __html: str }}
        />
      );
    }
    else if (key === 'tokenHold') {
      if (planIndex == 0) {
        return 'Join Telegram group'
      }
      if (planIndex == 3) {
        return `Stake 1% ${value}`
      }
      return "Hold " + value + " $KolsAI"
    } else if (key === 'aiSmartSearch' || key === 'contractInsight') {
      return value + '/days'
    } else if (key === 'apiRateLimit' && value > 0) {
      return value + '/min'
    } else if (key === 'autoBuySellFees') {
      return value + '%'
    } else if (key === 'lifetimeCost') {
      if (planIndex === 3) {
        return `$${value}/Lifetime`
      }
    }
    else {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  }

  const getPrice = (plantId, planName = '', payType = 'token', planTime = 'monthlyCost') => {
    let html = '';
    if (planName.toLowerCase() == 'test plan') {
      html += `<span style="font-size: 20px; color: #13FFFF">Join</span><br />
                <span style="font-size: 20px">Telegram group</span>
      `;
    } else {
      if (payType == 'money') {

      } else {
        html += `<span style="font-size: 20px; color: #13FFFF">${suscriptionPlanList?.pricing[plantId].tokenHold}</span><br />
                <span style="font-size: 20px">$KolsAI Hold</span>
      `;
      }
    }
    return html;
  }

  const selectPlan = async () => {

    const userConfirmed = window.confirm('Are you sure you want to change your plan?');
    if (!userConfirmed) {
      return;
    }

    if (!selectedPlanId) {
      toast.error("Please select plan....")
      return
    }
    try {
      let data = {
        plan_id: selectedPlanId,
        time_span: selectedTimeSpan,
        pay_type: selectedPaymentType,
        signature: userData.walleteSignature
      }
      const res = await _post("/v1/user/update-plan", data, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      let resp = res?.data
      if (resp) {
        if (resp?.status) {
          let finalUserInfo = { ...userData.userInfo };
          if (resp?.user) {
            finalUserInfo = { ...resp?.user };
          }
          finalUserInfo.subscriptionDetails = resp?.data
          const index = suscriptionPlanList?.header_data?.findIndex(
            item => item.id === resp?.data?.plan_id
          );
          setSelectedIndex(index);
          dispatch(userInfo(finalUserInfo))
          toast.success(resp?.message);
        } else {
          if (resp?.user) {
            dispatch(userInfo(resp?.user))
          }
          toast.error(resp?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong");
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="mt-2" style={{ width: "100%", maxWidth: "1000px" }}>
        <Table responsive className="table-subscription text-center">
          <thead>
            <tr>
              <th style={{ border: "none" }}></th>
              {suscriptionPlanList?.header_data?.map((item, index) => (
                <th className={item.id == userData?.userInfo?.subscriptionDetails?.plan_id ? "selected-plan" : ""}>{item.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-start" style={{ border: "none" }}>
                <div style={{ marginLeft: '5px' }}>
                  <span style={{ fontSize: "20px" }}>Subscription</span><br />
                  <span style={{ fontSize: "20px", color: "#13FFFF" }}>Plan</span>
                </div>
              </td>
              {suscriptionPlanList?.header_data?.map((item, index) => (
                <td className={item.id == userData?.userInfo?.subscriptionDetails?.plan_id ? "selected-plan" : ""}
                  key={index} dangerouslySetInnerHTML={{ __html: getPrice(item.id, item.name, selectedPaymentType) }}></td>
              ))}
            </tr>
            {suscriptionPlanList?.features ? Object.entries(suscriptionPlanList?.features).map(([key, value]) => (
              <tr key={key}>
                <td>{formatText(key)}</td>
                {value?.map((item, index) => (
                  <td className={index == selectedIndex ? "selected-plan" : ""}>{getTableData(key, item, index)}</td>
                ))}
              </tr>
            )) : <></>}
            <tr>
              <td style={{ border: 'none' }}></td>
              {suscriptionPlanList?.header_data?.map((item, index) => (
                <td key={index} className={item.id == userData?.userInfo?.subscriptionDetails?.plan_id ? "selected-plan" : ""}>
                  {item.id == userData?.userInfo?.subscriptionDetails?.plan_id ?
                    <Button
                      variant="primary"
                      className="table-subscription-btn"
                      style={{ color: "black", pointerEvents: "none" }}
                    >
                      CURRENT PLAN
                    </Button>
                    :
                    <Button
                      variant="primary"
                      className="table-subscription-btn"
                      style={{ color: "black" }}
                      onClick={() => setSelectedPlanId(item.id)}
                    >
                      Upgrade
                    </Button>


                  }
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        {showLogin && <LoginWithWallete origin="subscriptio_plan" />}
      </div>

      <SubscriptionPlanModal
        show={show}
        setShow={setShow}
        selectedPlanId={selectedPlanId}
      />
    </div>
  );
};
