import { toast } from "react-toastify";
import solanaImg from "../../assets/images/solana.png"
import etherImg from "../../assets/images/ether.png"
import tonImg from "../../assets/images/ton.png"
import baseImg from "../../assets/images/base.png"
import polygonImg from "../../assets/images/polygon.png"
import bnbImg from "../../assets/images/bnb.png"
import tronImg from "../../assets/images/tron.png"
import suiImg from "../../assets/images/sui.png"
import allChain from "../../assets/images/allchain.png"
import telegram from "../../assets/images/telegram.svg";
import tweet from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import reddit from "../../assets/images/reddit.svg";
import tiktok from "../../assets/images/tiktok.svg";

export const handleCopy = async (data) => {
  try {
    await navigator.clipboard.writeText(data);
    toast.success("Copied successfully");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};

export const timeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diffInSeconds = Math.floor((now - date) / 1000);

  /* const units = [
    { name: "year", seconds: 31536000 },
    { name: "month", seconds: 2592000 },
    { name: "week", seconds: 604800 },
    { name: "day", seconds: 86400 },
    { name: "hour", seconds: 3600 },
    { name: "minute", seconds: 60 },
    { name: "second", seconds: 1 },
  ]; */

  const units = [
    { name: "y", seconds: 31536000 },
    { name: "m", seconds: 2592000 },
    { name: "w", seconds: 604800 },
    { name: "d", seconds: 86400 },
    { name: "h", seconds: 3600 },
    { name: "m", seconds: 60 },
    { name: "s", seconds: 1 },
  ];

  for (const unit of units) {
    const interval = Math.floor(diffInSeconds / unit.seconds);
    if (interval >= 1) {
      //return `${interval} ${unit.name}${interval !== 1 ? 's' : ''} ago`;
      return `${interval}${unit.name}`;
    }
  }

  return "just now";
}

export const twoDigitNumber = (num, digit = 2) => {
  return num?.toFixed(digit)
};

export const formatString = (str) => {
  if (str) {
    //return str.substring(0, 4) + "..." + str.substring(str.length - 4);
    return str.substring(0, 1) + "..." + str.substring(str.length - 2);
  }
}

export const formatStringtoLimit = (str, len = 15) => {
  if (str) {
    if (str.length <= len) {
      return str;
    }
    return str.substring(0, len) + "...";
  }
}

export const calculateTimeAgo = (datetime) => {
  const now = new Date();
  const pastDate = new Date(datetime);
  const timeDifference = now - pastDate; // Difference in milliseconds

  // Convert time difference to various units
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Approximate month (30 days)
  const years = Math.floor(days / 365); // Approximate year (365 days)

  if (years > 0) {
    const remainingMonths = Math.floor((days % 365) / 30);
    return `${years}y, ${remainingMonths > 0 ? remainingMonths + 'm' : ''}`;
  } else if (months > 0) {
    const remainingDays = days % 30;
    return `${months}m, ${remainingDays > 0 ? remainingDays + 'd' : ''}`;
  } else if (days > 0) {
    const remainingHours = hours % 24;
    return `${days}d, ${remainingHours > 0 ? remainingHours + 'h' : ''}`;
  } else if (hours > 0) {
    const remainingMinutes = minutes % 60;
    return `${hours}h, ${remainingMinutes > 0 ? remainingMinutes + 'm' : ''}`;
  } else {
    return `${minutes}m`;
  }
};

export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num?.toString();
  }
};

export const formatDate = (dateString, format = 'dd-mm-yy') => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = String(date.getFullYear()); // Get last two digits of the year

  if (format === 'dd-mm-yy') {
    return `${day}/${month}/${year}`;
  } else if (format === 'mm-dd-yy') {
    return `${month}/${day}/${year}`;
  } else {
    return date.toLocaleString(); // Default format if unknown format is provided
  }
}


export const chainLogo = {
  'solana': solanaImg,
  'ether': etherImg,
  'ethereum': etherImg,
  'ton': tonImg,
  'base': baseImg,
  'polygon': polygonImg,
  'tron': tronImg,
  'bsc': bnbImg,
  'sui': suiImg
}

export const chainText = {
  ether: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_ETH_",
  ethereum: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_ETH_",
  solana: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_SOL_",
  ton: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_TON_",
  base: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_BASE_",
  polygon: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_POLYGON_",
  tron: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_TRON_",
  bsc: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_BSC_",
  sui: "https://t.me/GeekSwapBot/app?startapp=r_Hrr24U_SUI_"
}

export const chainLinkforDextool = {
  ether: "https://www.dextools.io/app/en/ether/pair-explorer/",
  ethereum: "https://www.dextools.io/app/en/ether/pair-explorer/",
  solana: "https://www.dextools.io/app/en/solana/pair-explorer/",
  ton: "https://www.dextools.io/app/en/ton/pair-explorer/",
  base: "https://www.dextools.io/app/en/base/pair-explorer/",
  tron: "https://www.dextools.io/app/en/tron/pair-explorer/",
  bsc: "https://www.dextools.io/app/en/bnb/pair-explorer/",
  polygon: "https://www.dextools.io/app/en/polygon/pair-explorer/",
  sui: "https://www.dextools.io/app/en/sui/pair-explorer/"
}

export const chainDataForDrawer = [
  { name: "All Chains", imgSrc: allChain, value: [""] },
  { name: "Ethereum", imgSrc: etherImg, value: ["ether", "ethereum"] },
  { name: "Solana", imgSrc: solanaImg, value: ["solana"] },
  { name: "Ton", imgSrc: tonImg, value: ["ton"] },
  { name: "Base", imgSrc: baseImg, value: ["base"] },
  { name: "Polygon", imgSrc: polygonImg, value: ["polygon"] },
  { name: "Tron", imgSrc: tronImg, value: ["tron"] },
  { name: "Bsc", imgSrc: bnbImg, value: ["bsc"] },
  { name: "Sui", imgSrc: suiImg, value: ["sui"] },
];

export const chainOptionList = [
  { value: "", label: "All Chains" },
  { value: "ether", label: "Ethereum" },
  { value: "solana", label: "Solana" },
  { value: "ton", label: "Ton" },
  { value: "base", label: "Base" },
  { value: "polygon", label: "Polygon" },
  { value: "bsc", label: "Bsc" },
  { value: "sui", label: "Sui" },
];

export const convertTo24HrFormat = (dateString) => {
  const date = new Date(dateString);

  // Get the hours and minutes
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Format minutes as two digits
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${hours}:${formattedMinutes} Hr`;
}

export const platformLogo = {
  'tweet': tweet,
  'x.com': tweet,
  'youtube-video': youtube,
  'reddit-post': reddit,
  'telegram': telegram,
  'tiktok-video': tiktok
}