import React from "react";
import styled from "styled-components";

// Define a styled component for the container
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Plus Jakarta Sans;
`;

// Define a styled component for the title
const Title = styled.h1`
  font-size: 4rem;
  color: #ffff;
`;

// Define a styled component for the subtitle
const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #abaaaa;
`;

// Define a styled component for the button
const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

function ComingSoon() {
  return (
    <Container>
      <div>
        <Title>Coming Soon...</Title>
        <Subtitle>We're working on something amazing. Stay tuned!</Subtitle>
        {/* <Button>Notify Me</Button> */}
      </div>
    </Container>
  );
}

export default ComingSoon;
