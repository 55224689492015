import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

// Define the styled button component
const StyledButton = styled.button`
  width: 20%;
  font-size: 18px;
  height: 40px;
  background-color: #228b22; // Default background color
  color: white;
  border: none;
  font-weight: 400;
  font-family: Plus Jakarta Sans;

  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; // Smooth transition for hover effect

  &:hover {
    color: #000;
    background-color: #ffff; // Hover background color
  }

  &:active {
    background-color: #228b22; // Active background color
  }
`;

function ApplyBtn({ saveBtn, isLoading, setIsLoading }) {
  return (
    <StyledButton onClick={saveBtn}>
      {isLoading ? (
        <Spinner animation="border" style={{ height: "50px", width: "50px" }} />
      ) : (
        "Apply"
      )}
    </StyledButton>
  );
}

export default ApplyBtn;
