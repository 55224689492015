import React from "react";
import MainSection from "../../components/mainSection/MainSection";

const TokenTrending = () => {
  return (
    <MainSection />
  );
};

export default TokenTrending;
