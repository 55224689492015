import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button, Row, Form } from "react-bootstrap";
import { Pagination } from "antd";
import Select from "react-select";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { twoDigitNumber } from "../../components/commonFunction/commonFunction";
import { StyledInfluencerWrapper } from "./styles";
import { _get, _post } from "../../api/ApiClient";
import {
  useAddChannelMutation,
  useGetInfluencerListQuery,
  useUnfollowChannelMutation,
  useUpdateInfluencerMutation,
  useFollowUnfollowChannelMutation,
} from "../../services/influencerServices";
import { AddInfluencerModal } from "./components/AddInfluencerModal";
import { SearchInput } from "../searchInput/SearchInput";
//import defaultProfile from "../../assets/images/default-profile.jpg";
//import Switch from "../../components/Switch/Switch"

export const TelegramButton = styled.button`
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #272727;
  color: #ffffff;
  border: solid 1px #272727;
  font-weight: 600;
`;

export const TwitterButton = styled.button`
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #12deb9;
  color: #000000;
  border: solid 1px #12deb9;
  font-weight: 600;
`;

export const InfluencerTable = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setIsOpen] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("all");
  const [influencerFollowedStatus, setInfluencerFollowedStatus] = useState("all");
  const userData = useSelector((state) => state.ROOT);
  const handleSearchInputChange = (e) => {
    setCurrentPage(1); //reset page to 1 when search
    setSearchQuery(e.target.value);
  };

  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent", // Set the background color to gray
      boxShadow: null,
      color: "#fff",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
    }),
    menu: (provided) => ({
      ...provided,
      minwidth: "150px", // Adjust the width as needed
      background: "#272727",
      color: "#fff",
      fontFamily: "Plus Jakarta Sans",
    }),
    option: (provided, state) => ({
      ...provided,
      gap: "20px",
      display: "flex",
      cursor: "pointer",
      color: state.isSelected ? "#fff" : "gray",
      backgroundColor: state.isSelected
        ? "black"
        : state.isFocused
          ? "lightgray"
          : null,

      // backgroundColor: state.isFocused ? "#00b8d8" : provided.backgroundColor, // Apply background color on hover or focus
      ":hover": {
        backgroundColor: "#8CDFED",
        color: "#fff", // This will apply when the option is hovered over with the mouse
      },
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 48, // Ensure the indicators container matches the control height
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Set the font color to your desired color for the selected option
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // Set the font color to white for the text you type
    }),
  };

  const [editInfluencer, { isLoading: influencerLoader, isError }] =
    useUpdateInfluencerMutation();
  /* const [addChannel, { isLoading: followLoader, isError: channelError }] =
    useAddChannelMutation();
  const [
    unfollowChannel,
    { isLoading: unfollowLoader, isError: unfollowError },
  ] = useUnfollowChannelMutation(); */

  const [
    followUnfollowChannel,
    { isLoading: followUnfollowLoader, isError: followUnfollowError },
  ] = useFollowUnfollowChannelMutation();

  const { data, error, isLoading, isFetching, refetch, hasNextPage } =
    useGetInfluencerListQuery({
      page_size: pageSize,
      page: currentPage,
      query: searchQuery,
      platform: selectedPlatform,
      follow_status: influencerFollowedStatus
    });

  /* const filteredData = data?.data?.influencers?.filter(
    (item) =>
      item?.social_username
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase()) ||
      item?.influncerName
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase()) ||
      item?.telegramUserName
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase())
  );
  const renderedData = searchQuery ? filteredData : data?.data?.influencers; */
  const renderedData = data?.data?.influencers;
  const followerCount = data?.data?.follower_counts;
  const platforms = data?.data?.platforms || [];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    refetch();
  };
  const handleEdit = (item) => {
    const initial = {
      profileImage: "",
      influencerName: item?.influncerName,
      twitterId: item?.twitterId ?? "-",
      telegramChannel:
        item?.telegramUserName ?? item?.channelUsername,
      id: item?._id,
      contact: item?.contact,
      profile: item?.profile,
    };
    setInitialData(initial);

    setIsOpen(true);
  };
  useEffect(() => {
    refetch();
  }, [open, refetch]);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleEditInfluencer = useCallback(async () => {
    try {
      const {
        profileImage,
        influencerName,
        telegramChannel,
        twitterId,
        id,
        contact,
        profile,
      } = initialData;
      const formBody = {
        profile: profileImage,
        influncerName: influencerName,
        telegramUserName: telegramChannel,
        twitterId: twitterId,
        contact: contact,
      };
      const formData = new FormData();

      if (profileImage) {
        formData.append("profile", profileImage);
      } else {
        formData.append("profile", profile);
      }

      formData.append("influncerName", influencerName);
      formData.append("telegramUserName", telegramChannel);
      formData.append("twitterId", twitterId);
      formData.append("contact", contact);

      const payload = await editInfluencer({ body: formData, channelId: id });
      if (payload.data) {
        toast.success("Influencer Updated successfully !");
        handleClose();
      } else {
        toast.error(payload?.error?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [editInfluencer, initialData]);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleEditInfluencer();
    //Close the modal after successful submission
  };

  const handleFollow = async (influencerId, status) => {
    const body = {
      influencerId: influencerId,
      status: status
    };
    try {
      //const isFollowed = item?.follow?.status === "follow";
      /* const payload = isFollowed
        ? await unfollowChannel({ body: body })
        : await addChannel({ body: body }); */

      const payload = await followUnfollowChannel({ body: body });

      if (payload.data) {
        if (payload.data.status) {
          toast.success(`Channel ${status}ed successfully`);
          handleClose();
        } else {
          toast.error(payload?.data?.message);
        }
      } else {
        toast.error(payload?.error?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchButtonClicked = () => {
    setCurrentPage(1);
    setSearchQuery(inputRef.current.value)
  }

  const clearButtonClicked = () => {
    setCurrentPage(1);
    setSearchQuery("");
    setSelectedPlatform('all');
  }

  const statusFilterUpdate = (status) => {
    setCurrentPage(1);
    setInfluencerFollowedStatus(status)
  }

  const deleteInfluencer = async (id) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this influencer?');
    if (!userConfirmed) {
      return;
    }
    let payload = {
      id: id
    }
    try {
      const res = await _post("/v1/user/deleteInfluencer", payload, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const data = res.data
      if (data.status) {
        toast.success(data.message);
        refetch()
        //window.location.reload()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const updateNotificationStatus = async (id, status) => {

    let payload = {
      influencer_id: id,
      status: status
    }
    try {
      const res = await _post("/v1/user/update-notification-status", payload, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const data = res.data
      if (data.status) {
        toast.success(data.message);
        refetch()
        //window.location.reload()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <StyledInfluencerWrapper>
      <div className="subscription-box-1 mt-3">
        <Row>
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
            <SearchInput
              style={{ border: "1px solid white" }}
              type="text"
              placeholder="Search by influencer or channel name..."
              defaultValue={searchQuery}
              ref={inputRef}
            />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-12">
            <Select
              styles={customStyles2}
              options={platforms}
              defaultValue={platforms[0]}
              isSearchable={false}
              onChange={(e) => { setSelectedPlatform(e.value) }}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12 center-class">
            <div>
              <Button
                variant="primary"
                style={{
                  /* width: "100px", */
                  height: "48px",
                  margin: "0 5px",
                  background:
                    "linear-gradient(70.25deg, #12E2CC 5.93%, #23D1DB 21.64%, #009DCD 58.29%)",
                }} onClick={() => { searchButtonClicked() }}>
                Search
              </Button>
              <Button
                variant="primary"
                style={{
                  /* width: "100px", */
                  height: "48px",
                  background:
                    "linear-gradient(70.25deg, #12E2CC 5.93%, #23D1DB 21.64%, #009DCD 58.29%)",
                }} onClick={() => { clearButtonClicked() }} >
                Clear
              </Button>
            </div>
            <div className="center-class" style={{ paddingLeft: "5px" }}>
              {/* <Switch
                isOn={true}
              />
              <span style={{ color: "white", paddingLeft: "5px" }}>Followed</span> */}
              <span style={{ color: "white", paddingLeft: "5px" }}>Status</span>
              <div style={{ marginLeft: "10px", color: 'white' }}>
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  id="Status"
                  value="unfollowed"
                  checked={influencerFollowedStatus == 'all'}
                  onChange={() => statusFilterUpdate("all")}
                />
                <Form.Check
                  inline
                  label="Followed"
                  name="group1"
                  type="radio"
                  id="Status"
                  value="followed"
                  checked={influencerFollowedStatus == 'followed'}
                  onChange={() => statusFilterUpdate("followed")}
                />
              </div>
            </div>

          </div>
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12" style={{ color: "white", display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "20px", marginLeft: "10px" }}>Followed- <span className="font-color">{followerCount?.total || 0}</span></span>&nbsp;|&nbsp;
            <span style={{ fontSize: "20px" }}>Telegram- <span className="font-color">{followerCount?.telegram || 0}</span></span>&nbsp;|&nbsp;
            <span style={{ fontSize: "20px" }}>X.com- <span className="font-color">{followerCount?.xcom || 0}</span></span>
          </div>
        </Row>
        <Table responsive className="text-start main-dashboard-table dashboard-table-common mt-3">
          <thead>
            <tr>
              <th>Profile</th>
              <th>Influencer Name</th>
              {/*<th>Telegram </th>
                <th>Twitter </th>*/}
              <th>Final Score</th>
              <th>Platform</th>
              <th>Social Link</th>
              <th>No. of Calls</th>
              {/* <th>Contact</th> */}
              <th>Followers</th>
              <th>Follow</th>
              <th>Notification</th>
              {userData?.role === "Admin" && <th>Connection</th>}
              {userData?.role === "Admin" && <th>Action</th>}
              {/* <th>Follow Twitter</th> */}
            </tr>
          </thead>
          <tbody>
            {renderedData && renderedData.length > 0 ? (
              renderedData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <img
                        /* src={item.platform == "telegram" ? defaultProfile : item?.profile} */
                        src={item?.profile}
                        alt="User Profile"
                        width={44}
                        height={44}
                        style={{
                          border: "1px solid white",
                          borderRadius: "45px",
                          padding: "3px",
                        }}
                      />
                    </td>
                    <td onClick={() => navigate(`/influencer-details/${item?._id}`)} style={{ cursor: "pointer" }}>{item?.influncerName ?? "-"}</td>
                    {/* <td onClick={() => navigate(`/influencer-details/${item?._id}`)} style={{ cursor: "pointer" }}>
                        {item?.influencer?.telegramUserName ??
                          item?.channelUsername ??
                          "-"}
                      </td> */}
                    <td><span className="font-color">{twoDigitNumber(item?.final_score?.all_time)}</span></td>
                    <td>{item?.platform == '' ? 'Telegram' : item?.platform}</td>
                    <td>
                      <a target="_blank" href={item?.profile_link} style={{ color: "white" }}>
                        {item?.profile_link}
                      </a>
                    </td>
                    {/* <td>{item?.influencer?.twitterId ?? "-"}</td> */}
                    {/* <td>{item?.count_1 + item.count_2 ?? "-"}</td> */}
                    <td>{item?.msgCount}</td>
                    <td>{item?.followers}</td>
                    {/* <td>{item?.influencer?.contact ?? "-"}</td> */}
                    <td>
                      <TwitterButton
                        style={{
                          color: item?.isFollowing?.status === "follow" ? "white" : "",
                          borderColor: item?.isFollowing?.status === "follow" ? "black" : "",
                          backgroundColor: item?.isFollowing?.status === "follow" ? "#da898c" : "",
                          backgroundImage: item?.isFollowing?.status === "follow" ? "linear-gradient(147deg, #da898c 0%, #59090c 74%)" : "",
                        }}
                        //onClick={() => handleFollow(item.channelInfo)}
                        onClick={() => handleFollow(item?._id, (!item.isFollowing ? "follow" : (item?.isFollowing?.status == 'follow' ? 'unfollow' : 'follow')))}
                      >
                        {/* {!item.isFollowing ? "Follow" : (item.isFollowing.status == 'follow' ? 'Unfollow' : 'Follow')} */}
                        {!item.isFollowing ? "Follow" : (item.isFollowing.status == 'follow' ? 'Unfollow' : (item.isFollowing.status == 'hold' ? 'On Hold' : 'Follow'))}
                      </TwitterButton>
                    </td>
                    <td>
                      {(item?.isFollowing?.status === "follow" || item?.isFollowing?.status === "hold") &&
                        <TwitterButton
                          style={{
                            marginLeft: "5px",
                            backgroundImage: item?.isFollowing?.notify == "on" ? "linear-gradient(147deg, #da898c 0%, #59090c 74%)" : (item?.isFollowing?.notify == "hold" ? "linear-gradient(147deg, #f0ed6f 0%, #ab9718 74%)" : ""),
                            color: item?.isFollowing?.notify == "on" ? "white" : "",
                          }}
                          onClick={() => updateNotificationStatus(item?._id, (item.isFollowing.notify == "on" ? false : true))}
                        >
                          {item?.isFollowing?.notify == "on" ? "Notified" : (item?.isFollowing?.notify == "hold" ? "On Hold" : "Start")}
                        </TwitterButton>
                      }
                    </td>
                    {userData?.role === "Admin" && (
                      <td>
                        {item?.platform != 'telegram' ? '-' : (item?.is_connected_with_boat ? 'Bot' : 'Server')}
                      </td>
                    )}
                    {userData?.role === "Admin" && (
                      <td>
                        {/* <img
                            src="/edit.svg"
                            alt="User Profile"
                            width={24}
                            height={24}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(item)}
                          /> */}
                        <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer", height: "20px", width: "20px", color: "#fff" }} onClick={() => handleEdit(item)} />
                        <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer", height: "20px", width: "20px", marginLeft: "5px", color: "#fff" }} onClick={() => deleteInfluencer(item?._id)} />
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" className="no-data">
                  <h2 style={{ textAlign: "center" }}>No Data</h2>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          className="mt-2"
          current={currentPage}
          pageSize={pageSize}
          total={data?.data?.total_records ?? 0}
          hideOnSinglePage={true}
          onChange={handlePageChange}
        />
      </div>
      <AddInfluencerModal
        initialData={initialData}
        handleClose={handleClose}
        influencerLoader={influencerLoader}
        handleSubmit={handleSubmit}
        setInitialData={setInitialData}
        setShow={setIsOpen}
        show={open}
      />
    </StyledInfluencerWrapper>
  );
};
