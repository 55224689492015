import React, { useEffect, useState } from "react";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Web3Modal } from "@web3modal/react";
import { Button, Row } from "react-bootstrap";
import { MainDashboardText, StyledMainSectionWrapper } from "./MainSection.style";
import { _get } from "../../api/ApiClient";
import { resetAll } from "../../redux/user/UserSlice";
import { getSocket } from "../../socket";
import Layout from "../layout/Layout";
import Filter from "../Filter/Filter";

import TokenCards from "../Cards/TokenCards/TokenCards";
import NewTokenCards from "../Cards/TokenCards/NewTokenCards";
import PrelaunchCard from "../Cards/TokenCards/PrelaunchCard";
import UtilityCard from "../Cards/TokenCards/UtilityCard";
import TagCards from "../Cards/TagCards/TagCards";
import TelegramChatScreen from "../Cards/TelegramChatScreenCard/TelegramChatScreen";
import TrendingTokenChatCard from "../Cards/TelegramChatScreenCard/TrendingTokenChatCard";
import CustomBarChart from "../../pages/MainDashboard/CustomBarChart"

const MainSection = () => {
  //const url = CONFIG.BASE_SOCKET_URL;
  const { address } = useParams()
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.ROOT);
  const { isConnected } = useAccount();
  const [allChannelData, setAllChannelData] = useState([]);
  const [trendingTokenList, setTredningTokenList] = useState([]);
  const [preLaunchList, setPreLaunchList] = useState([]);
  const [newTokenList, setNewTokenList] = useState([]);
  const [utilityTokenList, setUtilityTokenList] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [chatTrendingData, setChatTrendingData] = useState([]);
  const [chatPrelaunchData, setChatPrelaunchData] = useState([]);
  const [currentPageName, setCurrentPageName] = useState(null);

  const [tokenIds, setTokenIds] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [selectedChain, setSelectedChain] = useState("");
  const [click, setClick] = useState(false);
  const [slectedContractAddress, setSlectedContractAddress] = useState("");

  const [tokenLoader, setTokenLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [trendingCardLoader, setTrendingCardLoader] = useState(false);
  const [firstChatArray, setFirstChatArray] = useState([]);
  const [activeClick, setActiveClick] = useState(false);
  const [socket, setSocket] = useState(null);
  const [listOptions, setListOptions] = useState([]);
  const [selectList, setSelectList] = useState();
  const [activeLoader, setActiveLoader] = useState(true);
  const [trendingTokenClick, setTrendingTokenCLick] = useState(false);
  const [newTokenClick, setNewTokenClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const [isTrendingToken, setIsTrendingToken] = useState(false);
  const [isPreluanchCalls, setIsPreluanchCalls] = useState(false);
  const [isTokenTrending, setIsTokenTrending] = useState(false);
  const [isUtilityTrending, setIsUtilityTrending] = useState(false);

  const [preTokenClick, setPreTokenCLick] = useState(false);
  const [clickedIndexs, setClickedIndexs] = useState(null);

  const [influencerData, setInfluencerList] = useState([]);
  const [influencerDataPage, setInfluencerDataPage] = useState(1);
  const [selectedTime, setSelectedTime] = useState(24);
  const [selectedInfluencerListOption, setSelectedInfluencerListOption] = useState('my_influencer');
  const [isTokenUpdateLive, setIsTokenUpdateLive] = useState(false);
  const [tokenNameDisplay, setTokenNameDisplay] = useState('')
  const [displayChart, setDisplayChart] = useState(false)
  const [displayMainChart, setDisplayMainChart] = useState(false)
  const location = useLocation();
  const [divSize, setDivSize] = useState("col-xl-8 col-lg-8 col-md-6")
  const [divSizeTagCrad, setDivSizeTagCrad] = useState("col-xl-6 col-lg-6 col-md-12")
  const [columnChartData, setColumnChartData] = useState([])

  //const currenctPage = (location.pathname === "/prelaunch-calls" ? "prelaunch" : (location.pathname === "/token-trending" ? "trending" : "contract"))
  const currenctPage = location.pathname === "/prelaunch-calls" ? "prelaunch" : (location.pathname === "/token-trending" ? "trending" : (location.pathname === "/trending-contract" ? "contract" : "utility"));
  const [isSelectChain, setIsSelectChain] = useState({
    isOpen: false,
    current: "All Chains",
    value: ""
  });
  useEffect(() => {
    setSlectedContractAddress(address)

    function handleResize() {
      setIsMobileScreen(window.innerWidth < 768);
    }

    if (location.pathname.startsWith('/trending-contract')) {
      setIsTrendingToken(true)
      setCurrentPageName('trending_contract');
      getInfluencerList('trending_contract');
    } else if (location.pathname === "/prelaunch-calls") {
      setIsPreluanchCalls(true)
      setCurrentPageName('prelaunch_tag');
      getInfluencerList('prelaunch_tag');
    } else if (location.pathname === "/token-trending") {
      setIsTokenTrending(true)
      setCurrentPageName('dollar_tending');
      getInfluencerList('dollar_tending');
    } else if (location.pathname === "/utility-trending") {
      setIsUtilityTrending(true)
      setCurrentPageName('utility_trending');
      getInfluencerList('utility_trending');
    }

    // Initial check
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [cardType, setCardType] = useState("");

  const [activeIndex, setActiveIndex] = useState(0);
  const handleCardClick = (index) => {
    setActiveIndex(index); // Set the clicked card as active
    setClick(false);
    setClickedIndexs(null)
  };

  useEffect(() => {
    if (location.pathname == "/prelaunch-calls" || location.pathname == "/token-trending") {
      setDivSize("col-xl-9 col-lg-9 col-md-6")
      setDivSizeTagCrad("col-xl-4 col-lg-4 col-md-12")
    } else if (location.pathname == "/trending-contract") {
      setDivSize("col-xl-8 col-lg-8 col-md-6")
      setDivSizeTagCrad("col-xl-6 col-lg-6 col-md-12")
    }
  }, [location])

  const getInfluencerList = async (type, page = 1) => {
    try {
      const res = await _get(`/v1/user/get-influencer-list-by-call?type=${type}&page=${page}`, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (res.data) {
        if (res?.data?.data) {
          let data = res?.data?.data;
          //setInfluencerList(res.data.data)
          setInfluencerList((prev) => [...prev, ...data]);
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  useEffect(() => {
    if (socket && currentPageName) {
      socket.emit("get_page_chart_data", {
        page: currentPageName,
        limit: 20
      });
      socket.on("page_chart_data", (data) => {
        console.log("page_chart_data", data)
        setColumnChartData(data)
      });
    }
  }, [currentPageName, socket]);

  useEffect(() => {
    if (influencerDataPage > 1) {
      getInfluencerList(currentPageName, influencerDataPage)
    }
  }, [influencerDataPage]);

  const handleDisconnectWallet = async () => {
    disconnect();
    const referalCode = localStorage.getItem("referral_code");
    localStorage.clear();
    if (referalCode) {
      localStorage.setItem("referral_code", referalCode);
    }
    dispatch(resetAll());
    if (Web3Modal?.cachedProvider) {
      await Web3Modal?.clearCachedProvider();
    }

    if (!isConnected) {
      navigate("/");
    }
  };

  // firsttimechatmessage
  const firstCHatData = async (msg, data) => {
    setTokenLoader(true);
    setTokenLoader(false);
  };

  // getchannelList
  const channelList = async () => {
    try {
      const res = await _get("/v1/user/telegramChannels", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      // alert("1")
      if (res.data) {
        setAllChannelData(res.data.telegram_channels);
        //
        const selectedOptions = res?.data?.telegram_channels?.map((option) => ({
          value: option._id, // Use _id as the value
          label: option.channels[0]?.name, // Use channelUsername as the label
        }));

        const list = selectedOptions?.map((item) => item.value) || [];
        setListOptions(() => [...list]);
        const inputValue = "24";

        handleActivate("telegram", { selectedOptions, inputValue });

        firstCHatData("telegram", { selectedOptions, inputValue });
        return [...list];
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let resp = await channelList();
      if (socket) {
        socket.emit("tokenInfo", {
          type: "telegram",
          data: {
            list: resp,
            time: selectedTime,
            search: searchValue ? searchValue : "",
            page: currenctPage,
            selected_influencer_type: selectedInfluencerListOption,
            is_token_update_live: isTokenUpdateLive
          },
        });

        socket.emit("get_page_chart_data", {
          page: currentPageName,
          limit: 20,
          time: selectedTime,
          selected_influencer_type: selectedInfluencerListOption,
        });
      }
    };

    fetchData()
  }, [selectedTime, selectedInfluencerListOption, isTokenUpdateLive])

  const handleActivate = async (msg, data) => {
    setActiveLoader(true);
    const list = data?.selectedOptions?.map((item) => item.value) || [];
    if (socket) {
      socket?.emit("tokenInfo", {
        type: msg,
        data: {
          list: [...list || []],
          //time: Number(data.inputValue * 60)
          //time: selectList.inputValue,
          time: selectedTime,
          page: currenctPage,
          selected_influencer_type: selectedInfluencerListOption,
          is_token_update_live: isTokenUpdateLive
        },
      });
    }
  };

  const socketConnect = async () => {
    let resp = await channelList();
    setLoading(true);

    /* const socketInstance = io(url, {
      extraHeaders: {
        Authorization: `Bearer ${userData.jwtToken}`,
      },
    }); */

    const socketInstance = getSocket();
    setSocket(socketInstance);

    socketInstance.on("connect", () => { });

    socketInstance.on("disconnect", () => { });

    // Error handling
    socketInstance.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });

    socketInstance.on("error", (error) => {
      console.error("Socket error:", error);
    });
    if (socketInstance) {
      socketInstance.emit("tokenInfo", {
        type: "telegram",
        data: {
          list: resp,
          time: selectedTime,
          search: searchValue ? searchValue : "",
          page: currenctPage,
          selected_influencer_type: selectedInfluencerListOption,
          is_token_update_live: isTokenUpdateLive
        },
      });

      socketInstance.on("newToken", (data) => {
        setLoading(false);
        setNewTokenList(data);
      });
      socketInstance.on("trendingToken", (data) => {
        setLoading(false);
        setTredningTokenList(data);
      });
      socketInstance.on("preLaunch", (data) => {
        setLoading(false);
        setPreLaunchList(data);
      });
      socketInstance.on("utilityToken", (data) => {
        setLoading(false);
        setUtilityTokenList(data);
      });
      socketInstance.emit("tokenMessage", {
        data: {
          listType: "new",
          list: resp,
          time: selectedTime,
          search: searchValue ? searchValue : "",
          page: currenctPage,
          selected_influencer_type: selectedInfluencerListOption,
          is_token_update_live: isTokenUpdateLive
        },
      });
      socketInstance.on("newMessage", (data) => {
        setLoading(false);
        setChatData(data?.data);
      });
    }
  };

  useEffect(() => {
    if (socket == null) {
      socketConnect();
    }

    return () => {
      if (socket) {
        socket.off("newToken");
        socket.off("trendingToken");
        socket.off("preLaunch");
        socket.off("utilityToken");
        socket.off("newMessage");
      }
    };
  }, [socket]);

  /* useEffect(() => {
    if (isConnected) {
      if (chain.id !== 1) {
        handleDisconnectWallet();
      }
    }
  }, [isConnected, chain]); */

  useEffect(() => {
    if (activeClick) {
      setSearchValue("");
    }
  }, [activeClick]);
  // socket api call

  useEffect(() => {
    if (click) {
      setActiveIndex(2);
    }
  }, [click]);

  const clearData = async () => {
    //setDisplayChart(!displayChart)
    if (displayChart) {
      setDisplayChart(!displayChart)
    }
    setClick(false);
    setChatData([]);
    setSearchValue("")
    let resp = await channelList();
    if (socket) {
      socket.emit("tokenMessage", {
        data: {
          listType: "new",
          list: resp,
          time: selectedTime,
          search: searchValue ? searchValue : "",
          selected_influencer_type: selectedInfluencerListOption,
          is_token_update_live: isTokenUpdateLive
        },
      });
      setClickedIndexs(null)
    }
  }

  const closeButton = async () => {
    setClick(false)
    let resp = await channelList();
    if (socket) {
      socket.emit("tokenMessage", {
        data: {
          listType: "new",
          list: resp,
          time: selectedTime,
          search: searchValue ? searchValue : "",
          page: currenctPage,
          selected_influencer_type: selectedInfluencerListOption,
          is_token_update_live: isTokenUpdateLive
        },
      });
    }
  }

  return (
    <Layout>
      <div
        style={{
          fontSize: "28px",
          fontWeight: 600,
          lineHeight: "32.38px",
          color: " rgba(255, 255, 255, 1)",
        }}
      >
        {location.pathname == "/trending-contract" && <>Trending <MainDashboardText>Contract</MainDashboardText></>}
        {location.pathname == "/prelaunch-calls" && <>Prelaunch <MainDashboardText>Calls</MainDashboardText></>}
        {location.pathname == "/token-trending" && <>$Token <MainDashboardText>Trending</MainDashboardText></>}
      </div>
      {/* <button onClick={handleConnect}>Socket</button> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Filter
          activeLoader={activeLoader}
          setSelectList={setSelectList}
          activeClick={activeClick}
          setActiveClick={setActiveClick}
          activate={handleActivate}
          allChannelData={allChannelData}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          setSlectedContractAddress={setSlectedContractAddress}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setSelectedInfluencerListOption={setSelectedInfluencerListOption}
          isTokenUpdateLive={isTokenUpdateLive}
          setIsTokenUpdateLive={setIsTokenUpdateLive}
          isSelectChain={isSelectChain}
          setIsSelectChain={setIsSelectChain}
        />
        {/* <MultiSelectDropdown /> */}
        {click && location.pathname === "/trending-contract" ? (<>
          <StyledMainSectionWrapper>
            <div style={{ width: "100%" }}>
              <div style={{ float: "right" }}>
                <span style={{ color: 'white', marginRight: "10px" }}>{tokenNameDisplay}</span>
                <Button className="chart-close-btn" variant="primary" onClick={() => setDisplayChart(!displayChart)}>Chart</Button>
                <Button className="chart-close-btn" variant="primary" onClick={() => clearData()}>Close</Button>
              </div>
            </div>
          </StyledMainSectionWrapper>
        </>) : (<></>)}

        {!click && location.pathname === "/trending-contract" && <StyledMainSectionWrapper>
          <div style={{ width: "100%" }}>
            <div style={{ float: "right" }}>
              <Button className="chart-close-btn" variant="primary" onClick={() => setDisplayMainChart(!displayMainChart)}>Chart</Button>
            </div>
          </div>
        </StyledMainSectionWrapper>}

        {click && location.pathname === "/token-trending" ? (<>
          <StyledMainSectionWrapper>
            <div style={{ width: "100%" }}>
              <div style={{ float: "right" }}>
                <span style={{ color: 'white', marginRight: "10px" }}>{tokenNameDisplay}</span>
                <Button className="chart-close-btn" variant="primary" onClick={() => closeButton()}>Close</Button>
              </div>
            </div>
          </StyledMainSectionWrapper>
        </>) : (<></>)}

        {displayChart && isMobileScreen ? <Row style={{ color: 'white', marginBottom: "15px", paddingTop: "10px" }}>
          <div className="col-12">
            <div className="border-style" style={{ padding: '7px' }}>
              <CustomBarChart />
            </div>
          </div>
        </Row> : <></>}

        {displayMainChart && !click && isMobileScreen ? <Row style={{ color: 'white', paddingTop: "10px" }}>
          <div className="col-12">
            <div className="border-style" style={{ padding: '7px' }}>
              <CustomBarChart />
            </div>
          </div>
        </Row> : <></>}



        {/* Trending Card */}

        {isMobileScreen ? (
          <>
            <StyledMainSectionWrapper>
              <div className="tabs-container">
                <div
                  onClick={() => handleCardClick(0)}
                  className={`tab-cards ${activeIndex === 0 ? "active" : ""}`}
                >
                  {/* <img src="/trendingIcon.svg" alt="" /> */}
                  <span>
                    Trending Tokens
                  </span>
                </div>
                <div
                  onClick={() => handleCardClick(1)}
                  className={`tab-cards ${activeIndex === 1 ? "active" : ""}`}
                >
                  {/* <img src="/notification.svg" alt="" /> */}
                  <span>
                    Kols Feeds
                  </span>
                </div>
                <div
                  onClick={() => handleCardClick(2)}
                  className={`tab-cards ${activeIndex === 2 ? "active" : ""}`}
                >
                  {/* <img src="/infoIcon.svg" alt="" /> */}
                  <span>
                    Top active kols
                  </span>
                </div>
              </div>
              <div className="card-header">
                {activeIndex === 0 ? (
                  <div className="cards" style={{ color: "white" }}>
                    {
                      isTrendingToken ? (<NewTokenCards
                        setTrendingTokenCLick={setTrendingTokenCLick}
                        setPreTokenCLick={setPreTokenCLick}
                        preTokenClick={preTokenClick}
                        setNewTokenClick={setNewTokenClick}
                        trendingTokenClick={trendingTokenClick}
                        setCardType={setCardType}
                        socket={socket}
                        selectList={selectList}
                        name="Trending Contract"
                        data={newTokenList}
                        setChatData={setChatData}
                        setClick={setClick}
                        click={click}
                        setTokenIds={setTokenIds}
                        setTokenAddress={setTokenAddress}
                        setSelectedChain={setSelectedChain}
                        setTokenLoader={setTokenLoader}
                        trendingCardLoader={trendingCardLoader}
                        clickedIndexs={clickedIndexs}
                        setClickedIndexs={setClickedIndexs}
                        loader={loading}
                        slectedContractAddress={slectedContractAddress}
                        isTokenUpdateLive={isTokenUpdateLive}
                        setIsTokenUpdateLive={setIsTokenUpdateLive}
                        isSelectChain={isSelectChain}
                      />) : (<></>)
                    }

                    {
                      isPreluanchCalls ? (<PrelaunchCard
                        setNewTokenClick={setNewTokenClick}
                        setTrendingTokenCLick={setTrendingTokenCLick}
                        newTokenClick={newTokenClick}
                        trendingTokenClick={trendingTokenClick}
                        setPreTokenCLick={setPreTokenCLick}
                        setCardType={setCardType}
                        socket={socket}
                        selectList={selectList}
                        name="Prelaunch Calls"
                        data={preLaunchList}
                        setChatPrelaunchData={setChatPrelaunchData}
                        setTokenIds={setTokenIds}
                        setClick={setClick}
                        click={click}
                        setTokenLoader={setTokenLoader}
                        trendingCardLoader={trendingCardLoader}
                        selectedTime={selectedTime}
                        isTokenUpdateLive={isTokenUpdateLive}
                        setIsTokenUpdateLive={setIsTokenUpdateLive}
                        selectedInfluencerListOption={selectedInfluencerListOption}
                      />) : (<></>)
                    }

                    {
                      isTokenTrending ? (<TokenCards
                        setPreTokenCLick={setPreTokenCLick}
                        setNewTokenClick={setNewTokenClick}
                        preTokenClick={preTokenClick}
                        newTokenClick={newTokenClick}
                        setTrendingTokenCLick={setTrendingTokenCLick}
                        setCardType={setCardType}
                        socket={socket}
                        selectList={selectList}
                        name="$Token Trending"
                        data={trendingTokenList}
                        setChatTrendingData={setChatTrendingData}
                        setTokenIds={setTokenIds}
                        setClick={setClick}
                        setTokenAddress={setTokenAddress}
                        click={click}
                        setTokenLoader={setTokenLoader}
                        trendingCardLoader={trendingCardLoader}
                        selectedTime={selectedTime}
                        isTokenUpdateLive={isTokenUpdateLive}
                        setIsTokenUpdateLive={setIsTokenUpdateLive}
                        selectedInfluencerListOption={selectedInfluencerListOption}
                      />) : (<></>)
                    }
                    {
                      isUtilityTrending ? (<UtilityCard
                        setPreTokenCLick={setPreTokenCLick}
                        setNewTokenClick={setNewTokenClick}
                        preTokenClick={preTokenClick}
                        newTokenClick={newTokenClick}
                        setTrendingTokenCLick={setTrendingTokenCLick}
                        setCardType={setCardType}
                        socket={socket}
                        selectList={selectList}
                        name="Utility Trending"
                        data={utilityTokenList}
                        setChatTrendingData={setChatTrendingData}
                        setTokenIds={setTokenIds}
                        setClick={setClick}
                        click={click}
                        setTokenLoader={setTokenLoader}
                        setTokenAddress={setTokenAddress}
                        trendingCardLoader={trendingCardLoader}
                      />) : (<></>)
                    }
                  </div>
                ) : activeIndex === 1 ? (
                  <div className="cards" style={{ color: "white" }}>
                    {/* {cardType === "new" || cardType === "" ? ( */}
                    {isTrendingToken ? (
                      <TelegramChatScreen
                        cardType={cardType}
                        data={chatData.length > 0 ? chatData : firstChatArray}
                        tokenLoader={tokenLoader}
                      />
                    ) : (
                      <>
                        <div className="border-style" style={{ padding: '7px', marginBottom: "15px" }}>
                          <CustomBarChart data={columnChartData} />
                        </div>
                        <TrendingTokenChatCard
                          cardType={cardType}
                          data={
                            chatTrendingData.length > 0
                              ? chatTrendingData
                              : chatPrelaunchData.length > 0
                                ? chatPrelaunchData
                                : chatData.length > 0
                                  ? chatData
                                  : firstChatArray
                          }
                          tokenLoader={tokenLoader}
                        />
                      </>
                    )}
                  </div>
                ) : activeIndex === 2 ? (
                  <div className="cards token-info" style={{ color: "white" }}>
                    <TagCards
                      tokenIds={tokenIds}
                      tokenAddress={tokenAddress}
                      click={click}
                      setClick={setClick}
                      influencerData={influencerData}
                      setTokenNameDisplay={setTokenNameDisplay}
                      setInfluencerDataPage={setInfluencerDataPage}
                      socket={socket}
                    />
                  </div>
                ) : (
                  <div className="cards token-info" style={{ color: "white" }}>
                    <TagCards
                      tokenIds={tokenIds}
                      click={click}
                      setClick={setClick}
                      socket={socket}
                    />
                  </div>
                )}
              </div>
            </StyledMainSectionWrapper>
          </>
        ) : (
          <>
            <Row className="mt-2">


              {isTrendingToken ? (<>
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ color: "white" }}>
                  <NewTokenCards
                    setTrendingTokenCLick={setTrendingTokenCLick}
                    setPreTokenCLick={setPreTokenCLick}
                    preTokenClick={preTokenClick}
                    setNewTokenClick={setNewTokenClick}
                    trendingTokenClick={trendingTokenClick}
                    setCardType={setCardType}
                    socket={socket}
                    selectList={selectList}
                    name="Trending Contract"
                    data={newTokenList}
                    setChatData={setChatData}
                    setClick={setClick}
                    click={click}
                    setTokenIds={setTokenIds}
                    setTokenAddress={setTokenAddress}
                    setSelectedChain={setSelectedChain}
                    setTokenLoader={setTokenLoader}
                    trendingCardLoader={trendingCardLoader}
                    clickedIndexs={clickedIndexs}
                    setClickedIndexs={setClickedIndexs}
                    loader={loading}
                    slectedContractAddress={slectedContractAddress}
                    isTokenUpdateLive={isTokenUpdateLive}
                    setIsTokenUpdateLive={setIsTokenUpdateLive}
                    isSelectChain={isSelectChain}
                  /></div>
              </>) : (<></>)}

              {isPreluanchCalls ? (<>
                <div className="col-xl-3 col-lg-3 col-md-6" style={{ color: "white" }}>
                  <PrelaunchCard
                    setNewTokenClick={setNewTokenClick}
                    setTrendingTokenCLick={setTrendingTokenCLick}
                    newTokenClick={newTokenClick}
                    trendingTokenClick={trendingTokenClick}
                    setPreTokenCLick={setPreTokenCLick}
                    setCardType={setCardType}
                    socket={socket}
                    selectList={selectList}
                    name="Prelaunch Calls"
                    data={preLaunchList}
                    setChatPrelaunchData={setChatPrelaunchData}
                    setTokenIds={setTokenIds}
                    setClick={setClick}
                    click={click}
                    setTokenLoader={setTokenLoader}
                    trendingCardLoader={trendingCardLoader}
                    isTokenUpdateLive={isTokenUpdateLive}
                    setIsTokenUpdateLive={setIsTokenUpdateLive}
                  />
                </div>
              </>) : (<></>)}

              {isTokenTrending ? (<>
                <div className="col-xl-3 col-lg-3 col-md-6" style={{ color: "white" }}>
                  <TokenCards
                    setPreTokenCLick={setPreTokenCLick}
                    setNewTokenClick={setNewTokenClick}
                    preTokenClick={preTokenClick}
                    newTokenClick={newTokenClick}
                    setTrendingTokenCLick={setTrendingTokenCLick}
                    setCardType={setCardType}
                    socket={socket}
                    selectList={selectList}
                    name="$Token Trending"
                    data={trendingTokenList}
                    setTokenAddress={setTokenAddress}
                    setChatTrendingData={setChatTrendingData}
                    setTokenIds={setTokenIds}
                    setClick={setClick}
                    click={click}
                    setTokenLoader={setTokenLoader}
                    trendingCardLoader={trendingCardLoader}
                    isTokenUpdateLive={isTokenUpdateLive}
                    setIsTokenUpdateLive={setIsTokenUpdateLive}
                  />
                </div>
              </>) : (<></>)}

              {/* {
                isUtilityTrending ? (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-6" style={{ color: "white" }}>
                      <UtilityCard
                        setPreTokenCLick={setPreTokenCLick}
                        setNewTokenClick={setNewTokenClick}
                        preTokenClick={preTokenClick}
                        newTokenClick={newTokenClick}
                        setTrendingTokenCLick={setTrendingTokenCLick}
                        setCardType={setCardType}
                        socket={socket}
                        selectList={selectList}
                        name="Utility Trending"
                        data={utilityTokenList}
                        setChatTrendingData={setChatTrendingData}
                        setTokenIds={setTokenIds}
                        setClick={setClick}
                        click={click}
                        setTokenLoader={setTokenLoader}
                        trendingCardLoader={trendingCardLoader}
                      />
                    </div>
                  </>) : (<></>)
              } */}

              <div className={divSize}>
                {displayChart ? <Row style={{ color: 'white', marginBottom: "15px" }}>
                  <div className="col-12">
                    <div className="border-style" style={{ padding: '7px' }}>
                      {/* <CustomBarChart /> */}
                      {/* <iframe id="dextools-widget"
                        title="DEXTools Trading Chart"
                        sandbox="allow-same-origin allow-scripts"
                        style={{ width: '100%', height: '400px' }}
                        src={`https://www.dextools.io/widget-chart/en/${selectedChain === 'ethereum' ? 'ether' : selectedChain}/pe-light/${tokenAddress}?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false`}
                      ></iframe> */}
                      <iframe id="dexscreener-embed"
                        title="Dexscreener Trading Chart"
                        sandbox="allow-same-origin allow-scripts"
                        style={{ width: '100%', height: '400px' }}
                        src={`https://dexscreener.com/${selectedChain}/${tokenAddress}?embed=1&loadChartSettings=0&trades=0&info=0&chartLeftToolbar=0&chartTheme=dark&theme=dark&chartStyle=0&chartType=usd&interval=15`}
                      ></iframe>
                    </div>
                  </div>
                </Row> : <></>}

                {displayMainChart && !click ? <Row style={{ color: 'white' }}>
                  <div className="col-12">
                    <div className="border-style" style={{ padding: '7px' }}>
                      <CustomBarChart data={columnChartData} />
                    </div>
                  </div>
                </Row> : <></>}

                <Row className={displayMainChart ? "pt-3" : ""}>

                  {isTrendingToken ? (
                    <>
                      <div className="col-xl-6 col-lg-6 col-md-12" style={{ color: "white" }}>
                        <TelegramChatScreen
                          cardType={cardType}
                          data={chatData.length > 0 ? chatData : firstChatArray}
                          tokenLoader={tokenLoader}
                        />
                      </div></>
                  ) : (
                    <>
                      <div className="col-xl-8 col-lg-8 col-md-12" style={{ color: "white" }}>
                        <div className="border-style" style={{ padding: '7px', marginBottom: "15px" }}>
                          <CustomBarChart data={columnChartData} />
                        </div>
                        <TrendingTokenChatCard
                          cardType={cardType}
                          data={
                            chatTrendingData.length > 0
                              ? chatTrendingData
                              : chatPrelaunchData.length > 0
                                ? chatPrelaunchData
                                : chatData.length > 0
                                  ? chatData
                                  : firstChatArray
                          }
                          tokenLoader={tokenLoader}
                        />
                      </div>
                    </>
                  )}

                  <div className={divSizeTagCrad} style={{ color: "white" }}>
                    <TagCards
                      tokenIds={tokenIds}
                      tokenAddress={tokenAddress}
                      click={click}
                      setClick={setClick}
                      influencerData={influencerData}
                      setTokenNameDisplay={setTokenNameDisplay}
                      setInfluencerDataPage={setInfluencerDataPage}
                      socket={socket}
                    />
                  </div>
                </Row>
              </div>
            </Row>
          </>
        )}
      </div>
    </Layout >
  );
};

export default MainSection;
