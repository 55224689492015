import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

function MyAddChannelModal(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body
        style={{
          background: "#272727",
          fontSize: "20px",
          fontFamily: "Plus Jakarta Sans",
        }}
      >
        <h4 style={{ color: "#fff", textAlign: "center" }}>
          Add Channel/Groups
        </h4>
        <Form style={{ marginTop: "10px" }}>
          <Form.Check
            style={{ color: "#fff" }}
            inline
            label="Channel"
            name="group1"
            type="radio"
            id="Channel"
            value="Channel"
            checked={props.selectedOption === "Channel"}
            onChange={props.handleChange}
          />
          {/* <Form.Check
            style={{ color: "#fff" }}
            inline
            label="Group"
            name="group2"
            type="radio"
            id="Group"
            value="Group"
            checked={props.selectedOption === "Group"}
            onChange={props.handleChange}
          /> */}

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ color: "#fff", marginTop: "20px" }}>
              {props.selectedOption === "Channel" ? "User Name" : "Group Id"}
            </Form.Label>
            <Form.Control
              type="text"
              name="channelName"
              value={props.channelName}
              onChange={props.handleChange}
              placeholder={
                props.selectedOption === "Channel"
                  ? " Enter User Name"
                  : "Enter Group Id"
              }
            />
          </Form.Group>
        </Form>
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {props.addLoader ? (
            <Spinner
              animation="border"
              style={{ height: "50px", width: "50px", color: "#fff" }}
            />
          ) : (
            <>
              <Button
                onClick={props.onHide}
                style={{ background: "#ffff", color: "#000", width: "150px" }}
              >
                Close
              </Button>
              <Button onClick={props.addChannel} style={{ width: "150px" }}>
                Save
              </Button>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default MyAddChannelModal;
