import styled from "styled-components";

export const SideBarStyled = styled.div`
  // width: 238px;
  // width:17.5vw;
  width: 16rem;
  height: 100vh;
  background-color: #171721;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  position: fixed;
  cursor: pointer;
  font-family: Plus Jakarta Sans;

  z-index: 10;
  top: 0;
  left: 0;
`;

export const HeaderStyled = styled.div`
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  position: fixed;
  z-index: 1;
  // width: 1042px;
  // width:81.5vw;
  width: 100%;
  //height: 94px;
  background-color: #171721;
  // margin-left: 238px;
  padding-left: 5rem;

  .menu {
    margin-left: 30px;
    display: flex;
    gap: 30px;
  }
`;

export const StyledDrawerContainer = styled.div`
  .ant-drawer-content-wrapper {
    width: auto !important;
  }
`;
