import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import DashBoardHeader from "../../components/dashBoardHeader/DashBoardHeader";
import Common from "../../components/commonContent/Common";
import { TokenTable } from "../../components/DexScreener/TokenTable";
import { HeadTabs } from "../../components/DexScreener/HeadTabs";
import { _get, _post } from "../../api/ApiClient";

const DexScreen = () => {
  const userData = useSelector((state) => state.ROOT);
  const [tokenList, setTokenList] = useState([]);
  const [chainList, setChainList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedChain, setSelectedChain] = useState('all');
  const [showLoader, setShowLoader] = useState(false);

  const getNewTokenList = async () => {
    try {
      setShowLoader(true);
      let payload = {
        page: currentPage,
        pageSize: pageSize
      }
      if (selectedChain != 'all') {
        payload['chain'] = selectedChain
      }
      const resp = await _post("/v1/token/new-pair", payload, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      setShowLoader(false);
      if (resp.data) {
        setTokenList(resp?.data?.token_list)
        setChainList(resp?.data?.chain_list)
        setTotalRecords(resp?.data?.total_tokens)
      }
    } catch (err) {
      console.log("error", err);
      setShowLoader(false);
    }
  }

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleChainChange = (chain) => {
    setCurrentPage(1);
    setSelectedChain(chain);
  };

  useEffect(() => {
    getNewTokenList()
  }, [currentPage, pageSize, selectedChain])

  useEffect(() => {
    getNewTokenList()
  }, [])

  return (
    <>
      <DashBoardHeader />
      <Common>
        <div
          style={{
            width: "100%",
            height: "100vh",
            padding: "110px 20px 20px",
            /* background: "#27292A", */
          }}
        >
          <HeadTabs
            chainList={chainList}
            handleChainChange={handleChainChange}
          />
          {showLoader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                top: 150,
              }}
            >
              <Spinner
                animation="border"
                style={{ height: "50px", width: "50px", color: '#FFF' }}
              />
            </div>
          ) : (
            <TokenTable
              tokenList={tokenList}
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </Common>
    </>
  );
};

export default DexScreen;
