import styled from "styled-components";

export const StyledParagraph = styled.div`
  font-size: 12px;
  font-family: "Plus Jakarta", sans-serif;

  

  .dot{
    border-radius: 50%;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-left: 5px;    
    &.red {
      background-color: red;
    }
    &.green {
      background-color: green;
    }
    &.yellow {
      background-color: yellow;
    }
  }
`;
