import { Apis } from "./api";

export const authApi = Apis.injectEndpoints({
  endpoints: (builder) => ({
    addInfluencer: builder.mutation({
      query: ({ body }) => ({
        url: "/v1/user/addInfluncer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InfluencerList"],
    }),
    updateInfluencer: builder.mutation({
      query: ({ channelId, body }) => ({
        url: `/v1/admin/editInfluncer?channelId=${channelId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["InfluencerList"],
    }),
    getInfluencerList: builder.query({
      query: ({ page_size, page, query, platform, follow_status }) => ({
        url: "/v1/user/getInfluncerList",
        params: { page_size, page, query, platform, follow_status },
      }),
      providesTags: ["InfluencerList"],
    }),
    addChannel: builder.mutation({
      query: ({ body }) => ({
        url: "/v1/user/addChannel",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InfluencerList"],
    }),
    unfollowChannel: builder.mutation({
      query: ({ body }) => ({
        url: "/v1/user/unfollow",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InfluencerList"],
    }),
    followUnfollowChannel: builder.mutation({
      query: ({ body }) => ({
        url: "/v1/user/follow-unfollow",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InfluencerList"],
    }),
    overrideExisting: false,
  }),
});

export const {
  useAddInfluencerMutation,
  useAddChannelMutation,
  useUnfollowChannelMutation,
  useGetInfluencerListQuery,
  useUpdateInfluencerMutation,
  useFollowUnfollowChannelMutation,
} = authApi;
