import React from "react";
import DashBoardHeader from "../../components/dashBoardHeader/DashBoardHeader";
import Common from "../../components/commonContent/Common";
import DropdownBtn from "../../components/Buttons/Dropdown";
import TelegramListCard from "../../components/Cards/TelegramCards";
import ApplyBtn from "../../components/Buttons/ApplyBtn/Applybtn";
import Layout from "../../components/layout/Layout";

const AddPages = () => {
  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-around",
            width: "100%",
          }}
        >
          <TelegramListCard />
        </div>
      </Layout>
    </>
  );
};

export default AddPages;
