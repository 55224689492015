import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import { _get, _post } from "../../api/ApiClient";
import {
  chainLogo, twoDigitNumber, timeAgo, formatString, formatNumber, platformLogo,
  handleCopy, chainLinkforDextool
} from "../commonFunction/commonFunction"
import { getSocket } from "../../socket";
import { Button, Row } from "react-bootstrap";
import defaultProfile from "../../assets/images/profile.png"
import followers from "../../assets/images/followers.svg"
import telegram from "../../assets/images/telegram_influence.svg"
import ethere from "../../assets/images/ether_influence.svg"
import solana from "../../assets/images/solana.svg"
import sui from "../../assets/images/sui.svg"
import AreaChart from "../../pages/MainDashboard/AreaChart"
import BarChart from "../Chart/BarChart"
import { Spinner } from "react-bootstrap";
import copy from "../../assets/images/copy.svg";
import dexTool from "../../assets/images/dextools_logo.png";
const InfluencerDetails = () => {
  const optionList = [
    {
      value: "success_calls",
      label: "Success Calls",
    },
    {
      value: "live_calls",
      label: "Live Calls"
    },
  ];
  const userData = useSelector((state) => state.ROOT);
  const { influencer_id } = useParams()
  const [selectedOption, setSelectedOption] = useState(optionList[0]);
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [successCallsData, setSuccessCallsData] = useState({});
  const [liveCallsData, setLiveCallsData] = useState([]);
  const [activeButton, setActiveButton] = useState("5min");
  const [chartData, setChartData] = useState(null);
  const [chartTitle, setChartTitle] = useState("Activity");

  const handleChange = (selected) => {
    setSelectedOption(selected);
    if (selected.value == 'success_calls') {
      getSuccessCalls()
    }
  };

  const getEmoji = (num) => {
    let emoji = ""
    if (num > 10) {
      let count = Math.ceil(num / 50);
      emoji = '🚀'.repeat(count)
    } else if (num > 2) {
      emoji = "😀"
    } else {
      emoji = "😑"
    }
    return emoji
  }

  const getInfluencerDetails = async () => {
    try {
      const res = await _post("/v1/user/get-chart-data", { influencer_id: influencer_id }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (res.data) {
        if (res.data.data) {
          setInfluencerDetail(res?.data?.data)
          setChartData(res?.data?.data?.five_min_chart_data)
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  const getSuccessCalls = async () => {
    try {
      const res = await _get("/v1/user/get-success-calls?influencer_id=" + influencer_id, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (res.data) {
        if (res.data.data) {
          setSuccessCallsData(res.data.data)
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  const initSocket = async () => {
    const socket = getSocket();

    if (socket) {
      socket?.emit("join_detail_room", influencer_id);
      socket.on("live_calls", (data) => {
        setLiveCallsData(data)
      });
      return () => {
        socket?.emit("leave_detail_room", influencer_id);
        socket.off("live_calls");
      };
    }
  }

  useEffect(() => {
    initSocket();
    getInfluencerDetails()
    getSuccessCalls()
  }, [])

  const data = {
    text: "Monthly Revenue Growth",
    categories: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    series: [
      {
        name: '2023',
        data: [5000, 7000, 8000, 12000, 15000, 18000, 22000, 25000, 20000, 30000, 40000, 50000],
        color: '#7cb5ec',
      }
    ],
  }
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType == "5min") {
      setChartTitle("5m Volume Impact")
      setChartData(influencerDetail?.five_min_chart_data)
    } else if (buttonType == "1hr") {
      setChartTitle("1hr Volume Impact")
      setChartData(influencerDetail?.chart_data)
    } else {
      setChartTitle("Activity")
      setChartData({ categories: [], series: [] })
    }
  };

  const createInfluenecrSocialLink = (data) => {
    if (data?.profile_link) {
      return data?.profile_link
    } else if (data?.social_username) {
      let profileLink = ""
      if (data?.platform == 'tweet' || data?.platform == 'x.com') {
        profileLink = `https://x.com/${data?.social_username}`
      } else if (data?.platform == 'youtube-video') {
        profileLink = `https://www.youtube.com/@${data?.social_username}`
      } else if (data?.platform == 'reddit-post') {
        profileLink = `https://www.reddit.com/user/${data?.social_username}`
      } else if (data?.platform == 'tiktok-video') {
        profileLink = `https://www.tiktok.com/@${data?.social_username}`
      }
      return profileLink
    } else if (data?.platform == 'telegram') {
      return `https://t.me/${data?.telegramUserName}`
    }
  }
  return (
    <Layout>
      {/* <h4 style={{ color: "white" }}>Influencer Details</h4> */}
      <span style={{ fontSize: "28px", color: "white" }}>Influencer <span className="font-color">Details</span></span>
      <Row style={{ color: "white" }}>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="border-style">
            <div className="text-center influencer-detail-bottom-border center-class" style={{ padding: "10px" }}>
              <img /* src={influencerDetail?.influencer?.platform == 'telegram' ? defaultProfile : influencerDetail?.influencer?.profile} */
                src={influencerDetail?.influencer?.profile} alt="" height={105} width={105} style={{ borderRadius: "50%" }} />
              <div className="center-class">
                <span style={{ fontSize: "30px", marginLeft: "10px" }}>{influencerDetail?.influencer?.influncerName}</span>
                <a target="_balnk" href={createInfluenecrSocialLink(influencerDetail?.influencer)} style={{ marginLeft: "5px" }}>
                  <img
                    src={platformLogo[influencerDetail?.influencer?.platform]}
                    alt=""
                    height={20}
                    width={20}
                  />
                </a>
              </div>
            </div>
            <div>
              <div style={{ padding: "13px" }} className="influencer-detail-bottom-border">
                <div style={{ display: "inline-block" }}>
                  <img src={telegram} alt="" height={30} width={30} />
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>
                    {influencerDetail?.influencer?.formated_platform}
                  </span>
                </div>
                <span style={{ float: "right", fontSize: "20px" }} className="font-color">
                  {formatNumber(influencerDetail?.statatics?.total_messages)}
                </span>
              </div>
              <div style={{ padding: "13px" }} className="influencer-detail-bottom-border">
                <div style={{ display: "inline-block" }}>
                  <img src={followers} alt="" height={30} width={30} />
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>Contract Share</span>
                </div>
                <span style={{ float: "right", fontSize: "20px" }} className="font-color">
                  {formatNumber(influencerDetail?.statatics?.contracts)}
                </span>
              </div>
              <div style={{ padding: "13px" }} className="influencer-detail-bottom-border">
                <div style={{ display: "inline-block" }}>
                  <img src={followers} alt="" height={30} width={30} />
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>Presale Share</span>
                </div>
                <span style={{ float: "right", fontSize: "20px" }} className="font-color">
                  {formatNumber(influencerDetail?.statatics?.presell)}
                </span>
              </div>
              <div style={{ padding: "13px" }} className="influencer-detail-bottom-border">
                <div style={{ display: "inline-block" }}>
                  <img src={followers} alt="" height={30} width={30} />
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>AiDrop Share</span>
                </div>
                <span style={{ float: "right", fontSize: "20px" }} className="font-color">
                  {formatNumber(influencerDetail?.statatics?.airdrop)}
                </span>
              </div>
              <div style={{ padding: "13px" }}>
                <div style={{ display: "inline-block" }}>
                  <img src={followers} alt="" height={30} width={30} />
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>$Token Share</span>
                </div>
                <span style={{ float: "right", fontSize: "20px" }} className="font-color">
                  {formatNumber(influencerDetail?.statatics?.token)}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="border-style mt-4">
            <div style={{ padding: "10px" }}>
              <h4 className="font-color">SCORE</h4>
              <h1>3980</h1>
              <ProgressBar now={100} label={100} />
              <div className="mt-2">
                <span >Level: <span style={{ color: "#1BEB00" }}>5.Supreme</span></span>
              </div>
            </div>
          </div> */}
          <div className="border-style mt-4">
            <div style={{ padding: "10px" }}>
              <h4>Success Ratio</h4>

              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <img src={ethere} alt="" height={30} width={30} />
                <ProgressBar style={{ height: "5px", width: "100%", marginLeft: "15px" }}>
                  <ProgressBar variant="success" now={35} key={1} />
                  <ProgressBar variant="warning" now={35} key={2} />
                  <ProgressBar variant="danger" now={30} key={3} />
                </ProgressBar>
              </div>
              <div style={{ display: "flex", alignItems: "center" }} className="mt-2">
                <img src={solana} alt="" height={30} width={30} />
                <ProgressBar style={{ height: "5px", width: "100%", marginLeft: "15px" }}>
                  <ProgressBar variant="success" now={35} key={1} />
                  <ProgressBar variant="warning" now={35} key={2} />
                  <ProgressBar variant="danger" now={30} key={3} />
                </ProgressBar>
              </div>
              <div style={{ display: "flex", alignItems: "center" }} className="mt-2">
                <img src={sui} alt="" height={30} width={30} />
                <ProgressBar style={{ height: "5px", width: "100%", marginLeft: "15px" }}>
                  <ProgressBar variant="success" now={35} key={1} />
                  <ProgressBar variant="warning" now={35} key={2} />
                  <ProgressBar variant="danger" now={30} key={3} />
                </ProgressBar>
              </div> */}
              {influencerDetail?.success_ratio?.map((item, index) => (
                <div style={{ display: "flex", alignItems: "center", marginTop: "7px" }} key={index}>
                  <img src={chainLogo[item.chain]} alt="" height={30} width={30} />
                  <ProgressBar now={item.percentage} label={`${twoDigitNumber(item.percentage, 2)}%`} style={{ width: "100%", marginLeft: "15px" }} />
                </div>
              ))}
            </div>

          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12 margin-top-cls">
          <Row>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button variant="primary" className={`dashboard-btn ${activeButton === "default" ? "active" : ""}`}
                onClick={() => handleButtonClick("default")}>Activity</Button>
              <Button variant="primary" className={`dashboard-btn ${activeButton === "5min" ? "active" : ""}`} style={{ marginLeft: "10px" }}
                onClick={() => handleButtonClick("5min")}>5Min Vol</Button>
              <Button variant="primary" className={`dashboard-btn ${activeButton === "1hr" ? "active" : ""}`} style={{ marginLeft: "10px" }}
                onClick={() => handleButtonClick("1hr")}>1Hr Vol</Button>
            </div>
          </Row>
          <Row>
            {!influencerDetail && <div className="text-center" style={{ padding: "100px" }}>
              <Spinner animation="border" style={{ height: "50px", width: "50px", color: "white" }} />
            </div>}
            {/* {activeButton === "5min" && <AreaChart data={influencerDetail?.five_min_chart_data} title={chartTitle} />} */}
            {/* {activeButton === "1hr" && <AreaChart data={influencerDetail?.chart_data} title={chartTitle} />} */}
            {/* {activeButton === "default" && <AreaChart data={{ categories: [], series: [] }} title={chartTitle} />} */}
            {activeButton === "5min" && <BarChart data={influencerDetail?.five_min_chart_data} title={chartTitle} />}
            {activeButton === "1hr" && <BarChart data={influencerDetail?.chart_data} title={chartTitle} />}
            {activeButton === "default" && <BarChart data={influencerDetail?.activity_chart_data} title={chartTitle} />}
            {/* {chartData ? <AreaChart data={chartData} title={chartTitle} /> :
              <div className="text-center" style={{ padding: "100px" }}>
                <Spinner animation="border" style={{ height: "50px", width: "50px", color: "white" }} />
              </div>} */}
          </Row>

          <div className="border-style-background">
            <div style={{ background: "#132940", borderRadius: "15px" }}>
              <div style={{ padding: "5px" }}>
                <span style={{ fontSize: "25px" }}>Current Message</span>
              </div>
              <div className="trending-contract-table-scroll influencer_current" style={{ borderRadius: "15px" }}>
                <Table responsive className="text-start main-dashboard-table dashboard-table-common">
                  <tbody>
                    {liveCallsData.map((token, index) => (
                      <tr className="text-center" key={index}>
                        <td style={{ verticalAlign: "middle", width: "10%" }}>
                          <img
                            src={chainLogo[token.chain]}
                            alt="Profile icon"
                            width={44}
                            height={44}
                          />
                        </td>
                        <td style={{ verticalAlign: "middle", width: "20%" }}>
                          <span>{token.channel_name}</span><br />
                          <span style={{ color: "#d5d5d5", fontSize: "14px" }}>Channel - {formatString(token?.contract_address)}</span>
                        </td>
                        <td style={{ verticalAlign: "middle", width: "20%" }}>
                          {/* Creator Name */}
                          {token.channel_name}
                        </td>
                        <td className="text-start" style={{ width: "40%" }}>
                          <a href={token.msg_url} target="blank" className="text-msg-inflencer">{token.message_text}</a>
                        </td>
                        <td style={{ verticalAlign: "middle", width: "10%" }}>
                          <span style={{ color: "#d5d5d5", fontSize: "12px" }}>{timeAgo(token.createdAt)}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

          </div>

        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          {/* <div className="border-style">
            <div style={{ padding: "10px" }}>
              <span style={{ fontSize: "25px" }}>Recent <span className="font-color">Tokens</span></span>
              <div className="mt-2">
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
                <img src={ethere} alt="" width={45} height={45} style={{ padding: "5px" }} />
              </div>
            </div>
          </div> */}
          {/* <div className="border-style-background mt-4">
            <div style={{ background: "#132940", borderRadius: "15px" }}>
              <div style={{ padding: "5px" }}>
                <span style={{ fontSize: "25px" }}>Top Signals</span>
              </div>
              <div className="trending-contract-table-scroll influencer_success" style={{ borderRadius: "15px" }}>
                <Table responsive className="text-start main-dashboard-table dashboard-table-common">
                  <tbody>
                    {Object.keys(successCallsData).map((key) => (
                      <tr>
                        <td width="20%" className="text-center" style={{ background: "#151515" }}>
                          <img
                            src={chainLogo[successCallsData[key].tokenDetails.chain]}
                            alt="Profile icon"
                            width={44}
                            height={44}
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td width="60%" style={{ background: "#151515" }}>
                          <span>{twoDigitNumber(successCallsData[key].performance)}x{getEmoji(twoDigitNumber(successCallsData[key].performance))}</span><br />
                          <span>{successCallsData[key].tokenDetails.name}</span>
                        </td>
                        <td width="20%" style={{ background: "#151515" }}>
                          <span style={{ color: "#d5d5d5", fontWeight: "600" }}>{successCallsData[key].tokenDetails.symbol}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div> */}
          <div>
            <h3>Top Signals</h3>
            <div className="trending-contract-table-scroll influencer_success">
              {Object.keys(successCallsData).map((key, index) => (
                <div className="background-inflencer-detail" style={{ background: "url('/background_inflenecr.svg')" }} key={index}>
                  <div style={{ padding: "10px", display: "flex" }}>
                    <div style={{ width: "70%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img className="round-img" src={successCallsData[key].tokenDetails?.logo ? successCallsData[key].tokenDetails?.logo : chainLogo[successCallsData[key].tokenDetails?.chain]}
                          width={56} height={56} alt="token_icon" />
                        <span style={{ marginLeft: "5px", fontSize: "25px" }}>{successCallsData[key].tokenDetails?.symbol}</span>
                      </div>
                      <div>
                        <span style={{ fontSize: "15px" }}>{successCallsData[key].tokenDetails?.name}</span>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="font-color" style={{ fontSize: "25px", fontWeight: "600" }}>{twoDigitNumber(successCallsData[key].performance)}x</span>
                      </div>
                      <div className="center-class">
                        <span style={{ marginRight: "5px" }}>CA -</span>
                        <img className="c-point" src={copy} width={25} height={25} onClick={() => handleCopy(successCallsData[key]?.tokenDetails?.address)} />
                        <a target="_balnk" href={chainLinkforDextool[successCallsData[key]?.tokenDetails?.chain] + successCallsData[key]?.tokenDetails?.address}
                          title="Dextool" rel="noopener noreferrer">
                          <img
                            src={dexTool}
                            alt=""
                            height={26}
                            width={26}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="background-inflencer-detail" style={{ background: "url('/background_inflenecr.svg')" }}>
              <div style={{ padding: "10px", display: "flex" }}>
                <div style={{ width: "70%" }}>
                  <div>
                    <img src={ethere} width={56} height={56} alt="token_icon" />
                    <span style={{ marginLeft: "5px" }}>Sharp Ai</span>
                  </div>
                  <div>
                    <span>Sharp Ai Name</span>
                  </div>
                </div>
                <div style={{ width: "30%" }}>
                  <span className="font-color" style={{ fontSize: "50px", fontWeight: "600" }}>220</span>
                </div>

              </div>

            </div> */}
            </div>
          </div>

        </div>
      </Row>
    </Layout>
  );
};

export default InfluencerDetails;
