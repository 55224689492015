import React, { Profiler } from "react";
import DashBoardHeader from "../dashBoardHeader/DashBoardHeader";
import Common from "../commonContent/Common";

const Layout = ({ children }) => {
  return (
    <>
      <DashBoardHeader />
      <Common>
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            background: '#171721',
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div
            style={{
              background: "url('/curve.svg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              padding: "20px",
              paddingTop: "100px"
            }}
          >
            {children}
          </div>
        </div>
      </Common>
    </>
  );
};

export default Layout;
