import React from "react";
import { useSelector } from "react-redux";

const Common = ({ children }) => {
  const data = useSelector((state) => state.ROOT);

  return (
    <div
      style={{
        /* background: "#272727", */
        paddingLeft: data.isSideBarVisible && !data.isMobile ? "0" : "5rem", // Corrected line
        transition: "padding-left 0.5s ease-out", // Smooth transition when sidebar is toggled
        flexGrow: "1",
        height: "100vh",
      }}
    >
      <div style={{ position: "relative"}}>{children}</div>
    </div>
  );
};

export default Common;
