import styled from "styled-components";
export const TelegramCardStyle = styled.div`
  width: 350px;
  //height: 420px;
  font-family: Plus Jakarta Sans;

  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
  //background: rgba(21, 22, 24, 1);
  //margin-top: 10px;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  a {
    color: white;
  }
  a:hover {
    color: blue;
  }
`;

export const StyledManageWrapper = styled.div`
  @media screen and (max-width: 650px) {
    .card-container {
      flex-direction: column;
    }
    .telegram-card {
      width: 100% !important;
    }
  }
`;
