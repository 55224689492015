import React from "react";
import styled from "styled-components";
import SpaceImg from "../../assets/images/space-img.png";
import WebsiteIcon from "../../assets/images/website-icon.png";
import TwitterIcon from "../../assets/images/twitter-icon.png";
import TelegramIcon from "../../assets/images/telegram-icon.png";
import WatchListIcon from "../../assets/images/watchlist-icon.png";
import AlertIcon from "../../assets/images/alert-icon.png";

import TeligramLogo from "../../assets/images/teligram.png";
import TwitterLogo from "../../assets/images/x.png";

const DisplayBox = styled.div`
  padding: 5px 10px;
  border: 1px solid #29292b;
  border-radius: 6px;
  background-color: #0d0d0d;
  text-align: center;
  margin-top:10px;
  width: 100%;
  margin-left: 10px;
`;

export const TokenDetailsInfo = () => {
  return (
    <div>
      <div className="xpositon-token-deatils">
        <div className="border-class-01">
          <div className="d-flex justify-content-center">
            <h5 className="text-white mt-2">$BOON / WETH </h5>
          </div>
          <div className="d-flex justify-content-center token-brand-name">
            <div>Base</div>
            <div>&nbsp;{">"}&nbsp;</div>
            <div>SushiSwap</div>
          </div>
          <div className="mt-3" style={{maxWidth: "100%", width: "100%", padding: "10px", display: "flex", justifyContent: "center"}}> 
            <img src={SpaceImg} alt="" />
          </div>
          {/* <div className='hgytk-458'>
                    <div className='website-box'>
                        <div className='d-flex justify-content-evenly'>
                            <div><img src={WebsiteIcon} alt="website icon" /> Website |</div>
                            <div><img src={TwitterIcon} alt="twitter icon" /> Twitter |</div>
                            <div><img src={TelegramIcon} alt="telegram icon" /> Telegram</div>
                        </div>
                    </div>
                </div> */}

          <div>
            <div className="d-flex justify-content-center mt-2" style={{ gap: "20px" }}>
              <div className="text-white">Socials :- </div>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <a href="#">
                    <img src={TeligramLogo} alt="" width={25} />
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={TwitterLogo} alt="" width={25} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="dkfjfdef"
            style={{ height: "62vh", overflow: "auto", width: "95%",padding:"10px",scrollbarWidth: "thin" }}
          >
            <div className=" mt-3" style={{ width: "96%", }}>
              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Price</span> <br />
                    <span className="price-tag-01">$0.0 5 1807</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Market Cap</span> <br />
                    <span className="price-tag-01">-</span>
                  </DisplayBox>
              </div>

              <div className="d-flex justify-content-around">
                <DisplayBox>
                <span className="name-tag-01">5m</span> <br />
                  <span className="price-tag-01">$ 0.00</span>
                </DisplayBox>
                <DisplayBox>
                <span className="name-tag-01">1h</span> <br />
                  <span className="price-tag-01">$ 0.00</span>
                </DisplayBox>
                <DisplayBox>
                <span className="name-tag-01">6h</span> <br />
                  <span className="price-tag-01">$ 0.00</span>
                </DisplayBox>
                <DisplayBox>
                <span className="name-tag-01">24h</span> <br />
                  <span className="price-tag-01">$ 0.00</span>
                </DisplayBox>
              </div>

              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Liquidity</span> <br />
                    <span className="price-tag-01">$ 21439.13</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Liquidity Burnt</span> <br />
                    <span className="price-tag-01">$ 21439.13</span>
                  </DisplayBox>
              </div>

              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Vol(24h)</span> <br />
                    <span className="price-tag-01">279867.22</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">ATH</span> <br />
                    <span className="price-tag-01">-</span>
                  </DisplayBox>
              </div>

              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">LP Lock</span> <br />
                    <span className="price-tag-01">279867.22</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Buy/Sell Tax</span> <br />
                    <span className="price-tag-01">-/-</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Swappable</span> <br />
                    <span className="price-tag-01">-</span>
                  </DisplayBox>
              </div>

              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Total Supply</span> <br />
                    <span className="price-tag-01">10000000000000000.00</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Top 10 Holders</span> <br />
                    <span className="price-tag-01">39.99%</span>
                  </DisplayBox>
              </div>
              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Contract Renounced</span> <br />
                    <span className="price-tag-01">-</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Open Source Contract</span> <br />
                    <span className="price-tag-01">-</span>
                  </DisplayBox>
              </div>
              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Contract Address</span> <br />
                    <span className="price-tag-01">0xd3...223a</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Pair Address</span> <br />
                    <span className="price-tag-01">0x28...9f7f</span>
                  </DisplayBox>
              </div>
              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Creator Address</span> <br />
                    <span className="price-tag-01">pend...ding</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Owner Address</span> <br />
                    <span className="price-tag-01">pend...ding</span>
                  </DisplayBox>
              </div>
              <div className="d-flex justify-content-around">    
                  <DisplayBox>
                    <span className="name-tag-01">Honeypot</span> <br />
                    <span className="price-tag-01">no</span>
                  </DisplayBox>
                  <DisplayBox>
                    <span className="name-tag-01">Ownership Renounced</span> <br />
                    <span className="price-tag-01">yes</span>
                  </DisplayBox>
              </div>



            </div>
          </div>

        {/* display none of graph and wishlist button */}
          <div className="d-none">
            <div>
              <div className="boon-box-1">
                <div className="d-flex justify-content-evenly">
                  <div className="boon-box-2">
                    <div className="boon-box-3">5M</div>
                    <div className="boon-box-4">1.17%</div>
                  </div>
                  <div className="boon-box-2">
                    <div className="boon-box-3">1H</div>
                    <div className="boon-box-4">9.17%</div>
                  </div>
                  <div className="boon-box-2">
                    <div className="boon-box-3">6H</div>
                    <div className="boon-box-4">805%</div>
                  </div>
                  <div className="boon-box-5">
                    <div className="boon-box-3">24H</div>
                    <div className="boon-box-4">12,227%</div>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div
                      className="boon-box-6"
                      style={{ borderRight: "1px solid #5e5e5e" }}
                    >
                      <div>
                        <h5>TXNS</h5>
                        <p>1111</p>
                      </div>
                      <div>
                        <h5>Vloume</h5>
                        <p>$357K</p>
                      </div>
                    </div>
                    <div className="boon-box-7"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none">
            <div className="d-flex justify-content-evenly mt-4">
              <div>
                <DisplayBox>
                  <img src={WatchListIcon} alt="" width={20} />
                  <span className="price-tag-02"> Watchlist</span>
                </DisplayBox>
              </div>
              <div>
                <DisplayBox>
                  <img src={AlertIcon} alt="" width={15} />
                  <span className="price-tag-02"> Alerts</span>
                </DisplayBox>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
