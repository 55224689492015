import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import { Button, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import telegram from "../../assets/images/ref_telegram.svg";
import group from "../../assets/images/ref_group.svg";
import twitter from "../../assets/images/ref_twitter.svg";
import wallet from "../../assets/images/ref_wallet.svg";
import { _get, _post } from "../../api/ApiClient";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../components/commonFunction/commonFunction";
import BarChart from "../../components/Chart/BarChart"
import DonutChart from "../../components/Chart/DonutChart"
import { userInfo } from "../../redux/user/UserSlice";
import copy from "../../assets/images/copy.svg";
import { handleCopy } from "../../components/commonFunction/commonFunction";
import { toast } from "react-toastify";
import * as XLSX from "xlsx/dist/xlsx.full.min";

const RedeemPoint = () => {
  const userData = useSelector((state) => state.ROOT);
  const [redeemPoint, setRedeemPoint] = useState(null)
  const [redeemUrl, setRedeemUrl] = useState("")

  useEffect(() => {
    getRedeemPointInfo()

  }, [])

  const getRedeemPointInfo = async () => {
    try {
      const response = await _get("/v1/admin/get-redeem-pending-users", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const res = response.data
      if (res.status) {
        setRedeemPoint(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const calculatePointsToRedeem = async () => {
    const userConfirmed = window.confirm('Are you sure you want to generate points?');
    if (!userConfirmed) {
      return;
    }
    try {
      const response = await _get("/v1/admin/calculate-points-to-redeem", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const res = response.data
      if (res.status) {
        setRedeemPoint(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const redeemPointsCall = async () => {
    const userConfirmed = window.confirm('Are you sure you want to redeem points?');
    if (!userConfirmed) {
      return;
    }
    try {
      if (!redeemUrl) {
        toast.error("Redeem URL cannot be empty.");
        return;
      }

      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z0-9\\-\\.]+)\\.[a-zA-Z]{2,})|" + // domain name
        "localhost|" + // or localhost
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // or IP (v4) address
        "(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*" + // port and path
        "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z0-9_]*)?$", // fragment locator
        "i"
      );
  
      if (!urlPattern.test(redeemUrl)) {
        toast.error("Invalid Redeem URL.");
        return;
      }

      const response = await _post("/v1/admin/redeem-points", { redeem_url: redeemUrl }, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      const res = response.data
      if (res.status) {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleDownloadExcel = () => {
    const data = redeemPoint.map((item) => ({
      "First Name": item?.first_name,
      "Email": item?.email,
      "Telegram Username": item?.telegram_username,
      "Wallet Address": item?.wallet_address,
      "Total Points": item?.total_points,
      "Points Redeem Done": item?.points_redeem_done,
      "Points Redeem Pending": item?.points_redeem_pending,
    }));

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Referrals");


    // Generate a timestamp for the file name
    const currentDateTime = new Date();
    const formattedDate = currentDateTime
      .toISOString()
      .slice(0, 19)
      .replace("T", "_")
      .replace(/:/g, "-"); // e.g., 2025-01-22_15-30-45

    // Define the file name with the timestamp
    const fileName = `ReferralData_${formattedDate}.xlsx`;

    // Generate and download the Excel file
    XLSX.writeFile(workbook, fileName);
  };


  return (
    <Layout>
      <Row>
        <div className="col-12 center-class" style={{ justifyContent: "space-between" }}>
          <div className="center-class">
            <span className="font-color" style={{ fontSize: "33px", fontWeight: "500" }}>Redeem</span>
            <span style={{ fontSize: "33px", fontWeight: "500", color: "white", marginLeft: "10px" }}>Point</span>
            <button type="button" className="btn secure-sign-in-btn" onClick={calculatePointsToRedeem} style={{ marginLeft: "10px" }}>
              <span style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
                Generate Point
              </span>
            </button >
          </div>
          <div className="center-class">
            <input className="input-redeem-point" type="text" placeholder="please enter redeem url" style={{ width: "350px" }}
              onChange={(e) => setRedeemUrl(e.target.value)} />
            <button type="button" className="btn secure-sign-in-btn" style={{ marginLeft: "10px" }} onClick={redeemPointsCall}>
              <span style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
                Redeem Point
              </span>
            </button >
            <button type="button" className="btn secure-sign-in-btn" style={{ marginLeft: "10px" }} onClick={handleDownloadExcel}>
              <span style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
                Download
              </span>
            </button >
          </div>
        </div>
      </Row>
      <Row className="pt-2">
        <div className="col-12">
          <div className="border-style-background">
            <Table responsive striped className="text-center referral-page-table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Telegram Username</th>
                  <th>Wallet Address</th>
                  <th>Total Points</th>
                  <th>Points Redeem Done</th>
                  <th>Points Redeem Pending</th>
                </tr>
              </thead>
              <tbody>
                {redeemPoint?.map((item, index) => (
                  <tr className="referral-page-table-bottom-border">
                    <td className="referral-page-table-right-border">{item?.first_name}</td>
                    <td className="referral-page-table-right-border">{item?.telegram_username}</td>
                    <td className="referral-page-table-right-border">{item?.wallet_address}<img src={copy} height={18} width={18} onClick={() => handleCopy(item?.wallet_address)} style={{ marginLeft: "10px" }} className='c-point' /></td>
                    <td className="referral-page-table-right-border">{item?.total_points}</td>
                    <td className="referral-page-table-right-border">{item?.points_redeem_done}</td>
                    <td>{item?.points_redeem_pending}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Row>
    </Layout>
  );
};

export default RedeemPoint;
