import React, { useCallback, useEffect, useState } from "react";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import {
    useDisconnect,
    useAccount,
    useSignMessage,
    useNetwork,
    useSwitchNetwork,
} from "wagmi";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import {
    walletAddress,
    userId,
    profileUrl,
    role,
    jwtToken,
    firstName,
    lastName,
    twitterAc,
    telegramAc,
    subscriptionDetails,
    balance,
    userInfo,
    walleteSignature
} from "../../redux/user/UserSlice";
import { _post } from "../../api/ApiClient";

const LoginWithWallete = ({ origin, setLoginBlueBackground = () => { },
    setShowModalForSignature = () => { }, setProfileCompleteShowModal = () => { }, }) => {
    const userData = useSelector((state) => state.ROOT);
    const { chains, error, pendingChainId, switchNetwork } = useSwitchNetwork();
    const { disconnect } = useDisconnect();
    const dispatch = useDispatch();
    const { open } = useWeb3Modal();
    const [isSigned, setIsSigned] = useState({ status: false, signed: "" });
    const [loading, setLoading] = useState(false);
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const { data: signMessageData, isLoading, signMessage } = useSignMessage();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate(); // Initialize useHistory
    const { chain } = useNetwork();

    useEffect(() => {

        if (origin != "secure_sign_modal" && origin != "profile_completion_modal") {
            disconnect()
        }
        if (origin == "subscriptio_plan" || origin == "settings") {
            handleSign()
        } else {
            if (userData?.jwtToken) {
                navigate("/dashboard")
            }
        }
    }, [])

    const handleSign = useCallback(async () => {
        if (origin === "profile_completion_modal") {
            setProfileCompleteShowModal(false)
        }
        try {
            await open();
        } catch (err) {
            console.log(err, "err");
        }
    }, [open]);
    useEffect(() => {
        if (isConnected) {
            setLoginBlueBackground(true)
            setShowModalForSignature(true)
            //handleSignature();
            if (origin != "secure_sign_modal") {
                handleSignature();
                /* if (chain.id === 1) {
                    handleSignature();

                    // Access Login
                } else {
                    switchNetwork?.(1);
                } */
            }
        } else {
            setLoginBlueBackground(false)
            setShowModalForSignature(false)
        }
    }, [isConnected, chain]);

    // login api
    const loginAPi = async (message) => {
        setLoading(true);
        try {
            setLoading(true);
            const data = {
                signature: message
            };
            if (localStorage.getItem("referral_code")) {
                data.refer_by = localStorage.getItem("referral_code");
            }

            const res = await _post("/v1/login", data);
            if (typeof res != 'undefined' && res.data) {
                sessionStorage.setItem("profileModal", true);
                localStorage.removeItem("referral_code");
                localStorage.setItem("sessionValidTill", Date.now() + (24 * 60 * 60 * 1000));
                setLoginBlueBackground(false)
                setShowModalForSignature(false)
                setLoading(false);
                toast.success("Wallet connected sucessfully");
                dispatch(jwtToken(res.data.jwt_token));
                localStorage.setItem("token", res.data.jwt_token);
                dispatch(walletAddress(res.data.user.wallet_address));
                dispatch(profileUrl(res.data.user.profile_image_url));
                dispatch(role(res.data.user.role));
                dispatch(firstName(res.data.user.first_name));
                dispatch(lastName(res.data.user.last_name));
                dispatch(subscriptionDetails(res.data.user.subscriptionDetails));
                dispatch(twitterAc(res.data.user.twitter_ac));
                dispatch(telegramAc(res.data.user.telegram_ac));
                dispatch(balance(res?.data?.user?.balance));
                dispatch(userInfo(res?.data?.user));
                dispatch(walleteSignature(message));
                if (!res?.data?.user?.subscription_plan_id || res?.data?.user?.subscription_plan_id == '') {
                    navigate("/dashboard");
                } else {
                    navigate("/dashboard");
                }
            }

        } catch (err) {
            setLoading(false);

            if (typeof err.response != 'undefined' && err.response.data.message === "Level Up Your Access!") {
                handleShow();
                disconnect();
                const referalCode = localStorage.getItem("referral_code");
                localStorage.clear();
                if (referalCode) {
                    localStorage.setItem("referral_code", referalCode);
                }
            }
            disconnect();
            const referalCode = localStorage.getItem("referral_code");
            localStorage.clear();
            if (referalCode) {
                localStorage.setItem("referral_code", referalCode);
            }
        }
        setLoading(false);
    };
    const handleDisconnectWallet = async () => {
        disconnect();
        localStorage.clear();
        if (Web3Modal?.cachedProvider) {
            await Web3Modal?.clearCachedProvider();
        }

        if (!isConnected) {
            navigate("/");
        }
    };

    const handleSignature = () => {
        const message = "Please sign this message to connect.";
        signMessage({ message });
    };

    useEffect(() => {
        if (signMessageData) {
            if (origin === "subscriptio_plan" || origin == "settings" || origin == "profile_completion_modal") {
                dispatch(walleteSignature(signMessageData));
                return
            }
            loginAPi(signMessageData);

            setIsSigned({ status: false, signed: signMessageData });
        }
    }, [signMessageData]);
    // Include reloaded in the dependency array


    const manualDisconnectWallet = () => {
        disconnect();
        window.location.reload();
    }


    return (
        <>
            {origin === "landing_page" ? <button type="button" onClick={() => handleSign()} className="btn btn-primary login-connect-btn">
                {loading ? (
                    <Spinner animation="border" />
                ) : (
                    <span
                        style={{ fontSize: "20px", fontWeight: 600, color: "white" }}
                    >
                        Connect Wallet Now
                    </span>
                )}
            </button> : <></>}

            {origin === "login" ?
                <button type="button" className="btn wallet-connect-btn" onClick={() => handleSign()}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <span
                            style={{ fontSize: "22px", fontWeight: 600, color: "black" }}
                        >
                            Connect With Wallet
                        </span>
                    )}
                </button >
                : <></>
            }

            {origin === "secure_sign_modal" ?
                <>
                    <button type="button" className="btn secure-sign-in-btn" onClick={() => handleSignature()}>
                        <span style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
                            Resend request
                        </span>
                    </button >
                    <div onClick={manualDisconnectWallet} style={{ cursor: "pointer", marginLeft: "20px" }}>
                        <FontAwesomeIcon icon={faLinkSlash} style={{ color: "#d5d5d5" }} />
                        <span
                            style={{ fontSize: "20px", fontWeight: 600, color: "#d5d5d5", textDecoration: "underline", marginLeft: "5px" }}
                        >
                            Disconnect
                        </span>
                    </div>

                </>
                : <></>
            }

            {origin === "profile_completion_modal" ?
                <button type="button" className="btn btn-primary setting-connet-btn" onClick={() => handleSign()}>Link Wallet</button>
                : <></>
            }

        </>
    )
}

export default LoginWithWallete;