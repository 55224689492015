import React from "react";
import DashBoardHeader from "../../components/dashBoardHeader/DashBoardHeader";
import Common from "../../components/commonContent/Common";
import DropdownBtn from "../../components/Buttons/Dropdown";
import TelegramListCard from "../../components/Cards/TelegramCards";
import { TelegramCardStyle } from "../../components/Cards/TelegramCards.style";
import { Button } from "react-bootstrap";
import ApplyBtn from "../../components/Buttons/ApplyBtn/Applybtn";
import { TokenTable } from "../../components/DexScreener/TokenTable";
import { HeadTabs } from "../../components/DexScreener/HeadTabs";
import { GraphTable } from "../../components/DexScreener/GraphTable";
import { TokenDetailsInfo } from "../../components/DexScreener/TokenDetailsInfo";
import { SubscriptionDeatils } from "./SubscriptionDeatils";
import Layout from "../../components/layout/Layout";

export const Subscriptionplan = () => {
  return (
    <div>
      {/* <DashBoardHeader /> */}
      {/* <Common>
        <div
          style={{
            width: "100%",
            height: "100vh",
            padding: "110px 15px 15px",
            background: "#27292A",
          }}
        >
          <SubscriptionDeatils />
        </div>
      </Common> */}
      {/* <Layout>
        <SubscriptionDeatils />
      </Layout> */}


      {/* <div
        style={{
          width: "100%",
          minHeight: "100vh",
          padding: "20px",
          paddingTop: "10px"
        }}
      >
        <SubscriptionDeatils />
      </div> */}

      <Layout>
        <SubscriptionDeatils />
      </Layout>

    </div>
  );
};
