import styled from "styled-components";
export const MainDashboardText = styled.span`
  background: radial-gradient(
      335.04% 3677.93% at 14.2% 212.5%,
      #009dcd 0.01%,
      #12f3c9 0.02%,
      #00f6bc 0.03%,
      #44d9f0 38.16%,
      #00f6bc 67.2%,
      #44d9f0 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  // background: linear-gradient(45deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const StyledMainSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  width: 100%;

  .tabs-container {
    display: flex;

    justify-content: space-between;
    gap: 10px;
    .active {
      border-width: 2px;
      border-style: solid;
      border-color: rgb(0, 184, 216);
      border-radius: 8px !important;

      padding: 1px;

      background-color: white;
    }

    /* padding: 20px; */
    .tab-cards {
      flex-grow: 1;
      background: rgba(6, 6, 6, 1);
      width: 140px;
      color: white;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 8px;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;

      h2 {
        font-size: 2.5vw;
        font-family: Plus Jakarta Sans;
        color: rgba(180, 180, 180, 1);

        font-weight: 500;
        line-height: 19px;
        text-align: center;
      }
    }
  }

  .cards {
    flex: 1 1 0;
    width: 0;
    color: white;
  }
  .card-header {
    display: flex;
    gap: 10px;
    width: 100%;

    .cards {
      width: 33%;
    }
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    .card-header {
      width: 100%;
    }
    .token-info {
      width: 100%;
    }
  }

  @media screen and (max-width: 670px) {
    .card-header {
      width: 100%;
      flex-direction: column;
      .cards {
        width: 100%;
      }
    }
  }
`;
