import React, { useState } from "react";
import { StyledChainDrawer } from "./styles";
import { Input } from "antd";
import {chainDataForDrawer} from "../../../commonFunction/commonFunction"
const { Search } = Input;

export const ChainDrawer = ({ isSelectChain, setIsSelectChain }) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleCardClick = (index, item) => {
    setSelectedCard(index);
    setIsSelectChain((prevState) => ({
      isOpen: !prevState.isOpen,
      current: item.name,
      value: item.value
    }));
  };

  const filteredChains = chainDataForDrawer.filter((item) =>
    item?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  return (
    <StyledChainDrawer
      open={isSelectChain.isOpen}
      placement={"top"}
      push={false}
      closable={true}
      onClose={() =>
        setIsSelectChain((prevState) => ({
          isOpen: !prevState.isOpen,
          current: "All Chains",
          value: ""
        }))
      }
      getContainer={false}
      mask={true}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <div className="search-container">
        <Search
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search"
          onSearch={handleSearch}
          enterButton
        />
      </div>

      <div className="chain-grid">
        {filteredChains.map((item, index) => (
          <div
            key={index}
            className={`card-item ${selectedCard === index ? "active" : ""}`}
            onClick={() => handleCardClick(index, item)}
          >
            {/* {index !== 0 && <img src={item.imgSrc} alt="" />} */}
            <img src={item.imgSrc} alt="" width={25} height={25} />
            <p style={{ color: "#fff", fontSize: "11px" }}>{item.name}</p>
          </div>
        ))}
      </div>
    </StyledChainDrawer>
  );
};
