import React, { useState, useRef } from "react";
import Layout from "../../components/layout/Layout";
import historyImg from "../../assets/images/influenecricon.png"
import { useSelector, useDispatch } from "react-redux";
import { _get, _post } from "../../api/ApiClient";
import axios from 'axios';
import { CONFIG } from "../../config/index.js";
import {
  firstName,
  lastName,
  twitterAc,
  telegramAc
} from "../../redux/user/UserSlice";


const EditProfile = () => {
  const userData = useSelector((state) => state.ROOT);
  const dispatch = useDispatch();
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [twitter_ac, setTwitterAc] = useState('')
  const [telegram_ac, setTelegramAc] = useState('')
  const [isDisabledInput, setIsDisabledInput] = useState(true)
  const [selectedImage, setSelectedImage] = useState(historyImg);
  const [imageFile, setImageFile] = useState(null)
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file)
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };


  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const editUserInfo = async () => {
    const editProfieData = new FormData();
    editProfieData.append("profile", imageFile);
    editProfieData.append("first_name", first_name != '' ? first_name : userData.firstName);
    editProfieData.append("last_name", last_name != '' ? last_name : userData.lastName);
    editProfieData.append("twitter_ac", twitter_ac != '' ? twitter_ac : userData.twitterAc);
    editProfieData.append("telegram_ac", telegram_ac != '' ? telegram_ac : userData.telegramAc);

    /* const boundary = '----WebKitFormBoundaryOkVrVXrIJhxUxRaw';
    const headers = new Headers();
    headers.append('Content-Type', `multipart/form-data; boundary=${boundary}`);
    headers.append('Authorization', `Bearer ${userData.jwtToken}`); */

    const res = await axios.post(CONFIG.BASE_URL + "/v1/user/update-profile", editProfieData, {
      headers: { 'Authorization': `Bearer ${userData.jwtToken}` }
    });
    const data = res.data

    if (res.data.status) {
      dispatch(firstName(res.data.data.first_name));
      dispatch(lastName(res.data.data.last_name));
      dispatch(twitterAc(res.data.data.twitter_ac));
      dispatch(telegramAc(res.data.data.telegram_ac));
    }

  }

  return (

    <Layout>

      <h4 style={{ color: "white" }}>Edit Profile</h4>
      <div className="row" style={{ float: 'right', clear: 'both' }}>
        <div style={{ color: 'white', paddingRight: '25px' }}>
          <button type="button" className="btn btn-primary edit-profile-save-btn"
            onClick={() => setIsDisabledInput(false)}
          >Edit</button>
        </div>
      </div>
      <div className="row" style={{ color: '#fff', clear: 'both' }}>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4">
          <div className="edit-profile-img-div">
            <div className="edit-profile-img-section">
              <img src={selectedImage} height={150} width={150} alt="Selected"
                onClick={handleImageClick}
                style={{ cursor: 'pointer' }} />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
            </div>
            <div className="edit-profile-img-text">
              <div style={{ fontSize: '20px' }}>{userData.firstName}</div>
              <div style={{ color: '#d5d5d5' }}>{userData.role}</div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-4">
          <div style={{ margin: "10px" }}>
            <div>
              <label className="edit-profile-label">First Name</label>
              <input type="text" className="edit-profile-input" placeholder="Enter your first name"
                defaultValue={userData.firstName} onChange={(e) => setFirstName(e.target.value)}
                disabled={isDisabledInput}
              />
            </div>
            <div className="mt-2">
              <label className="edit-profile-label">Twitter A/C</label>
              <input type="text" className="edit-profile-input" placeholder="Enter your Twitter A/C"
                defaultValue={userData.twitterAc} onChange={(e) => setTwitterAc(e.target.value)}
                disabled={isDisabledInput}
              />
            </div>
            <div className="mt-2">
              <label className="edit-profile-label">Subscribe Plan</label>
              <div>{userData.subscriptionDetails?.plan_name}</div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
          <div style={{ margin: "10px" }}>
            <div>
              <label className="edit-profile-label">Last Name</label>
              <input type="text" className="edit-profile-input" placeholder="Enter your last name"
                defaultValue={userData.lastName} onChange={(e) => setLastName(e.target.value)}
                disabled={isDisabledInput}
              />
            </div>
            <div className="mt-2">
              <label className="edit-profile-label">Telegram A/C</label>
              <input type="text" className="edit-profile-input" placeholder="Enter your Telegram A/C"
                defaultValue={userData.telegramAc} onChange={(e) => setTelegramAc(e.target.value)}
                disabled={isDisabledInput}
              />
            </div>
            <div className="mt-2">
              <label className="edit-profile-label">Linked Wallet</label>
              <input type="text" className="edit-profile-input" placeholder="Enter your Linked Wallet "
                defaultValue={userData.walletAddress} disabled />
            </div>
            <div className="acitve-button mt-3">
              <button type="button" className="btn btn-primary edit-profile-save-btn"
                onClick={() => editUserInfo()}
              >Save</button>
            </div>

          </div>
        </div>
      </div>
    </Layout>


  );
};

export default EditProfile;
