import React, { useEffect, useState } from "react";
import { StyledManageWrapper, TelegramCardStyle } from "./TelegramCards.style";
import telegramImg from "../../assets/images/telegram.png";
import MyAddChannelModal from "../Modal/MyAddChannelModal";
import { _get, _post } from "../../api/ApiClient";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ApplyBtn from "../Buttons/ApplyBtn/Applybtn";
import { Button, Spinner } from "react-bootstrap";
import DropdownBtn from "../Buttons/Dropdown";
const optionList = [
  {
    value: "Telegram",
    label: "Telegram",
  },
  { value: "Twitter", label: "Twitter" },
];
function TelegramListCard() {
  const userData = useSelector((state) => state.ROOT);
  const [channelName, setChannelName] = useState("");

  const [modalShow, setModalShow] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("Channel");
  const [telegramList, setTelegramList] = useState([]);
  const [secondCardContent, setSecondCardContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSecondTerm, setSearchSecondTerm] = useState("");
  const [allChannelData, setAllChannelData] = useState([]);
  const [initialState, setInitialState] = useState({});

  const filteredTelegramList = telegramList.filter((channel) =>
    channel?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const filteredSecondCardContent = secondCardContent.filter((content) =>
    content.name?.toLowerCase().includes(searchSecondTerm?.toLowerCase())
  );
  const filteredThirdCardContent = allChannelData.filter((content) =>
    content.channels[0]?.name
      ?.toLowerCase()
      ?.includes(searchSecondTerm?.toLowerCase())
  );
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "channelName") {
      setChannelName(event.target.value);
    } else {
      setSelectedOption(event.target.value);
    }
  };
  //getChannel for user
  const channelList = async () => {
    try {
      const res = await _get("/v1/user/telegramChannels", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (res.data) {
        setAllChannelData(res.data.telegram_channels);
        let all = {};
        res.data.telegram_channels.forEach((item) => {
          all[item.channels[0]?._id] = true;
        });
        setInitialState(all);
        // all[item.id] = true;
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  //get channel by admin
  // /v1/admin/AllTelegramChannels
  const getAllChannelsList = async () => {
    try {
      setListLoader(true);
      const resp = await _get("/v1/admin/AllTelegramChannels", {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (resp.data) {
        setListLoader(false);
        setTelegramList(resp.data.telegram_channels);
      }
    } catch (err) {
      setListLoader(false);
      console.log("error", err);
    }
    setListLoader(false);
  };
  // add channel by admin

  const addChannel = async () => {
    try {
      setAddLoader(true);
      const payload = { channelUsername: channelName };
      const resp = await _post(
        `/v1/admin/addChannel?type=${selectedOption}`,
        payload,
        {
          headers: { Authorization: `Bearer ${userData.jwtToken}` },
        }
      );
      if (resp.data) {
        setAddLoader(false);
        setModalShow(false);
        toast.success("channel added sucessfully");
        getAllChannelsList();
        setSelectedOption("Channel");
        setChannelName("");
      }
    } catch (e) {
      setAddLoader(false);
      toast.error(e.response.data.message);
      console.log("error", e);
    }
    setAddLoader(false);
  };

  const handleCheckboxChange = (item) => {
    const tempInitial = { ...initialState };
    // Update the content to be displayed in the second card
    if (tempInitial[item._id]) {
      delete tempInitial[item._id];
    } else {
      tempInitial[item._id] = true;
    }
    setInitialState(tempInitial);
    const newContent = [];
    telegramList.forEach((channel) => {
      if (tempInitial[channel._id]) {
        newContent.push({
          name: channel?.name,
          type: channel?.type,
          id: channel?._id, // Assuming _id is the channel ID
        });
      }
    });
    setSecondCardContent(newContent);
  };
  // save channel
  const saveChannel = async () => {
    const tempInitails = { ...initialState };
    const deleted = [];
    allChannelData.forEach((item) => {
      if (tempInitails[item.channels[0]?._id]) {
        delete tempInitails[item.channels[0]?._id];
      } else {
        deleted.push(item.channels[0]?._id);
      }
    });
    const newAdded = Object.keys(tempInitails);
    try {
      setIsLoading(true);
      const payload = { channelIDs: [...newAdded] };
      const resp = await _post("/v1/user/addChannel", payload, {
        headers: { Authorization: `Bearer ${userData.jwtToken}` },
      });
      if (resp.data) {
        setIsLoading(false);
        toast.success("Channel added successfully");
        channelList();
        setSecondCardContent([]);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.msg);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getAllChannelsList();
    channelList();
  }, []);
  return (
    <StyledManageWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DropdownBtn optionList={optionList} />
        {userData.role === "Admin" && (
          <Button variant="primary" onClick={() => setModalShow(true)}>
            + Add Channels
          </Button>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          className="card-container"
          style={{ display: "flex", gap: 20, width: "100%" }}
        >
          <TelegramCardStyle
            style={{
              width: "50%",
              height: "400px",
              overflowY: "auto",
            }}
            className="telegram-card"
          >
            {listLoader ? (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  animation="border"
                  style={{
                    height: "50px",
                    width: "50px",
                    color: "#fff",
                  }}
                />
              </div>
            ) : (
              <>
                <div style={{ padding: "15px" }}>
                  {/* First card with checkboxes */}
                  <div
                    style={{
                      position: "sticky",
                      top: "10px",
                      bottom: "10px",
                      zIndex: 10, // Ensure it's above other content
                    }}
                  >
                    <input
                      style={{
                        background: "rgba(21, 22, 24, 1)",
                        border: "0.5px solid #fff",
                        borderRadius: "5px",
                        outline: "none",
                        color: "#fff",
                        padding: "5px",
                        width: "100%",
                      }}
                      type="text"
                      placeholder="Search Channel..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  {filteredTelegramList.length > 0 ? (
                    filteredTelegramList.map((channel, index) => (
                      <div
                        key={channel}
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid rgba(238, 238, 238, 0.2)",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <div style={{ display: "flex", gap: "20px" }}>
                          <img
                            src={telegramImg}
                            alt=""
                            width={40}
                            height={40}
                          />
                          <div>
                            <div
                              style={{
                                color: "#ffff",
                                fontSize: "16px",
                                fontWeight: 700,
                              }}
                            >
                              {channel?.name}
                            </div>
                            <div style={{ color: "#ffff", fontSize: "12px" }}>
                              {channel.type === "Channel" ? "Channel" : "Group"}
                            </div>
                          </div>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={initialState[channel._id]}
                            onChange={() => handleCheckboxChange(channel)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "300px",
                        color: "#fff",
                        flexDirection: "column",
                      }}
                    >
                      <div>No Channels/Groups Found </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </TelegramCardStyle>

          {/* Second card to display content */}

          <TelegramCardStyle
            style={{
              height: "400px",
              overflowY: "auto",
              width: "50%",
            }}
            className="telegram-card"
          >
            <div style={{ padding: "15px" }}>
              <div
                style={{
                  position: "sticky",
                  top: "10px",
                  bottom: "10px",
                  zIndex: 10, // Ensure it's above other content
                }}
              >
                <input
                  style={{
                    background: "rgba(21, 22, 24, 1)",
                    border: "0.5px solid #fff",
                    borderRadius: "5px",
                    outline: "none",
                    color: "#fff",
                    padding: "5px",
                    width: "100%",
                  }}
                  type="text"
                  placeholder="Search Channel..."
                  value={searchSecondTerm}
                  onChange={(e) => setSearchSecondTerm(e.target.value)}
                />
              </div>

              {filteredSecondCardContent.length > 0 ? (
                filteredSecondCardContent.map((content, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      borderBottom: "1px solid rgba(238, 238, 238, 0.2)",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      gap: "20px",
                    }}
                  >
                    <img src={telegramImg} alt="" width={40} height={40} />
                    <div>
                      <div
                        style={{
                          color: "#ffff",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {content?.name}
                      </div>
                      <div style={{ color: "#ffff", fontSize: "12px" }}>
                        {content.type === "Channel" ? "Channel" : "Group"}
                      </div>
                    </div>
                    <div></div>
                  </div>
                ))
              ) : allChannelData.length > 0 ? (
                filteredThirdCardContent.map((content, index) => (
                  <div
                    key={index}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      borderBottom: "1px solid rgba(238, 238, 238, 0.2)",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      gap: "20px",
                    }}
                  >
                    <img src={telegramImg} alt="" width={40} height={40} />
                    <div>
                      <div
                        style={{
                          color: "#ffff",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {content?.channels[0]?.name}
                      </div>
                      <div style={{ color: "#ffff", fontSize: "12px" }}>
                        {content?.channels[0]?.type === "Channel"
                          ? "Channel"
                          : "Group"}
                      </div>
                    </div>
                    <div></div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    color: "#fff",
                    flexDirection: "column",
                  }}
                >
                  <div>No Channels/Groups Found</div>
                </div>
              )}
            </div>
          </TelegramCardStyle>
        </div>
        <ApplyBtn
          saveBtn={saveChannel}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />

        <MyAddChannelModal
          show={modalShow}
          setChannelName={setChannelName}
          channelName={channelName}
          onHide={() => setModalShow(false)}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          handleChange={handleChange}
          addChannel={addChannel}
          addLoader={addLoader}
        />
      </div>
    </StyledManageWrapper>
  );
}

export default TelegramListCard;
