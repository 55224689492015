
import { Button, Form, Modal } from "react-bootstrap";
import { _post } from "../../api/ApiClient";
import { useSelector } from "react-redux";
import { useState } from "react";
export const SubscriptionPlanModal = ({
    show,
    setShow,
    selectedPlanId
}) => {
    const handleClose = () => {
        setShow(false);
    };
    const userData = useSelector((state) => state.ROOT);
    const [actionType, setActionType] = useState('')
    const makePayment = async () => {
        if (actionType == 'money') {
            let payload = {
                plan_id: selectedPlanId,
                type: 'monthly'
            }
            try {
                const resp = await _post("/v1/user/make-payment", payload, {
                    headers: { Authorization: `Bearer ${userData.jwtToken}` },
                });
                if (resp.data.payment_url) {
                    window.location.href = resp.data.payment_url
                }

            } catch (e) {
                console.log("error", e);
            }
        } else {
        }

    }

    const handlePlanChange = (e) => {
        setActionType(e.target.value); // Set the selected value
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            //size="lg"
            //style={{ maxWidth: "600px", margin: 'auto' }}
            // style={{ background: "rgb(118, 118, 118)" }}
            dialogClassName="modal-custom-width"
        >
            <Modal.Header
                closeButton
                closeVariant="white"
                style={{ background: "rgb(18 19 20)", borderBottom: "none" }}
            >
                <Modal.Title style={{ color: "#ffffff" }}>
                    <span style={{ marginLeft: '10px' }}>
                        Subscription Plan
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ background: "rgb(18 19 20)" }}>
                <div style={{ color: "#fff" }}>

                    <span>Your Token are 10000</span>
                    <div style={{ marginTop: '10px' }}>
                        <Form.Check
                            style={{ color: "#fff" }}
                            /* inline */
                            label="Pay With Money"
                            name="group1"
                            type="radio"
                            id="Channel"
                            value="money"
                            onChange={handlePlanChange}
                        />
                        <Form.Check
                            style={{ color: "#fff" }}
                            /* inline */
                            label="By Token Holding (You can select this plan)"
                            name="group1"
                            type="radio"
                            id="Channel"
                            value="token"
                            onChange={handlePlanChange}
                        />
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer style={{ background: "rgb(18 19 20)", borderTop: "none" }}>
                <Button variant="primary" onClick={makePayment}>
                    Pay
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
