import React from 'react'
import Nav from 'react-bootstrap/Nav';
/* import DexRoundLogo from "../../assets/images/dex-logo.png";
import DexPolygonLogo from "../../assets/images/polygon-logo.png";
import Arbitrum from "../../assets/images/arbitrum.png"
import Avalanche from "../../assets/images/avalanche.png"
import Blast from "../../assets/images/blast.png"
import BSC from "../../assets/images/bsc.png"
import Ethereum from "../../assets/images/ethereum.png"
import Polygon from "../../assets/images/polygon.png"
import { Link } from 'react-router-dom'; */

import { chainLogo } from '../commonFunction/commonFunction';

export const HeadTabs = (props) => {
  return (
    <div>
      <Nav variant="underline" defaultActiveKey="/new-pair" className='nav-style-78'>
        <Nav.Item >
          <Nav.Link onClick={() => props.handleChainChange('all')}> All chains </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item >
          <Nav.Link href="/new-pair"> All chains </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link><Link to="/subscription-plan"><img src={DexPolygonLogo} alt="" /> Solona</Link></Nav.Link>
        </Nav.Item>
        <Nav.Item >
          <Nav.Link ><img src={Ethereum} alt="" /> Ethereum</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link ><img src={BSC} alt="" /> BSC</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link ><img src={Arbitrum} alt="" /> Arbitrum</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link ><img src={DexRoundLogo} alt="" /> Base</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link ><img src={Polygon} alt="" /> Polygon</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link ><img src={Avalanche} alt="" /> Avalanche</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link ><img src={Blast} alt="" /> Blast</Nav.Link>
        </Nav.Item> */}
        {props?.chainList?.map((chain, index) => (
          <Nav.Item key={index}>
            <Nav.Link onClick={() => props.handleChainChange(chain.id)}>
              <img src={chainLogo[chain.id]} alt="" className='chain-logo' style={{ width: '24px' }} /> {chain.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <hr />
    </div>
  )
}
