import styled from "styled-components";
export const BorderField = styled.div`
  background: transparent;
  /* width: 35%; */
  min-height: 48px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 1);
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  font-family: Plus Jakarta Sans;
  padding: 12px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // text-wrap: break-word;
`;

export const StyledFilterWrapper = styled.div`
  .filter-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 20px;

    width: 100%;
    // border: "1px solid red",
    // padding: "10px",

    .search-container {
      width: calc(100% - 600px);
      div {
        width: 100%;
      }
    }

    .filter-header {
      display: flex;
      gap: 20px;
      margin-top: 10px;
      width: 600px;
      align-items: center;
    }

    .dropdown-container {
      display: flex;
      gap: 20px;
    }
    .hours-container {
      display: flex;
      gap: 20px;
    }
  }

  @media screen and (max-width: 1130px) {
    .filter-container {
      flex-direction: column;
      .filter-header {
        width: 100%;
      }
      .search-container {
        width: 100%;
        margin-top: 20px;
        div {
          width: 100%;
        }
      }
      .dropdown-container {
        width: 50%;
        .channels {
          width: 50%;
        }

        .select {
          width: 50%;
        }
      }
      .hours-container {
        width: 50%;
        .input-section {
          width: 55% !important;
          input {
            width: 100% !important;
          }
        }
        .acitve-button {
          width: 40% !important;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .filter-header {
      flex-direction: column;
      justify-content: space-between;
      .dropdown-container,
      .hours-container {
        width: 100%;
      }
    }
  }
`;
