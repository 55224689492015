import React from 'react'
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import './new_pair_style.css';
import { TransactionsTable } from './TransactionsTable';
import { TopTraders } from './TopTraders';
import { Holders } from './Holders';
import { LiquidityProviders } from './LiquidityProviders';


export const GraphTable = () => {
    const [key, setKey] = useState('Transactions');
  return (
    <div className='xwidth-70 xpos-bot'>
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="Transactions" title="Transactions">
        <TransactionsTable/>
      </Tab>
      <Tab eventKey="toptraders" title="Top Traders ">
        <TopTraders/>
      </Tab>
      <Tab eventKey="holders" title="Holders(233)">
        <Holders/>
      </Tab>
      <Tab eventKey="liquidityproviders" title="Liquidity Providers">
       <LiquidityProviders/>
      </Tab>
    </Tabs>
    </div>
  )
}
