// Import dependencies
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const CustomBarChart = ({ data }) => {
  let series = data?.series?.filter(item => item !== null);
  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',  // Background similar to your chart
      borderRadius: 10,
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: data?.categories || {},
      labels: {
        style: {
          color: '#FFFFFF'  // White text for better contrast
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      gridLineColor: '#333333',  // Dark grid lines
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    legend: {
      itemStyle: {
        color: '#FFFFFF'  // Legend text color
      }
    },
    tooltip: {
      formatter: function () {
        if (this.x === 'Wed' && this.y === 130) {
          return `<b>${this.x}, 10 of September</b><br/>${this.y}$123001`;
        }
        return `<b>${this.x}</b><br/>${this.series.name}: ${this.y}`;
      },
      backgroundColor: '#00D4FF',
      style: {
        color: '#000000',
      }
    },
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1
      }
    },
    series: series || [],
    credits: {
      enabled: false, // Remove the Highcharts credits
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default CustomBarChart;
