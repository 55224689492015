import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { TelegramCardStyle } from "../TelegramCards.style";
import defaultProfile from "../../../assets/images/default-profile.jpg"
import { timeAgo } from "../../commonFunction/commonFunction"

function formatString(str) {
  if (str) {
    let maxLength = 20;
    if (str.length > maxLength) {
      return str.slice(0, maxLength - 3) + "...";
    }
    return str;
  }
}

const TrendingTokenChatCard = ({ data, tokenLoader, cardType }) => {

  const getProfileImage = (item) => {
    let profileImage = defaultProfile;
    if (item?.platform && item?.platform != 'telegram') {
      profileImage = item?.influencerDetails?.profile
    }
    return profileImage
  }

  return (
    <TelegramCardStyle
      style={{ width: "100%" }}
    >
      {!tokenLoader ? (
        <div style={{ padding: "1px", width: "100%" }} className="border-style-background">
          {data.length > 0 ? (
            <div className="trending-contract-table-scroll livefeed" style={{ borderRadius: "15px", background: "#171721" }}>
              <Table responsive striped className="text-start main-dashboard-table dashboard-table-common">
                <tbody>
                  {data?.map((item, index) => (
                    <tr key={index}>
                      <td width="15%"><img
                        src={getProfileImage(item)}
                        alt="" width={40} height={40} /></td>
                      <td width="70%">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={item.msg_url}
                          target="_blank"
                        >
                          <div
                            style={{
                              width: "90%",
                              fontSize: "16px",
                              lineHeight: "20.16px",
                              fontWeight: 600,
                              color: "rgba(233, 233, 233, 1)",
                            }}
                          >
                            {item?.influencerDetails?.influncerName}
                            {item.is_utility && (
                              <span className="utility-text">Utility</span>
                            )}
                          </div>
                        </Link>


                        {item.type === "Channel"
                          ? " Channel - "
                          : item.type === "Group"
                            ? " Grpoup - "
                            : item?.platform + " - "}
                        {formatString(item?.dollar_tending?.join(", "))}

                      </td>
                      <td width="15%">{timeAgo(item?.msg_time)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                background: "#171721",
                borderRadius: "15px"
              }}
            >
              No data
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: 200,
          }}
        >
          <Spinner
            animation="border"
            style={{ height: "50px", width: "50px" }}
          />
        </div>
      )}
    </TelegramCardStyle>
  );
};

export default TrendingTokenChatCard;
