import { styled } from "styled-components";

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1;
  border-bottom: 0.5px solid #303b4e;
  width: 100%;
  //height: 94px;
  background-color: #171721;

  .menu {
    // margin-left: 30px;
    display: flex;
    gap: 30px;
  }
`;
