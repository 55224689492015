import styled from "styled-components";

export const StyledInfluencerWrapper = styled.div`
  .ant-pagination-item-active {
    background-color: #12deb9 !important;
    color: white;
    font-weight: 600;
  }
  .ant-pagination-item {
    color: white;
    font-weight: 600;
    background-color: transparent
  }
  .anticon,
  .anticon-left,
  .ant-pagination-item-ellipsis {
    color: white !important;
    font-weight: 600;
  }

  .unfollow-btn {
    color: wheat;
    border-color: black;
    background-color: #da898c !important;
    background-image: linear-gradient(
      147deg,
      #da898c 0%,
      #59090c 74%
    ) !important;
  }
  @media screen and (max-width: 1100px) {
    .subscription-box-1 {
      width: 100%;
      margin-left: 0px;
    }
  }
`;

export const StyledInfluencerHeading = styled.div`
  .ant-select-dropdown {
    background: rgb(39, 41, 42);
  }

  .ant-select-item-option {
    color: white !important;
    font-weight: 600 !important;
  }

  .ant-select-item-option-selected {
    background-color: dimgray !important;
  }
  .ant-select-single {
    width: 100%;
  }
  .ant-select-selection-item {
    color: white !important;
    font-weight: 600;
    font-size: 22px;
  }
  .ant-select-focused,
  .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open,
  .ant-select-selector {
    border-color: white !important;
    box-shadow: none !important;
  }
  .ant-select-selector {
    background: transparent !important;
    padding: 23px;
    font-size: 26px;
    font-weight: 600;
    line-height: 32.38px;
    color: rgb(255, 255, 255) !important;
    padding: 25px 10px !important ;
  }
  .ant-select-arrow {
    padding: 15px;
    font-size: 20px;
    color: white;
  }

  .ant-select,
  .ant-select-outlined,
  .ant-select-single,
  .ant-select-show-arrow {
    border-color: white !important;
    box-shadow: none;
    outline: none;
  }
`;
