import { Drawer } from "antd";
import styled from "styled-components";

export const StyledChainDrawer = styled(Drawer)`
  background: rgba(21, 22, 24, 1) !important;
  .ant-drawer-content-wrapper {
    transform: translateX(185px) !important;
  }
  .ant-drawer-body {
    padding: 18px !important;
  }
  .ant-drawer-header {
    padding: 0px !important;
    .ant-drawer-header-title {
      position: absolute;
      right: 0;
      top: 23px;
      color: white;
      button {
        color: white;
      }
    }
  }

  .search-container {
    width: 90%;

    input {
      background: transparent;
      color: white !important;
      outline: none !important;
      border-right: none;
      box-shadow: none !important;
      border-color: white;
    }
    input:hover {
      outline: none !important;
      border-color: white !important;
    }
    input::placeholder {
      color: white !important;
      font-weight: 600;
      opacity: 0.5;
    }
  }
  .ant-btn-primary {
    background: transparent;
    border: 1px solid white;
    border-left: none;
  }
  .ant-input:focus {
    border-color: white;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
  }
  .chain-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;
    .card-item {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      font-family: "Plus Jakarta Sans";

      border-radius: 8px;
      gap: 5px;
      p {
        margin-bottom: 0px;
      }
      
    }
    .card-item:hover {
      background: rgb(39, 41, 42);
    }
    .active {
      background: rgb(39, 41, 42);
    }
  }
  .ant-drawer-content-wrapper {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    &::-webkit-scrollbar {
      width: 0.4em;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
