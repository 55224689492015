import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({ data, title = "" }) => {
  const options = {
    chart: {
      type: 'column',
      backgroundColor: "transparent"
    },
    title: {
      text: title,
      style: {
        color: '#FFFFFF'
      }
    },
    xAxis: {
      categories: data?.categories || {},
      labels: {
        style: {
          color: '#FFFFFF'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Values',
      },
      labels: {
        style: {
          color: '#FFFFFF'
        },
        /* formatter: function () {
          // Divide value by 1 billion and add 'B'
          return `${(this.value / 1e9).toFixed(1)}B`;
        }, */
      }
    },
    legend: {
      itemStyle: {
        color: '#FFFFFF'
      },
      itemHoverStyle: {
        color: '#d5d5d5' // The hover color you want
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0,  // Remove the border from columns
        dataLabels: {
          enabled: false,
          style: {
            color: '#FFFFFF'
          }
        }
      }
    },
    series: data?.series || [],

    tooltip: {
      formatter: function () {
        return `${this.x}: ${this.y}<br/>`;
      },
      style: {
        color: '#000'
      }
    },
    credits: {
      enabled: false
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart;
