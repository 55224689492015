import React, { useEffect, useState } from "react";
import { SideBarStyled } from "./styled";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import bigLogo from "../../assets/images/KolsAILogo.png";
import smallLogo from "../../assets/images/kols.png";
import {
    faDashboard, faCreditCard, faBell, faArrowTrendUp, faDollar, faRocket,
    faCog, faSignOut, faUser, faRobot, faChartColumn, faShop, faGlobe, faRepeat
} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from "react-redux";

const LeftSidebar = ({ handleDisconnectWallet, isActive }) => {
    const userData = useSelector((state) => state.ROOT);
    const location = useLocation();
    const isDashboardSelected = location.pathname === "/dashboard";
    const isSettingsSelected = location.pathname === "/setting";
    const isNotificationsSelected = location.pathname === "/notifications";
    const isNewPairSelected = location.pathname === "/new-pair";
    const isTrendingContract = location.pathname === "/trending-contract";
    const isPrelauchCalls = location.pathname === "/prelaunch-calls";
    const isTokenTrending = location.pathname === "/token-trending";
    const isKolaAiChatBot = location.pathname === "/kols-ai-chatbot";
    const isSmartTraders = location.pathname === "/smart-traders";
    const isReferralProgram = location.pathname === "/referral-program";
    const isSubscriptionPlanSelected = location.pathname === "/subscription-plan";
    const isManageInfluencer = location.pathname === "/influence-mangement";
    const isTwitterInfluencer = location.pathname === "/influence-twitter";
    const isChainDataAnalysis = location.pathname === "/chart-data-analysis";
    const isLiveTrending = location.pathname === "/live-trending";
    const isKolsBoard = location.pathname === "/kols-board";
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isSubmenuOpenForInfluencer, setIsSubmenuOpenForInfluencer] = useState(false);

    useEffect(() => {
        if (location.pathname === "/trending-contract" || location.pathname === "/prelaunch-calls" || location.pathname === "/token-trending" || location.pathname === "/utility-trending") {
            setIsSubmenuOpen(true)
            setIsSubmenuOpenForInfluencer(false)
        }
        if (location.pathname === "/influence-mangement" || location.pathname === "/influence-twitter") {
            setIsSubmenuOpenForInfluencer(true)
            setIsSubmenuOpen(false)
        }
        setIsSubmenuOpenForInfluencer(false)
        setIsSubmenuOpen(false);
    }, [location.pathname])

    const handleLiveTrendingClick = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
        setIsSubmenuOpenForInfluencer(false)
    };

    const handleInfluenecerClick = () => {
        setIsSubmenuOpenForInfluencer(!isSubmenuOpenForInfluencer);
        setIsSubmenuOpen(false);
    };


    const handleMouseLeave = () => {
        setIsSubmenuOpenForInfluencer(false)
        setIsSubmenuOpen(false);
    };


    // Function to toggle sidebar visibility    
    return (
        <SideBarStyled
            className={`sidebar-new ${isActive ? 'hover' : ''} leftbar-border`}
            onMouseLeave={handleMouseLeave}
        >
            <div className="headerLogo">
                <img
                    src={bigLogo}
                    alt="cognoFiLogo"
                    className="bigLogo"
                />
                <img
                    src={smallLogo}
                    alt="cognoFiLogo"
                    className="smallLogo"
                />
                {/* <img
                    src={HeaderLogo}
                    alt="headerLogo"
                    style={{ marginBottom: "20px", width: "80%" }}
                /> */}
            </div>
            <div className="sidebar-scroll">
                <div className={`sidebar-menu ${isDashboardSelected ? 'active' : ''}`}>
                    {/* <img style={{ width: 20, height: 20 }} src={DashImg} alt="" /> */}
                    <Link
                        to="/dashboard"
                        style={{
                            color: isDashboardSelected ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faDashboard} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isDashboardSelected ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Dashboard
                        </span>
                    </Link>
                </div>
                <div className={`sidebar-menu`}>
                    {/* <img style={{ width: 20, height: 20 }} src={DashImg} alt="" /> */}
                    <a
                        href="https://www.dextools.io/app/en/token/kolsai"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faShop} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{ color: "rgb(208, 207, 207)" }}
                        >
                            Buy $KolsAI
                        </span>
                    </a>
                </div>
                {/* <div className="sidebar-menu"
                    onClick={handleLiveTrendingClick}
                >
                    <FontAwesomeIcon icon={faArrowTrendUp} className="sidebar-icon" />
                    <div style={{ width: '100%' }}>
                        <span
                            className="dropdown-label"
                            style={{
                                color: (location.pathname === "/trending-contract" || location.pathname === "/prelaunch-calls" || location.pathname === "/token-trending") ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Influence Trending
                        </span>
                        <span className="dropdown-arrow">{isSubmenuOpen ? '▲' : '▼'}</span>
                    </div>
                </div>

                {isSubmenuOpen && (
                    <div
                        style={{
                            maxHeight: isSubmenuOpen ? '300px' : '0px',
                            opacity: isSubmenuOpen ? 1 : 0,
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease, opacity 0.3s ease',
                            marginLeft: '40px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <Link to="/trending-contract" style={{ textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faArrowTrendUp} className="sidebar-icon" />
                            <span className="sub-menu-sidebar"
                                style={{ color: (location.pathname === "/trending-contract") ? "#19e1c8" : "#D0CFCF" }}
                            >Trending Contract</span>
                        </Link>
                        <Link to="/prelaunch-calls" style={{ textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faRocket} className="sidebar-icon" />
                            <span className="sub-menu-sidebar"
                                style={{ color: (location.pathname === "/prelaunch-calls") ? "#19e1c8" : "#D0CFCF" }}
                            >Prelaunch Calls</span>
                        </Link>
                        <Link to="/token-trending" style={{ textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faDollar} className="sidebar-icon" />
                            <span className="sub-menu-sidebar"
                                style={{ color: (location.pathname === "/token-trending") ? "#19e1c8" : "#D0CFCF" }}
                            >$Token Trending</span>
                        </Link>
                    </div>
                )} */}
                <div className={`sidebar-menu ${isTrendingContract ? 'active' : ''}`}
                >
                    <Link
                        to="/trending-contract"
                        style={{
                            color: isTrendingContract ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowTrendUp} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isTrendingContract ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Trending Contract
                        </span>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isPrelauchCalls ? 'active' : ''}`}
                >
                    <Link
                        to="/prelaunch-calls"
                        style={{
                            color: isPrelauchCalls ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faRocket} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isPrelauchCalls ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Prelaunch Calls
                        </span>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isTokenTrending ? 'active' : ''}`}
                >
                    <Link
                        to="/token-trending"
                        style={{
                            color: isTokenTrending ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faDollar} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isTokenTrending ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            $Token Trending
                        </span>
                    </Link>
                </div>
                {/* {userData.role === 'Admin' ? <>
                    <div className="sidebar-menu"
                    >
                        <Link
                            to="/new-pair"
                            style={{
                                color: isNewPairSelected ? "#19e1c8" : "black",
                                textDecoration: "none",
                            }}
                        >
                            <FontAwesomeIcon icon={faRobot} className="sidebar-icon" />
                            <span
                                className="label"
                                style={{
                                    color: isNewPairSelected ? "#19e1c8" : "#D0CFCF",
                                }}
                            >
                                Live New Pair
                            </span>
                        </Link>
                    </div>
                    <div className="sidebar-menu"
                    >
                        <Link
                            to="/live-trending"
                            style={{
                                color: isLiveTrending ? "#19e1c8" : "black",
                                textDecoration: "none",
                            }}
                        >
                            <FontAwesomeIcon icon={faChartBar} className="sidebar-icon" />
                            <span
                                className="label"
                                style={{
                                    color: isLiveTrending ? "#19e1c8" : "#D0CFCF",
                                }}
                            >
                                Live Influencer Trending
                            </span>
                        </Link>
                    </div>
                </> : <></>} */}

                <div className={`sidebar-menu ${isManageInfluencer ? 'active' : ''}`}
                >
                    <Link
                        to="/influence-mangement"
                        style={{
                            color: isManageInfluencer ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faUser} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isManageInfluencer ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Influencers
                        </span>
                    </Link>
                </div>

                <div className={`sidebar-menu ${isKolsBoard ? 'active' : ''}`}
                >
                    <Link
                        to="/kols-board"
                        style={{
                            color: isKolsBoard ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faDashboard} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isKolsBoard ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Kols Board
                        </span>
                    </Link>
                </div>

                <div className={`sidebar-menu ${isKolaAiChatBot ? 'active' : ''}`}
                >
                    <Link
                        to="/kols-ai-chatbot"
                        style={{
                            color: isKolaAiChatBot ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faRobot} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isKolaAiChatBot ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            KolsAI Chat Bot
                        </span>
                    </Link>
                </div>

                <div className={`sidebar-menu ${isSubscriptionPlanSelected ? 'active' : ''}`}
                >
                    <Link
                        to="/subscription-plan"
                        style={{
                            color: isSubscriptionPlanSelected ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faCreditCard} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isSubscriptionPlanSelected ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Subscription Plan
                        </span>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isChainDataAnalysis ? 'active' : ''}`}
                >
                    <Link
                        to="/chart-data-analysis"
                        style={{
                            color: isChainDataAnalysis ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faChartColumn} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isChainDataAnalysis ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Chain Data Analysis
                        </span>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isSmartTraders ? 'active' : ''}`}
                >
                    <Link
                        to="/smart-traders"
                        style={{
                            color: isSmartTraders ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faGlobe} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isSmartTraders ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Smart Traders
                        </span>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isReferralProgram ? 'active' : ''}`}
                >
                    <Link
                        to="/referral-program"
                        style={{
                            color: isReferralProgram ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faRepeat} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isReferralProgram ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Referral Program
                        </span>
                    </Link>
                </div>
                {/* <div className={`sidebar-menu ${isNotificationsSelected ? 'active' : ''}`}
                >
                    <Link
                        to="/notifications"
                        style={{
                            color: isNotificationsSelected ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faBell} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isNotificationsSelected ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Notifications
                        </span>
                    </Link>
                </div> */}

                <div className={`sidebar-menu ${isSettingsSelected ? 'active' : ''}`}
                >
                    <Link
                        to="/setting"
                        style={{
                            color: isSettingsSelected ? "#19e1c8" : "black",
                            textDecoration: "none",
                        }}
                    >
                        <FontAwesomeIcon icon={faCog} className="sidebar-icon" />
                        <span
                            className="label"
                            style={{
                                color: isSettingsSelected ? "#19e1c8" : "#D0CFCF",
                            }}
                        >
                            Settings
                        </span>
                    </Link>
                </div>
                <div className="sidebar-menu"
                >
                    <div
                        onClick={() => handleDisconnectWallet()}
                        style={{
                            color: "rgba(255, 73, 73, 1)",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <FontAwesomeIcon icon={faSignOut} className="sidebar-icon" style={{ color: "rgba(255, 73, 73, 1)" }} />
                        <span className="label">Logout</span>
                    </div>
                </div>

            </div>
        </SideBarStyled>
    )
}

export default LeftSidebar;

